import React, { useMemo, useState } from "react";
import usePost from "../custom/usePost";
import DashCalFull from "./dashCalFull";
import { Empty, Flex, Spin } from "antd";

const DashCal = ({ data: course }) => {
  const usidStore = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");

  let today = new Date(),
    lastMonth = new Date(new Date().setDate(new Date().getDate() - 60)),
    date =
      lastMonth.getFullYear() +
      "-" +
      (lastMonth.getMonth() + 1) +
      "-" +
      lastMonth.getDate(),
    nextMonth = new Date(new Date().setDate(new Date().getDate() + 30)),
    date7 =
      nextMonth.getFullYear() +
      "-" +
      (nextMonth.getMonth() + 1) +
      "-" +
      nextMonth.getDate();
  const [usid, setUsid] = useState({
    instructor: usidStore,
    from_date: lastMonth,
    to_date: nextMonth,
  });

  // const { data: course, isLoading } = usePost("get_course_schedule", usid);

  const data = useMemo(() => {
    if (!course) return { events: [] };

    // Transform and format course data
    const eventsData = course.map((e) => {
      return {
        id: e.schedule_date + e.name,
        title: e.student_group,
        course: e.title.split(" - ").join("-"),
        start: e.schedule_date + "T" + ("0" + e.from_time).slice(-8),
        end: e.schedule_date + "T" + ("0" + e.to_time).slice(-8),
        allDay: false,
        color: e.color,
        company: e.company.split(" - ").join("-"),
        attendance_status: e.attendance_status,
        course: e.course.split(" - ").join("-"),
        instructor: e.instructor,
        instructor_name: e.instructor_name,
        name: e.name,
        program: e.program,
        room: e.room.split(" - ").join("-"),
        groupId: e.status,
      };
    });

    return { events: eventsData };
  }, [course])
  // let data = { events: [] };
  // let arr = course && course.message;
  // arr &&
  //   arr.forEach((e) => {
  //     let x = {
  //       id: e.schedule_date + e.name,
  //       title: e.student_group,
  //       course: e.title.split(" - ").join("-"),
  //       start: e.schedule_date + "T" + ("0" + e.from_time).slice(-8),
  //       end: e.schedule_date + "T" + ("0" + e.to_time).slice(-8),
  //       // startTime: e.from_time,
  //       // endTime: e.to_time,
  //       allDay: false,
  //       color: e.color,
  //       company: e.company.split(" - ").join("-"),
  //       attendance_status: e.attendance_status,
  //       // eslint-disable-next-line
  //       course: e.course.split(" - ").join("-"),
  //       instructor: e.instructor,
  //       instructor_name: e.instructor_name,
  //       name: e.name,
  //       program: e.program,
  //       room: e.room.split(" - ").join("-"),
  //       groupId: e.status,
  //     };
  //     data.events.push(x);
  //   });
  // console.log(data);

  return (
    <div className="w-100" style={{ padding: "15px", height: "85vh" }}>
      {/* {console.log(data.events[0])} */}
      <div className="row col-md-12" style={{ padding: "0", margin: "0" }}>
        {data.events.length > 0 ? (
          <DashCalFull events={data.events.sort((a, b) => a.start > b.start)} />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};
export default DashCal;
