import React, { Component } from "react";
import useWindow from "../custom/useWindow";
import { redirectToPage } from "../custom/pageFn";

const Page404 = () => {
  const { width } = useWindow();
  return (
    <>
      <div
        className=""
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:
            "url(https://picsum.photos/600/300?grayscale) no-repeat center center / cover",
        }}
      >
        <div className="container" style={{ padding: "25px" }}>
          <div
            className="row align-items-center justify-content-around"
            style={{
              color: "white",
              borderRadius: "25px",
              backdropFilter: "blur(5px)",
              background: "rgb(255 255 255 / 0.3)",
            }}
          >
            <div className="w-100" style={{ minHeight: "50px" }}></div>
            <div
              style={{
                padding: "50px",
                display: "flex",
                flexDirection: width > 450 ? "row" : "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "72px" }}>404</div>

              <div style={{ fontSize: "18px" }}>
                Congratulation..
                <br />
                You found our 404 Pages.
              </div>
            </div>
            <button
              className="col-md-3 btn btn-light"
              onClick={() => redirectToPage(0.1, "/")}
            >
              back home
            </button>
            <div className="w-100" style={{ minHeight: "50px" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
