import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { apiSite } from "../config/urlLink";
import loading from "../assets/img/loading.gif";

const Term = () => {
  const location = useLocation();
  const [form, setForm] = useState(location.state);
  const [msg, setMsg] = useState({ message: "", state: false });
  const [isLoading, setIsLoading] = useState(true);
  const signPad = useRef({});
  const clearSignPad = () => {
    signPad.current.clear();
    setForm({ ...form, sign: false });
  };
  const saveSignPad = () => {
    // const res = signPad.current.toDataURL();
    // setForm({ ...form, sign: true });

    // Create a new object with trimmed values
    const trimmedForm = Object.fromEntries(
      Object.entries(form).map(([key, value]) => [
        key,
        typeof value === "string" ? value.trim() : value,
      ])
    );

    // Add the sign key with value true
    trimmedForm.sign = true;

    // Update the state
    setForm(trimmedForm);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(false);
    const data = '{"data":' + JSON.stringify(form) + "}";

    const abortCtr = new AbortController();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "token f6b262554df61cb:ca71946b2def9fa",
    };
    console.log("data", data);
    setTimeout(async () => {
      try {
        let res = await fetch(
          `${apiSite}api/method/smi.api.registrasi_student`,
          {
            signal: abortCtr.signal,
            method: "POST",
            body: data,
            headers: headers,
          }
        );
        res = await res.json();
        let resData = JSON.parse(res._server_messages);
        let resData1 = JSON.parse(resData[0]);
        console.log(res);
        console.log(resData);
        console.log(resData1);
        if (res.exception) {
          throw res;
        } else {
          let waMsg = {
            receiver: "6281901823478",
            message: `${JSON.stringify(data)} \n\n\n ${JSON.stringify(res)}`,
            session: "628112971117",
          };
          let wa = await fetch("https://wa.sekolahmusik.co.id/api/send", {
            signal: abortCtr.signal,
            method: "POST",
            body: JSON.stringify(waMsg),
            headers: headers,
          });
          console.log("wa", wa);
          setMsg({ ...msg, message: resData1.message, state: true });
        }
      } catch (error) {
        let resData = JSON.parse(error._server_messages);
        let resData1 = JSON.parse(resData[0]);
        console.log(error);
        console.log(resData);
        let waMsg = {
          receiver: "6281901823478",
          message: `${JSON.stringify(data)} \n\n\n ${JSON.stringify(error)}`,
          session: "628112971117",
          // apikey: "10-eUxVxUTnBK35",
        };
        await fetch("https://wa.sekolahmusik.co.id/api/send", {
          signal: abortCtr.signal,
          method: "POST",
          body: JSON.stringify(waMsg),
          headers: headers,
        });
        setMsg({
          ...msg,
          message: resData1.message,
          error: error.exception + " - " + error._error_message,
          indicator: resData.indicator,
          state: true,
        });
      }
    }, 0);
  };

  return (
    <>
      <div
        className="__modal-window"
        style={{ display: "block" }}
        hidden={isLoading}
      >
        <div className="row col-md-7 col-12">
          <div
            className="w-100 justify-content-around"
            style={{
              textAlign: "justify",
              height: "auto",
            }}
          >
            <div
              style={{ fontSize: "24px", textAlign: "center", lineHeight: "1" }}
            >
              {msg.state ? (
                msg.indicator ? (
                  <>
                    {msg.message}
                    <br />
                    <small>
                      <i> Please contact SMI {form.unit}.</i>
                    </small>
                  </>
                ) : (
                  <>
                    {msg.message} <br />
                    <i>{msg.error}</i>
                  </>
                )
              ) : (
                <>
                  <img src={loading} alt="Loading" width={"50%"} />
                  <p style={{ position: "relative", bottom: "30px" }}>
                    Please wait... <br />
                    We still typing your data to our server.
                  </p>
                </>
              )}
            </div>
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log(msg);
                setMsg({ ...msg, state: false, message: "" });
                setIsLoading(true);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {console.log(form)}
      {form && form.length === 0 ? (
        <div className="w-100 form-title">
          <h1>ADA KESALAHAN DALAM PENGIRIMAN DATA!</h1>
        </div>
      ) : (
        <div className="w-100 form-title">
          <h1>SYARAT DAN KETENTUAN </h1> <i>Term and Condition</i>
        </div>
      )}

      <div className="w-100" style={{ height: "25px" }}></div>
      <div
        className="row col-md-12"
        style={{ padding: "5px 0px", margin: "0px" }}
      >
        <div className="col-md-6" style={{ textAlign: "justify" }}>
          <p>
            <strong>Medical release : </strong>
            <br />
            Kami memberikan kuasa kepada Sekolah Musik Indonesia untuk
            memberikan pertolongan pertama dan membawa murid ke Rumah Sakit
            terdekat jika terjadi keadaan darurat (orang tua/ wali tidak bisa
            dihubungi)
          </p>
          <p>
            <strong>Documentation release : </strong>
            <br />
            Kami memberikan ijin kepada Sekolah Musik Indonesia untuk membuat
            rekaman gambar maupun suara terhadap anak kami/ kami. SMI boleh
            menggunakan rekaman tersebut untuk program audio visual guna
            kepentingan pendidikan, promosi, dan informasi sehubungan dengan
            kegiatan SMI.
            <br />
            <br />
          </p>
          {/* {JSON.stringify(form)} */}
          <p>
            <strong>Detail Pendaftaran : </strong>
            <br />
          </p>
          <table className="mb-4" style={{ margin: "0 10px" }}>
            <tbody>
              <tr>
                <td>Unit</td>
                <td>: {form.unit}</td>
              </tr>
              <tr>
                <td>Mengenal SMI</td>
                <td>: {form.know}</td>
              </tr>
              <tr>
                <td>Alasan Mendaftar</td>
                <td>: {form.reason}</td>
              </tr>
              <tr>
                <td>Mobile</td>
                <td>: {form.mobile}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>: {form.email}</td>
              </tr>
              <tr>
                <td>Fullname</td>
                <td>: {form.full_name}</td>
              </tr>
              <tr>
                <td>Nickname</td>
                <td>: {form.nick_name}</td>
              </tr>
              <tr>
                <td>NIK</td>
                <td>: {form.nik}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>: {form.gender}</td>
              </tr>
              <tr>
                <td>Place</td>
                <td>: {form.place}</td>
              </tr>
              <tr>
                <td>Date of Birth</td>
                <td>: {form.date_of_birth}</td>
              </tr>
              <tr>
                <td>Religion</td>
                <td>: {form.religion}</td>
              </tr>
              <tr>
                <td>Nationality</td>
                <td>: {form.national}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>: {form.address}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>: {form.city}</td>
              </tr>
              <tr>
                <td>Postal Code</td>
                <td>: {form.postal_code}</td>
              </tr>
              <tr>
                <td>School</td>
                <td>: {form.school}</td>
              </tr>
              <tr>
                <td>Sosmed</td>
                <td>: {form.sosmed}</td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Detail Guardian : </strong>
            <br />
          </p>
          {form.guardian === "" ? (
            "Anda tidak memberikan data Guardian"
          ) : (
            <>
              {/* <div>{JSON.stringify(form.guardian)}</div> */}
              <div className="col-md-12 mb-4">
                {form.guardian.map((d) => (
                  <>
                    <div className="m-2">
                      <b>
                        {d.relation} - {d.name}
                      </b>

                      <div style={{ paddingLeft: "15px" }}>
                        <table className="col-md-12">
                          <tbody>
                            <tr>
                              <td>Mobile</td>
                              <td>: {d.mobile} </td>
                              <td>E-mail</td>
                              <td>: {d.email}</td>
                            </tr>
                            <tr>
                              <td>Job</td>
                              <td>: {d.job} </td>
                              <td>Sosmed</td>
                              <td>: {d.sosmed}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
          <p>
            <strong>Detail Course : </strong>
            <br />
            {/* {JSON.stringify(form.course)} */}
          </p>
          <div className="col-md-12 mb-4">
            {form.course.map((d) => (
              <>
                <div className="m-2">
                  <b>
                    {d.format} - {d.major}
                  </b>
                  <br />
                  <i>
                    {d.duration} - {d.type}
                  </i>
                </div>
              </>
            ))}
          </div>
          <p>
            <strong>Detail Tambahan : </strong>
            <br />
          </p>
          <table className="col-md-12">
            <tbody>
              <tr>
                <td>QR</td>
                <td>: {form.qr} </td>
              </tr>
              <tr>
                <td>Daftar Ulang</td>
                <td>: {JSON.stringify(form.daftar_ulang)}</td>
              </tr>
              <tr>
                <td>Search</td>
                <td>: {form.search} </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <p>
            <strong>Calon Murid : </strong>
            <br /> {form.full_name ? form.full_name : ""}
          </p>
          {form.guardian ? (
            <>
              <p>
                <strong>Orang Tua : </strong>
                <br />
              </p>
              <table>
                {form.guardian.map((e) => (
                  <tbody>
                    <tr>
                      <td>{e.relation}</td>
                      <td>:</td>
                      <td>{e.name}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </>
          ) : (
            ""
          )}

          <p>
            Kami sudah membaca dan memahami syarat dan ketentuan yang ada. dan
            menyatakan bersedia mengikuti tata tertib dan ketentuan yang berlaku
            di Sekolah Musik Indonesia.
          </p>
          {/* {JSON.stringify(form)} */}
          <div className="col">
            <p>Silahkan tanda tangan atau tulis nama di bawah ini.</p>
            <div style={{ border: "1px solid gray" }}>
              <SignaturePad
                canvasProps={{
                  // width: 500,
                  height: 400,
                  className: "w-100",
                }}
                ref={signPad}
              />
            </div>
            <button className="btn btn-primary" onClick={clearSignPad}>
              Clear
            </button>
            <button className="btn btn-warning" onClick={saveSignPad}>
              Save
            </button>
          </div>
        </div>

        <div className="w-100" style={{ height: "25px" }}></div>
        <hr />
        <div className="w-100" style={{ textAlign: "center" }}>
          <Link to="/course" state={form}>
            <button
              className="btn btn-primary m-2"
              style={{ width: "150px" }}
              type="submit"
            >
              Back
            </button>
          </Link>
          {form.sign ? (
            <button
              className="btn btn-primary m-2"
              type="submit"
              style={{ width: "150px" }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          ) : (
            <button
              className="btn btn-secondary m-2"
              style={{ width: "150px" }}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Term;
