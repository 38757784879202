import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Gender from "./gender";
import Nationality from "./nationality";
import Religion from "./religion";
import useDate from "../custom/useDate";

const Form2 = () => {
  const location = useLocation();
  const { now } = useDate();
  const [form, setForm] = useState(location.state);
  const [err, setErr] = useState({ mobile: "", email: "" });
  const [btnNext, setBtnNext] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value;
    // const trimmedValue = value.trim();
    console.log({ [name]: value });

    const rn = /^[0-9\b]+$/;
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    let newForm = { ...form, [name]: trimmedValue };
    let newErr = { ...err };

    switch (name) {
      case "nik":
      case "mobile":
        if (trimmedValue === "" || rn.test(trimmedValue)) {
          newErr[name] = "";
        } else {
          newErr[name] = "Number only";
        }
        break;
      case "email":
        if (trimmedValue === "" || re.test(trimmedValue)) {
          newErr[name] = "";
        } else {
          newErr[name] = "Please input a valid format email.";
        }
        break;
      case "date_of_birth":
        if (trimmedValue < now) {
          newErr[name] = "";
        } else {
          newErr[name] = "Please input a valid date.";
        }
        break;
      case "full_name":
      case "nick_name":
        newForm[name] = trimmedValue.toUpperCase();
        break;
      default:
        newForm[name] = trimmedValue;
        break;
    }

    setForm(newForm);
    setErr(newErr);

    const isFormValid = Object.keys(form).every(
      (key) => form[key] && newErr[key] === ""
    );

    setBtnNext(isFormValid);
  };

  return (
    <>
      <div className="w-100 form-title">
        <h1>DETAIL MURID </h1> <i>Student Details</i>
      </div>
      <div className="w-100" style={{ height: "25px" }}>
        {/* {JSON.stringify(form)} */}
      </div>
      {form === null ? (
        <>
          <div>
            <p>"Ups anda belum mengisi data sebelum nya"</p>
          </div>
          <div>
            <Link to="/">
              <button className="btn btn-primary" style={{ width: "150px" }}>
                Start Here
              </button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <form
            className="w-100"
            style={{ lineHeight: "1" }}
            onSubmit={handleSubmit}
          >
            {/* whatsapp & Email */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Nomor Whatsapp <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Whatsapp Number</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  {err.mobile !== "" ? (
                    <small className="text-danger">{err.mobile}</small>
                  ) : (
                    ""
                  )}
                  <input
                    required={true}
                    onChange={handleChange}
                    type="tel"
                    className="form-control"
                    name="mobile"
                    id="mobile"
                    placeholder="628112345.. / 08112345.."
                    value={form.mobile ? form.mobile : ""}
                    aria-describedby="mobileHelp"
                  />
                </div>
              </div>
              <div
                className="row col-md-6"
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Surel <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>E-Mail</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  {err.email !== "" ? (
                    <small className="text-danger">{err.email}</small>
                  ) : (
                    ""
                  )}
                  <input
                    required={true}
                    onChange={handleChange}
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={form.email ? form.email : ""}
                    aria-describedby="emailHelp"
                  />
                </div>
              </div>
            </div>

            {/* Nama Lengkap */}
            <div
              className="row col-md-12 "
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <label className="col-md-2 col-4" htmlFor="FirstName">
                Nama Lengkap <span className="text-danger">*</span>
                <br />
                <small>
                  <i>Full Name</i>
                </small>
              </label>
              <div className="col-md-10 col-8">
                <input
                  required={true}
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  name="full_name"
                  id="full_name"
                  value={form.full_name ? form.full_name : ""}
                  aria-describedby="fullHelp"
                />
              </div>
            </div>

            {/* nick name & gender */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Nama Panggilan <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Nick Name</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <input
                    required={true}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    name="nick_name"
                    id="nick_name"
                    value={form.nick_name ? form.nick_name : ""}
                    aria-describedby="nickHelp"
                  />
                </div>
              </div>
              <div
                className="row col-md-6"
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Jenis Kelamin <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Gender</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <Gender
                    dataForm={form.gender ? form.gender : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            {/* tempat, tanggal lahir */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Tempat Lahir <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Birth Place</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <input
                    required={true}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    name="place"
                    id="place"
                    value={form.place ? form.place : ""}
                    aria-describedby="placeHelp"
                  />
                </div>
              </div>
              <div
                className="row col-md-6"
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Tanggal Lahir <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Date of Birth</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  {err.date_of_birth !== "" ? (
                    <small className="text-danger">{err.date_of_birth}</small>
                  ) : (
                    ""
                  )}
                  <input
                    required={true}
                    onChange={handleChange}
                    type="date"
                    className="form-control"
                    name="date_of_birth"
                    id="date_of_birth"
                    value={form.date_of_birth ? form.date_of_birth : ""}
                    aria-describedby="dateHelp"
                  />
                </div>
              </div>
            </div>

            {/* no ktp & upload file ktp */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              {/* <div
            className="row col-md-6"
            style={{ margin: "0px", padding: "0px" }}
          >
            <label className="col-md-4 col-4" htmlFor="InputNickName">
              Unggah Foto ID
              <br />
              <small>
                <i>Upload ID Photo </i>
              </small>
            </label>
            <div className="col-md-8 col-8">
              <FileUpload onMouseOver={handleChange} />
            </div>
          </div> */}
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  No KTP
                  <br />
                  <small>
                    <i>ID Number</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <input
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    name="nik"
                    id="nik"
                    value={form.nik ? form.nik : ""}
                    aria-describedby="nikHelp"
                  />
                  {err.nik !== "" ? (
                    <small className="text-danger">{err.nik}</small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* Agama & Warga Negara */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Agama <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Religion</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <Religion
                    dataForm={form.religion ? form.religion : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                className="row col-md-6"
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Warga Negara <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Nationality</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <Nationality
                    dataForm={form.national ? form.national : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            {/* Alamat Lengkap */}
            <div
              className="row col-md-12 "
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <label className="col-md-2 col-4" htmlFor="FirstName">
                Alamat Lengkap <span className="text-danger">*</span>
                <br />
                <small>
                  <i>Full Address</i>
                </small>
              </label>
              <div className="col-md-10 col-8">
                <input
                  required={true}
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  name="address"
                  id="address"
                  value={form.address ? form.address : ""}
                  aria-describedby="fullHelp"
                />
              </div>
            </div>

            {/* Kota & Kode Pos */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Kota <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>City</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <input
                    required={true}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={form.city ? form.city : ""}
                    aria-describedby="nickHelp"
                  />
                </div>
              </div>
              <div
                className="row col-md-6"
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Kode Pos <span className="text-danger">*</span>
                  <br />
                  <small>
                    <i>Postal Code</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <input
                    required={true}
                    onChange={handleChange}
                    type="number"
                    className="form-control"
                    name="postal_code"
                    id="postal_code"
                    value={form.postal_code ? form.postal_code : ""}
                    aria-describedby="nickHelp"
                  />
                </div>
              </div>
            </div>
            {/* Sekolah & Sosmed Akun */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-6 "
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Nama Sekolah
                  <br />
                  <small>
                    <i>School Name</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <input
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    name="school"
                    id="school"
                    value={form.school ? form.school : ""}
                    placeholder="SDN Negeri Utama 1"
                    aria-describedby="nickHelp"
                  />
                </div>
              </div>
              <div
                className="row col-md-6"
                style={{ margin: "0px", padding: "0px" }}
              >
                <label className="col-md-4 col-4" htmlFor="InputNickName">
                  Akun SosMed
                  <br />
                  <small>
                    <i>SosMed Account</i>
                  </small>
                </label>
                <div className="col-md-8 col-8">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">@</div>
                    </div>
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      name="sosmed"
                      id="sosmed"
                      value={form.sosmed ? form.sosmed : ""}
                      placeholder="@john.smith"
                      aria-describedby="nickHelp"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100" style={{ height: "25px" }}></div>

            {/* button Next */}
            <div className="w-100" style={{ textAlign: "center" }}>
              <Link to="/" state={form}>
                <button
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                  type="submit"
                >
                  Back
                </button>
              </Link>
              {btnNext ||
              (form.mobile !== "" &&
                form.email !== "" &&
                form.full_name &&
                form.nick_name &&
                form.gender &&
                form.place &&
                form.date_of_birth &&
                form.address &&
                form.city &&
                form.postal_code &&
                form.religion &&
                form.national &&
                err.email === "" &&
                err.mobile === "") ? (
                <>
                  <Link to="/parent" state={form}>
                    <button
                      className="btn btn-primary"
                      style={{ width: "150px" }}
                      type="submit"
                      // onClick={() => handleNext()}
                    >
                      Next
                    </button>
                  </Link>
                </>
              ) : (
                <button
                  className="btn btn-secondary"
                  style={{ width: "150px" }}
                >
                  Next
                </button>
              )}
            </div>
          </form>
          <span className="text-danger">* = Mandatory / Harus di isi.</span>
        </>
      )}
    </>
  );
};

export default Form2;
