import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Unit from "./unit";
// import QRScan from "./qrcode/scan";
// import QRCam from "./qrcode/cam1";
import "../assets/css/modal.css";
import KnowFrom from "./knowFrom";
import Reason from "./reason";

const Form1 = (props) => {
  const location = useLocation();
  const [visCam, setVisCam] = useState(false);
  const [form, setForm] = useState(
    location
      ? location.state
      : {
          qr: "",
          search: "",
          guardian: "",
          know: "",
          reason: "",
          school: "",
          sosmed: "",
          nik: "0",
          daftar_ulang: true,
          follow_up: "",
          mgm: "",
        }
  );
  const [btnNext, setBtnNext] = useState(false);
  const handleUnit = (e) => {
    console.log("handleUnit", e);
    setForm({
      ...form,
      unit: e,
      search: window.location.search,
      qr: "",
      guardian: "",
      know: "",
      reason: "",
      school: "",
      sosmed: "",
      mobile: "",
      email: "",
      nik: "0",
      daftar_ulang: true,
      follow_up: "",
      mgm: "",
    });
    setBtnNext(true);
  };
  const handleQR = (e) => {
    console.log("handleQR", e);
    setForm({ ...form, qr: e });
  };
  const handleChange = (e) => {
    console.log({ [e.target.name]: e.target.value });
    setForm({ ...form, [e.target.name]: e.target.value.trim() });
  };
  return (
    <>
      {/* QR Scanner Modal box*/}
      {visCam && (
        <div
          id="mdl"
          className="modal-window"
          style={{
            display: { true: "block", false: "none" }[visCam],
            visibility: { true: "block", false: "none" }[visCam],
          }}
        >
          {/* Data taken from session storage */}
          <div className="row col-md-12">
            <div
              className="w-100 justify-content-around"
              style={{
                textAlign: "justify",
                height: "auto",
              }}
            ></div>
            {/* <QRCam hdlQR={(e) => handleQR(e)} /> */}
            <div
              className="w-100 justify-content-around"
              style={{
                textAlign: "center",
                height: "auto",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  textAlign: "center",
                  width: "60px",
                  height: "auto",
                }}
                onClick={() => setVisCam(!visCam)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      {/* {JSON.stringify(form)} */}
      <div
        className="row col-md-12"
        style={{ minHeight: "300px", margin: "0px" }}
      >
        <div className="col-md-6" style={{ alignSelf: "center" }}>
          <Unit dataForm={form} hdlUnit={(e) => handleUnit(e)} />
        </div>
        <div
          className="col-md-6"
          style={{
            borderLeft: "1px solid lightgray",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={() => setVisCam(!visCam)}
          >
            Scan QR
          </button>
          <br />
          <br />
          or <br />
          <br />
          {/* <QRScan hdlQR={(e) => handleQR(e)} /> */}
        </div>
        <div
          className="w-100"
          style={{
            height: "25px",
          }}
        ></div>
        <div className="container">
          {/* Know From & Warga Negara */}
          <div
            className="row col-md-12"
            style={{ padding: "5px 0px", margin: "0px" }}
          >
            <div
              className="row col-md-6 "
              style={{ margin: "0px", padding: "15px" }}
            >
              <label className="col-md-4 col-4" htmlFor="InputNickName">
                Mengenal SMI
                <br />
                <small>
                  <i>Know From</i>
                </small>
              </label>
              <div className="col-md-8 col-8">
                <KnowFrom dataForm={form} onChange={handleChange} />
              </div>
            </div>
            <div
              className="row col-md-6"
              style={{ margin: "0px", padding: "15px" }}
            >
              <label className="col-md-4 col-4" htmlFor="InputNickName">
                Alasan mendaftar
                <br />
                <small>
                  <i>Joining Reason</i>
                </small>
              </label>
              <div className="col-md-8 col-8">
                <Reason dataForm={form} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100" style={{ textAlign: "center" }}>
        {form && (form.unit || form.unit !== "") ? (
          <Link to="/student" state={form}>
            <button className="btn btn-primary" style={{ width: "150px" }}>
              Next
            </button>
          </Link>
        ) : (
          <button className="btn btn-secondary" style={{ width: "150px" }}>
            Next
          </button>
        )}
      </div>

      <span className="text-danger">* = Mandatory / Harus di isi.</span>
    </>
  );
};

export default Form1;
