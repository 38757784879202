import React, { useState } from "react";

const Relation = ({ handleChange, data }) => {
  const [other, setOther] = useState(false);
  const handleRelationChange = (e) => {
    if (e.target.value === "other") {
      setOther(true);
    } else {
      setOther(false);
    }
    handleChange(e);
  };
  const handleOther = (e) => {
    handleChange(e);
  };
  return (
    <>
      <div className="row col" style={{ margin: "0px" }}>
        <select
          className="form-select  mb-3"
          name="relation"
          onChange={handleRelationChange}
          defaultValue=""
        >
          <option key="0" value="">
            -
          </option>
          <option value="Mother">Mother</option>
          <option value="Father">Father</option>
          <option value="other">Other</option>
        </select>

        {other ? (
          <input
            className="form-control"
            type="text"
            name="relation"
            id="relation"
            placeholder="please specify here"
            onChange={handleOther}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Relation;
