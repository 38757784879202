const LoadingComponent = ({ loading = false, text = "" }) => {
  return loading ? (
    <div className="modal-window" style={{ backdropFilter: "blur(5px)" }}>
      <div className="col-md-3" style={{ background: "rgb(255 255 255/0)" }}>
        <div className="blink-1">{text}</div>
        <div className="__bar-loader"></div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default LoadingComponent;
