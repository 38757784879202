import React, { useState } from "react";
import { LongDate, ShortDateFull, ShortDate } from "../custom/dateFormatter";
import useDate from "../custom/useDate";
import "../assets/css/dashHis.css";
import Modal from "../website/modal";
import StudentAttendanceResponse from "./studentAttendanceResponse";
import {
  GetResourceDetail,
  PostResource,
  SendWa,
  SendWaTemplate,
} from "../custom/apiResource";
import EditAttendance from "./editAttendance";

const StudentAttendance = ({ data, comment }) => {
  const { monthShort } = useDate();
  const [vis, setVis] = useState({ modal: false });
  let fullname = sessionStorage.getItem("full_name");

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const handleEditAttendance = (data, cs) => {
    setVis({
      modal: true,
      title: "Update Daily Report",
      body: (
        <>
          <div className="w-100">
            Please wait while we're retrieving and organize the data !
            <div className="__custom-loader"></div>
          </div>
        </>
      ),
    });
    delay(1000).then(() => {
      setVis({
        modal: true,
        title: `Daily Report ${data.name}`,
        body: (
          <EditAttendance
            data={data}
            cs={cs}
            handleClose={() => setVis({ modal: false })}
          />
        ),
      });
    });
  };
  const handleResendAttendance = async (att, cs) => {
    setVis({
      modal: true,
      title: "Sending Data",
      body: (
        <>
          <div className="w-100">
            Please wait while we're retrieving the data and re-sending the Daily
            Report !
          </div>
        </>
      ),
    });
    try {
      let res = await GetResourceDetail("Student", att.student);
      console.log(res);
      let mobile = [];
      await mobile.push(res.data.student_mobile_number);
      await res.data.guardians.forEach((e) => {
        if (e) {
          mobile.push(e.mobile_number);
        }
      });
      await console.log("mobile", mobile);
      for (const e of mobile) {
        let msg = {
          ...att,
          cs_id: cs.name || "-",
          cs_date: LongDate(cs.schedule_date) || "-",
          cs_time: cs.from_time || "-",
          additional_info: `🚀 RESEND | ${ShortDateFull(new Date())} 🚀`,
          instructor_name: fullname || "-",
        };
        let wasend = await SendWaTemplate(e, msg, "student_attendance");
        if (!wasend.ok) {
          const errResponse = await wasend.json();
          throw errResponse;
        }
      }
      let content = `✈ Resend Student Attendance Report on ${ShortDate(
        new Date()
      )}, to ${mobile.join(", ")}.`;
      let body = {
        comment_type: "Comment",
        comment_by: fullname,
        reference_doctype: "Student Attendance",
        reference_name: att.name,
        content: content,
      };
      await PostResource("Comment", body);
      setVis({
        modal: true,
        title: "Sending Success",
        body: (
          <>
            <div className="w-100">{content} Send Success!</div>
          </>
        ),
      });
    } catch (error) {
      setVis({
        modal: true,
        title: "Ups there is an error",
        body: (
          <>
            <div className="w-100">
              {error.message}
              <br />
              <small>
                Please try again later.If the problem persist please contact the
                administrator.
              </small>
              <br />
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    let msg = {
                      receiver: "6287832340390",
                      message: JSON.stringify(error),
                      session: "628112971117",
                    };
                    let wasen = await SendWa("6287832340390", msg);
                    if (!wasen.ok) {
                      const errResponse = await wasen.json();
                      throw errResponse;
                    }
                    alert("Message send succesfully!");
                  } catch (err) {
                    console.log(err);
                    alert(
                      "Ups, sorry. Cannot send the message now. There is an unknown error."
                    );
                  }
                }}
              >
                Send Error
              </button>
            </div>
          </>
        ),
      });
    }
  };
  const handleNoteAttendance = (data, name) => {
    setVis({
      modal: true,
      title: "Response List",
      body: (
        <StudentAttendanceResponse
          data={data}
          fullname={fullname}
          name={name}
          handleClose={() => setVis({ modal: false })}
        />
      ),
    });
  };
  return (
    data && (
      <>
        <Modal
          modal={vis.modal}
          title={vis.title}
          element={vis.body}
          handleClose={() => setVis({ modal: false })}
        />
        <div
          className="w-100"
          key={data.att.name}
          style={{ display: "flex", flexDirection: "row", gap: "7px" }}
        >
          <div
            style={{
              width: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="__date_month">
              {
                monthShort[
                  parseInt(data.cs.schedule_date.substring(5, 7), 10) - 1
                ]
              }
            </div>
            <div className="__date_day">
              {parseInt(data.cs.schedule_date.substring(8, 10), 10)}
            </div>
            <div
              className="__btnattcontainer"
              style={{ padding: "30px 0", fontSize: "10px" }}
            >
              <div
                className="__btnatt"
                style={{
                  "--bgbtnatt": "#FFEE93",
                  "--clbtnatt": "#555",
                }}
                onClick={() => handleEditAttendance(data.att, data.cs)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-vector-pen"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.646.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-1.902 1.902-.829 3.313a1.5 1.5 0 0 1-1.024 1.073L1.254 14.746 4.358 4.4A1.5 1.5 0 0 1 5.43 3.377l3.313-.828L10.646.646zm-1.8 2.908-3.173.793a.5.5 0 0 0-.358.342l-2.57 8.565 8.567-2.57a.5.5 0 0 0 .34-.357l.794-3.174-3.6-3.6z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2.832 13.228 8 9a1 1 0 1 0-1-1l-4.228 5.168-.026.086.086-.026z"
                  />
                </svg>
                <br />
                <small>edit</small>
              </div>
              <div
                className="__btnatt"
                style={{
                  "--bgbtnatt": "#F3A738",
                  "--clbtnatt": "#555",
                }}
                onClick={() => handleResendAttendance(data.att, data.cs)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                </svg>
                <br />
                <small>resend</small>
              </div>
              <div
                className="__btnatt"
                style={{
                  "--bgbtnatt": "#79ADDC",
                  "--clbtnatt": "#fff",
                }}
                onClick={() =>
                  handleNoteAttendance(
                    comment.filter(
                      (f) => f.reference_name === data.attendance_name
                    ),
                    data.att.name
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                </svg>
                <br />
                <small>respon</small>
                <div className="__btnattnotif">
                  <p>
                    {comment.filter(
                      (f) => f.reference_name === data.attendance_name
                    ).length || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-100"
            style={{
              background: "white",
              borderRadius: "5px",
              padding: "5px 15px",
            }}
          >
            <div
              style={{
                position: "relative",
                borderBottom: "solid 1px grey",
                marginBottom: "30px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  fontSize: "14px",
                }}
              >
                {
                  {
                    0: (
                      <i
                        className="bi bi-check2"
                        style={{
                          color: "grey",
                          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
                        }}
                      ></i>
                    ),
                    1: (
                      <i
                        className="bi bi-check2"
                        style={{
                          color: "limegreen",
                          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
                        }}
                      ></i>
                    ),
                    2: (
                      <i
                        className="bi bi-check2"
                        style={{
                          color: "red",
                          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
                        }}
                      ></i>
                    ),
                  }[data.att.docstatus]
                }
              </div>
              <div style={{ paddingBottom: "20px", lineHeight: "1" }}>
                <div style={{ padding: "5px 0" }}>
                  <span className="__cs_hour">
                    {data.cs.from_time.split(":")[0]}
                  </span>
                  .{data.cs.from_time.split(":")[1]}-
                  <span className="__cs_hour">
                    {data.cs.to_time.split(":")[0]}
                  </span>
                  .{data.cs.to_time.split(":")[1]}
                </div>
                <div>
                  <span style={{ fontSize: "12px", color: "grey" }}>
                    {data.cs.name}
                  </span>
                  <br />
                  {data.att.student_group}
                  <br />
                  {data.cs.course.split(" - ")[1]}
                </div>
              </div>
              <span
                style={{
                  color: "lightgrey",
                  fontWeight: "bold",
                  fontSize: "24px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                {data.att.attendance_status
                  ? data.att.attendance_status.toUpperCase()
                  : data.att.status.toUpperCase()}
              </span>
              <div
                className="w-100"
                style={{
                  color: "lightgrey",
                  fontSize: "11px",
                  position: "absolute",
                  bottom: "-15px",
                  right: "0",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{data.attendance_name}</span>
                <span>{ShortDateFull(data.att.creation)}</span>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div
                className="w-100"
                style={{ borderBottom: "solid 1px grey", lineHeight: "1" }}
              >
                <small style={{ fontSize: "12px", color: "grey" }}>
                  <i>{data.att.student}</i>
                </small>
                <br />
                <p>{data.att.student_name}</p>
              </div>
              <div style={{ position: "absolute", top: "-10px", right: "0" }}>
                <div style={{ display: "flex" }}>
                  {[...Array(5)].map((star, i) => {
                    i += 1;
                    return (
                      <div
                        type="star-rating-button"
                        key={i}
                        className={i <= data.att.growth_point ? "on" : "off"}
                        style={{ color: "red" }}
                      >
                        <i
                          className={
                            i <= data.att.growth_point
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                        ></i>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div style={{ height: "150px", overflowY: "auto" }}>
                {data.att.lesson && (
                  <>
                    <small>
                      <b>Lesson : </b>
                    </small>
                    <p
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {data.att.lesson}
                    </p>
                  </>
                )}
                {data.att.comment && (
                  <>
                    <small>
                      <b>Comment : </b>
                    </small>
                    <p
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {data.att.comment}
                    </p>
                  </>
                )}
                {data.att.video_url && (
                  <>
                    <small>
                      <b>Video Url : </b>
                    </small>
                    <p
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {data.att.video_url}
                    </p>
                  </>
                )}
              </div>
              <div className="w-100" style={{ height: "15px" }}></div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default StudentAttendance;
