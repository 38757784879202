import React from "react";
import { NavLink, Link } from "react-router-dom";
import useWindow from "../custom/useWindow";
import Logo from "../assets/img/logow.png";
import handleLogout from "../custom/logout";
import DashProfilePic from "./dashProfilePic";

const DashNav = (props) => {
  const { width } = useWindow();
  const usr = JSON.parse(sessionStorage.getItem("usr"));

  return (
    <>
      <div>
        {width > 450 ? (
          <div
            style={{
              minWidth: "230px",
              maxWidth: "250px",
              overflowY: "auto",
              padding: "0 1rem 1rem 1rem",
            }}
            className="b-menu d-flex flex-column flex-shrink-0  text-white bg-dark"
          >
            <Link
              to={"/d"}
              className="d-flex align-items-center p-2  text-white text-decoration-none"
            >
              <img
                src={Logo}
                style={{ width: "75px" }}
                alt="SMI Logo"
                className="logo"
              />
              <span>{width > 650 ? ".EDUCATION" : ""}</span>
            </Link>
            <hr style={{ marginTop: "0px" }} />
            <div className="d-flex align-items-center">
              <div
                className="dashProfileContainer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Link
                  to="/d/e"
                  className="nav-link text-white"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                > */}
                {/* {console.log(usr)} */}
                {usr && <DashProfilePic userImage={usr.user_image} />}
                <div className="w-100" style={{ height: "25px" }}></div>
                <div>
                  <p>
                    <small>
                      <b>{usr.full_name}</b>
                    </small>
                    <br />
                    <small>{usr.name0}</small>
                  </p>
                  <hr />
                  <small>
                    Teacher ID :<br />
                    {sessionStorage
                      .getItem("instructor_id")
                      .split(",")
                      .map((ins) => (
                        <p key={ins}>
                          {ins}
                          <br />
                        </p>
                      ))}
                  </small>
                </div>
                {/* </Link> */}
              </div>
            </div>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
              <li>
                <Link
                  to="/d"
                  className="nav-link text-white"
                  aria-current="page"
                >
                  <i className="bi bi-house"></i> Dashboard
                </Link>
              </li>
              <li>
                <NavLink
                  to="/d/e"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                  aria-current="page"
                >
                  <i className="bi bi-laptop"></i> Staff
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/d/student"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                >
                  <i className="bi bi-person-square"></i> Student
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/d/course"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                >
                  <i className="bi bi-calendar3"></i> Schedule
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/d/riwayat"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                >
                  <i className="bi bi-layout-text-sidebar-reverse"></i> History
                </NavLink>
              </li>
              <hr />
              <li>
                <Link
                  to={"/login"}
                  className="nav-link text-white"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-right"></i> Log Out
                </Link>
              </li>
            </ul>
            <div className="dropdown" style={{ opacity: "10%" }}>
              <img
                src=""
                style={{ width: "75px" }}
                alt="Logo"
                className="logo"
              />
            </div>
          </div>
        ) : (
          <div className="w-100 m__menu">
            <div
              className="row subm__menu mb-auto"
              style={{ padding: "0 25px" }}
            >
              {/* <div className="d-flex align-items-center">
              
            </div> */}

              {/* <div className="row nav nav-pills flex-column mb-auto"> */}
              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <Link
                  to="/d"
                  className="nav-link text-white"
                  // style={({ isActive }) => {
                  //   return {
                  //     borderBottom: isActive ? "3px solid grey" : "",
                  //   };
                  // }}
                  aria-current="page"
                >
                  <i className="bi bi-house"></i>
                </Link>
              </div>
              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <NavLink
                  to="/d/e"
                  className="nav-link text-white"
                  style={({ isActive }) => {
                    return {
                      borderBottom: isActive ? "3px solid grey" : "",
                    };
                  }}
                  aria-current="page"
                >
                  <i className="bi bi-laptop"></i>
                  {/* {usr && (
                    <div
                      style={{
                        background: `url('${
                          usr.user_image === null
                            ? propic
                            : usr.user_image.includes("/files/")
                            ? "https://sister.sekolahmusik.co.id" +
                              usr.user_image
                            : usr.user_image
                        }') center center / cover`,
                        height: "23px",
                        width: "23px",
                        borderRadius: "2px",
                        border: "1px solid gray",
                      }}
                    ></div>
                  )} */}
                </NavLink>
              </div>
              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <NavLink
                  to="/d/student"
                  className="nav-link text-white"
                  style={({ isActive }) => {
                    return {
                      borderBottom: isActive ? "3px solid grey" : "",
                    };
                  }}
                >
                  <i className="bi bi-person-square"></i>
                </NavLink>
              </div>
              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <NavLink
                  to="/d/course"
                  className="nav-link text-white"
                  style={({ isActive }) => {
                    return {
                      borderBottom: isActive ? "3px solid grey" : "",
                    };
                  }}
                >
                  <i className="bi bi-calendar3"></i>
                </NavLink>
              </div>

              {/* <div className="col-3">
                <NavLink
                  to="/d/qrcam"
                  className="nav-link text-white"
                  style={{
                    borderBottom:
                      window.location.pathname === "/d/qrcam"
                        ? "3px solid grey"
                        : "",
                  }}
                >
                  <svg className="bi me-2" width="55 " height="55">
                    <use xlinkHref="#qr" />
                  </svg>
                </NavLink>
              </div> */}

              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <NavLink
                  to="/d/riwayat"
                  className="nav-link text-white"
                  style={({ isActive }) => {
                    return {
                      borderBottom: isActive ? "3px solid grey" : "",
                    };
                  }}
                >
                  <i className="bi bi-layout-text-sidebar-reverse"></i>
                </NavLink>
              </div>
              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                {/*<NavLink
                  to="/d/profile"
                  className="nav-link text-white"
                  style={{
                    borderBottom:
                      window.location.pathname === "/d/profile"
                        ? "3px solid grey"
                        : "",
                  }}
                >
                   <svg className="bi me-2" width="16" height="16">
                  <use xlinkHref="#people-circle" />
                </svg> 
                </NavLink>*/}
                <NavLink
                  to={"/login"}
                  className="nav-link text-white"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                </NavLink>
              </div>
            </div>
          </div>
          // </div>
        )}
      </div>
    </>
  );
};

export default DashNav;
