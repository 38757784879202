import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ListGuardian from "./listGuardian";
import Relation from "./relation";

const Form3 = () => {
  const location = useLocation();
  const [form, setForm] = useState(location.state);
  const [err, setErr] = useState({});
  const [list, setList] = useState({
    name: "",
    relation: "",
    email: "",
    mobile: "",
    job: "",
    sosmed: "",
  });
  const [lists, setLists] = useState(
    location.state && location.state.guardian ? location.state.guardian : []
  );
  const [btnNext, setBtnNext] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);
  };

  const handleList = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    // console.log({ [name]: value });
    const rn = /^[0-9\b]+$/;
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    let newForm = { ...list, [name]: trimmedValue };
    let newErr = { ...err };

    switch (name) {
      case "mobile":
        if (trimmedValue === "" || rn.test(trimmedValue)) {
          newErr[name] = "";
        } else {
          newErr[name] = "Number only";
        }
        break;
      case "email":
        if (trimmedValue === "" || re.test(trimmedValue)) {
          newErr[name] = "";
        } else {
          newErr[name] = "Please input a valid format email.";
        }
        break;
      default:
        newForm[name] = trimmedValue;
        break;
    }

    setList({ ...newForm });
    setErr(newErr);

    if (list.mobile && list.email && list.name && list.relation) {
      setBtnNext(true);
    } else {
      setBtnNext(false);
    }
  };

  const handleListAdd = (e) => {
    e.preventDefault();

    if (
      list.name !== "" &&
      list.relation !== "" &&
      list.email !== "" &&
      list.mobile !== ""
    ) {
      setLists((prevLists) => [...prevLists, list]);
      setForm((prevForm) => ({
        ...prevForm,
        guardian: [...(prevForm.guardian || []), list],
      }));
      setBtnNext(true);
    } else {
      alert("Please complete the data");
    }

    setList({
      name: "",
      relation: "",
      email: "",
      mobile: "",
      job: "",
      sosmed: "",
    });
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  const handleClear = (e) => {
    e.preventDefault();
    setForm({ ...form, guardian: "" });
    setList({
      name: "",
      relation: "",
      email: "",
      mobile: "",
      job: "",
      sosmed: "",
    });
  };

  const handleDelete = (index) => {
    const newList = lists.filter((_, i) => i !== index);
    setLists(newList);
    setForm({ ...form, guardian: newList.length === 0 ? "" : newList });
    setBtnNext(newList.length > 0);
  };
  return (
    <>
      <div className="w-100 form-title">
        <h1>DETAIL WALI MURID </h1> <i>Student Guardian Details</i>
      </div>
      <div className="w-100" style={{ height: "25px" }}>
        {/* {JSON.stringify(form)} <hr />
        {JSON.stringify(list)} <hr />
        {JSON.stringify(lists)} */}
      </div>

      {form === null ? (
        <>
          <div>
            <p>"Ups anda belum mengisi data sebelum nya"</p>
          </div>
          <div>
            <Link to="/">
              <button className="btn btn-primary" style={{ width: "150px" }}>
                Start Here
              </button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <form
            className="w-100"
            style={{ lineHeight: "1" }}
            onSubmit={handleSubmit}
          >
            {/* Guardian */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className="row col-md-8"
                style={{ padding: "5px 0px", margin: "0px" }}
              >
                <div
                  className="w-100 form-title"
                  style={{ padding: "0px 0px", margin: "0px" }}
                >
                  <p>Detail Guardian.</p>
                  <hr />
                </div>
                {/* Nama */}
                <div
                  className="row col-md-12"
                  style={{ padding: "5px 0px", margin: "0px" }}
                >
                  <div
                    className="row col-md-6 "
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <label className="col-md-4 col-4">
                      Nama <span className="text-danger">*</span>
                      <br />
                      <small>
                        <i>Name</i>
                      </small>
                    </label>
                    <div className="col-md-8 col-8">
                      <input
                        // required={true}
                        onChange={handleList}
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        value={list.name}
                      />
                    </div>
                  </div>
                  <div
                    className="row col-md-6 "
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <label className="col-md-4 col-4">
                      Relasi <span className="text-danger">*</span>
                      <br />
                      <small>
                        <i>Relationship</i>
                      </small>
                    </label>
                    <div className="col-md-8 col-8">
                      <Relation
                        data={list.relation}
                        handleChange={handleList}
                      />
                    </div>
                  </div>
                </div>

                {/* whatsapp & Email */}
                <div
                  className="row col-md-12"
                  style={{ padding: "5px 0px", margin: "0px" }}
                >
                  <div
                    className="row col-md-6 "
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <label className="col-md-4 col-4">
                      Nomor Whatsapp <span className="text-danger">*</span>
                      <br />
                      <small>
                        <i>Whatsapp Number</i>
                      </small>
                    </label>
                    <div className="col-md-8 col-8">
                      {err.mobile !== "" ? (
                        <small className="text-danger">{err.mobile}</small>
                      ) : (
                        ""
                      )}
                      <input
                        // required={true}
                        onChange={handleList}
                        type="text"
                        className="form-control"
                        name="mobile"
                        id="mobile"
                        placeholder="628112345.. / 08112345.."
                        aria-describedby="mobileHelp"
                        value={list.mobile}
                      />
                    </div>
                  </div>
                  <div
                    className="row col-md-6"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <label className="col-md-4 col-4">
                      Surel <span className="text-danger">*</span>
                      <br />
                      <small>
                        <i>E-Mail</i>
                      </small>
                    </label>
                    <div className="col-md-8 col-8">
                      {err.email !== "" ? (
                        <small className="text-danger">{err.email}</small>
                      ) : (
                        ""
                      )}
                      <input
                        onChange={handleList}
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        aria-describedby="emailHelp"
                        value={list.email || ""}
                      />
                    </div>
                  </div>
                </div>

                {/* Pekerjaan & Sosmed Akun */}
                <div
                  className="row col-md-12"
                  style={{ padding: "5px 0px", margin: "0px" }}
                >
                  <div
                    className="row col-md-6 "
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <label className="col-md-4 col-4">
                      Pekerjaan
                      <br />
                      <small>
                        <i>Occupation</i>
                      </small>
                    </label>
                    <div className="col-md-8 col-8">
                      <input
                        onChange={handleList}
                        type="text"
                        className="form-control"
                        name="job"
                        id="job"
                        aria-describedby="nickHelp"
                        value={list.job}
                      />
                    </div>
                  </div>
                  <div
                    className="row col-md-6"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <label className="col-md-4 col-4">
                      Akun SosMed
                      <br />
                      <small>
                        <i>SosMed Account</i>
                      </small>
                    </label>
                    <div className="col-md-8 col-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">@</div>
                        </div>
                        <input
                          // required={false}
                          onChange={handleList}
                          type="text"
                          className="form-control"
                          name="sosmed"
                          id="sosmed"
                          placeholder="@john.smith"
                          aria-describedby="nickHelp"
                          value={list.sosmed}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-100" style={{ height: "25px" }}></div>
              </div>

              {/* Guardian List */}
              <div className="col-md-4">
                {/* Program List */}
                <ListGuardian
                  handleDelete={handleDelete}
                  data={form.guardian || []}
                />
                <div className="w-100" style={{ height: "25px" }}></div>
              </div>

              <div className="w-100" style={{ height: "25px" }}></div>
              <span className="text-description">
                Klik tombol Add untuk menambahkan data orang tua.
              </span>
              <div className="w-100" style={{ textAlign: "center" }}>
                <button className="btn btn-primary" onClick={handleListAdd}>
                  Add
                </button>

                <button className="btn btn-primary" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
            <div className="w-100" style={{ height: "25px" }}></div>

            <div className="w-100" style={{ textAlign: "center" }}>
              <Link to="/student" state={form}>
                <button
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                  type="submit"
                >
                  Back
                </button>
              </Link>
              {btnNext || form.guardian !== "" ? (
                <Link to="/course" state={form}>
                  <button
                    className="btn btn-primary"
                    style={{ width: "150px" }}
                    type="submit"
                    // onClick={() => handleNext()}
                  >
                    Next
                  </button>
                </Link>
              ) : (
                <Link to="/course" state={form}>
                  <button
                    className="btn btn-primary"
                    style={{ width: "150px" }}
                  >
                    Skip
                  </button>
                </Link>
              )}
            </div>
          </form>
        </>
      )}
      <span className="text-danger">* = Mandatory / Harus di isi.</span>
    </>
  );
};

export default Form3;
