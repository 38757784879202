import React, { useCallback, useState } from "react";

const CroStudentTitle = ({ data, handleStatus }) => {
  const [status, setStatus] = useState("");
  const handleStatusClick = useCallback(
    (e) => {
      setStatus(e);
      handleStatus(e);
    },
    [handleStatus]
  );
  const statuses = ["Trial", "Waiting", "Active", "Leave", "Idle", "Out"];
  const statusCounts = statuses.reduce((acc, status) => {
    acc[status] = data.filter((f) => f.status === status).length;
    return acc;
  }, {});
  return (
    <div className="__top_content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="col-md-6 col-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div
            className="page-title"
            style={{
              color: "#212529",
              lineHeight: "1",
              padding: "0",
            }}
          >
            STUDENT LIST
          </div>
        </div>
        <div
          className="col-md-6 col-9"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 15px",
                alignItems: "center",
                fontSize: "12px",
                margin: "0",
                color: "grey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "5px",
                  margin: "0",
                  width: "100%",
                  borderRadius: "5px",
                  background: "#cdcdcd",
                }}
              >
                {statuses.slice(0, -1).map((stat) => (
                  <div
                    key={stat}
                    className={`__studentnumbercontainer${
                      status === stat ? "active" : ""
                    }`}
                    onClick={() => handleStatusClick(stat)}
                    style={{
                      borderRadius: "5px",
                      background: "#cdcdcd",
                      cursor: "pointer",
                    }}
                  >
                    <div className="__studentnumber">{statusCounts[stat]}</div>
                    <p style={{ margin: "0", padding: "0 5px" }}>
                      {stat.toUpperCase()}
                    </p>
                  </div>
                ))}

                <div
                  className={`__studentnumbercontainer`}
                  style={{
                    fontSize: "20px",
                    position: "relative",
                  }}
                >
                  <i
                    className="bi bi-square"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ position: "absolute" }}>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0",
                          margin: "0",
                          position: "relative",
                          top: "-3px",
                          left: "0",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        =
                      </span>
                    </div>
                  </i>
                </div>

                <div className={`__studentnumbercontainer`}>
                  <div className="__studentnumber">
                    {statuses
                      .slice(0, -1)
                      .reduce((sum, stat) => sum + statusCounts[stat], 0)}
                  </div>
                  <p style={{ margin: "0", padding: "0 5px" }}>TOTAL</p>
                </div>
              </div>
              <div style={{ width: "15px" }}></div>
              <div
                className={`__studentnumbercontainer${
                  status === "Out" ? "active" : ""
                }`}
                onClick={() => handleStatusClick("Out")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  margin: "0",
                  background: "crimson",
                  cursor: "pointer",
                }}
              >
                <div
                  className="col-md-12"
                  style={{
                    textAlign: "center",
                    color: "white",
                    width: "fit-content",
                    // minWidth: width > 450 ? "100px" : "",
                  }}
                >
                  <div className="__studentnumber">{statusCounts["Out"]}</div>
                  <p style={{ margin: "0", padding: "0" }}>OUT</p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <hr style={{ marginTop: "0px" }} />
    </div>
  );
};

export default CroStudentTitle;
