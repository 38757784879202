import propic from "../assets/img/propic.png";
import { useState } from "react";
import {
  GetResourceDetail,
  UpdateResource,
  UploadFile,
} from "../custom/apiResource";
import { apiSite } from "../config/urlLink";
import { redirectToPage } from "../custom/pageFn";
import LoadingComponent from "../components/loading";

const DashStudentPic = ({ userImage, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleUploadImage = async (e) => {
    setIsLoading(true);
    try {
      let stud = await GetResourceDetail("Student", id);
      stud = await stud.data;

      let res = await UploadFile(e.target.files[0], "profile");
      await UpdateResource("User", stud.user, {
        user_image: res.file_url,
      });

      redirectToPage(0.3);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <LoadingComponent loading={isLoading} text="Uploading..." />
      <div
        style={{
          position: "relative",
          height: "60px",
          width: "60px",
          borderRadius: "5px",
          border: "1px solid rgb(0 0 0 /.3)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            background: `url('${
              userImage === null || userImage === undefined
                ? propic
                : userImage.includes("/files/")
                ? apiSite + userImage
                : userImage
            }') center center / cover`,
            height: "100%",
            width: "100%",
            borderRadius: "3px",
          }}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            opacity: isHovered ? "1" : "0",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "3px",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          <label
            htmlFor="uploadphoto"
            style={{ color: "white", cursor: "pointer", fontSize: "12px" }}
          >
            <i className="bi bi-camera" style={{ fontSize: "24px" }}></i>
            <br />
            UPLOAD
          </label>
          <input
            id="uploadphoto"
            type="file"
            style={{ display: "none" }}
            onChange={handleUploadImage}
          />
        </div>
      </div>
    </>
  );
};

export default DashStudentPic;
