import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import routes from "../config/routes";
import handleLogout from "../custom/logout";
import useWindow from "../custom/useWindow";
import DashStaffMenu from "./dashStaffMenu";

const DashStaff = () => {
  const { width } = useWindow();
  const usr = JSON.parse(sessionStorage.getItem("usr"));
  return (
    <>
      <div className="w-100 " style={{ overflowY: "auto" }}>
        <div className="__top_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="page-title" style={{ color: "#212529" }}>
              STAFF ADMINISTRATION
            </div>
            {width > 450 ? (
              ""
            ) : (
              <Link to={"/"} className="nav-link" onClick={handleLogout}>
                <button
                  type="button"
                  className="btn btn-default btn-lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></i>
                </button>
              </Link>
            )}
          </div>
        </div>
        <hr style={{ marginTop: "0px" }} />
        <DashStaffMenu />
        <Routes>
          {routes.staff.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={route.element}
            />
          ))}
        </Routes>
      </div>
    </>
  );
};

export default DashStaff;
