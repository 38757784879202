// option value
// numeric
// 2-digit
// long
// short
// narrow
// https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020

const ShortDate = (date) => {
  const options = {
    weekday: "short",
    year: "2-digit",
    month: "short",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString("id-ID", options);
};
const ShortDateNumber = (date) => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("id-ID", options);
};
const ShortDateFull = (date) => {
  const options = {
    weekday: "short",
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("id-ID", options);
};
const LongDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString("id-ID", options);
};
function CalculateAge(dateOfBirth) {
  let today = new Date();
  let birthDate = new Date(dateOfBirth);
  let ageInMilliseconds = today - birthDate;
  let ageInSeconds = ageInMilliseconds / 1000;
  let ageInMinutes = ageInSeconds / 60;
  let ageInHours = ageInMinutes / 60;
  let ageInDays = ageInHours / 24;
  let ageInYears = ageInDays / 365;
  let ageInMonths = (ageInYears - Math.floor(ageInYears)) * 12;
  // let ageInDaysRounded = Math.round(
  //   ageInDays - (ageInYears * 365 + ageInMonths * 30)
  // );
  let age =
    pad(Math.floor(ageInYears), 2) +
    " yr " +
    pad(Math.floor(ageInMonths), 2) +
    " m";
  // +
  // pad(ageInDaysRounded, 2) +
  // " d";
  return age;
}
function pad(num, size) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function CompDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Add 1 because the months are zero-indexed
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${pad(day, 2)}-${pad(month, 2)}-${year}`;
  const formattedTime = `${pad(hours, 2)}:${pad(minutes, 2)}`;
  const dateTime = `${formattedDate} ${formattedTime}`;
  return dateTime;
}

export {
  ShortDate,
  ShortDateNumber,
  ShortDateFull,
  LongDate,
  CalculateAge,
  CompDate,
};
