import React from 'react'
import CroStudent from './croStudent'
import { StudentsContextProvider } from '../../context/studentsContext'

const CroDash = () => {
    return (

        <StudentsContextProvider>
            <CroStudent />
        </StudentsContextProvider>
    )
}

export default CroDash