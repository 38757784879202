import React, { useState } from "react";
import studdis from "../assets/img/studdis.png";
import studonly from "../assets/img/studonly.png";
import studwpar from "../assets/img/studwpar.png";
import useWindow from "../custom/useWindow";

const FormStudType = ({ handleData, data }) => {
  const { width } = useWindow();
  return (
    <>
      <div
        className="w-100"
        style={{
          textAlign: "center",
          fontSize: width > 450 ? "24px" : "18px",
          fontWeight: "500",
          color: "coral",
          padding: "25px 0 0 0",
        }}
      >
        WHO WILL REGISTER
      </div>
      <div className="w-100" style={{ padding: "25px" }}>
        <div
          className="w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: `url(${
                { 1: studdis, 2: studwpar, 3: studonly }[data.type]
              }) no-repeat center center / cover`,
              width: width > 450 ? "200px" : "150px",
              height: width > 450 ? "200px" : "150px",
            }}
          ></div>
          <p>
            {
              {
                1: "Please choose",
                2: "STUDENT WITH GUARDIAN",
                3: "STUDENT ONLY",
              }[data.type]
            }
          </p>
        </div>
        <div className="w-100">
          <div className="input-group">
            <div className="input-group-text">
              <input
                className="form-check-input mt-0"
                type="radio"
                name="type"
                value="2"
                checked={"2" === data.type}
                onChange={handleData}
              />
            </div>
            <input
              type="text"
              className="form-control"
              value="Student w/ Guardian"
              disabled
              style={{ background: "white" }}
            />
          </div>
          <div className="input-group">
            <div className="input-group-text">
              <input
                className="form-check-input mt-0"
                type="radio"
                name="type"
                value="3"
                checked={"3" === data.type}
                onChange={handleData}
              />
            </div>
            <input
              type="text"
              className="form-control"
              value="Student Only"
              disabled
              style={{ background: "white" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormStudType;
