import React from "react";

const ListGuardian = (props) => {
  const { data } = props;
  const handleDelete = (e, i) => {
    e.preventDefault();
    props.handleDelete(i);
  };
  return (
    <>
      <div>
        {/* {console.log(data)} */}
        {data && data.length === 0 && (
          <div>Anda Belum menambahkan Guardian!</div>
        )}
      </div>

      {data &&
        data.map((e, i) => (
          <div
            className="card row"
            style={{ margin: "10px", padding: "5px" }}
            key={`${e.name}-${e.relation}|${e.job}`}
          >
            {console.log(e)}
            <table style={{ margin: "10px" }}>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>: {e.name}</td>

                  <td rowSpan="3">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(del) => handleDelete(del, i)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Relasi</td>
                  <td>: {e.relation}</td>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <td>: {e.mobile}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>: {e.email}</td>
                </tr>
                <tr>
                  <td>Job</td>
                  <td>: {e.job}</td>
                </tr>
                <tr>
                  <td>Sosmed</td>
                  <td>: {e.sosmed}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
    </>
  );
};

export default ListGuardian;
