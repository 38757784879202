import React from "react";

const ListCourse = (props) => {
  const { data } = props;
  const handleDelete = (e, i) => {
    e.preventDefault();
    console.log(e);
    props.handleDelete(i);
  };
  return (
    <>
      <div>
        {/* {console.log(data)} */}
        {data && data.length === 0 && (
          <div>Anda Belum menambahkan program!</div>
        )}
      </div>
      {/* {console.log(data)} */}
      {data &&
        data.map((e, i) => (
          <div
            className="card row"
            style={{ margin: "10px" }}
            key={`${e.id}${e.format}-${e.major}-${e.duration}-${e.type}`}
          >
            {/* {console.log(e)} */}
            <table style={{ margin: "10px" }}>
              <tbody>
                <tr>
                  <td>Class Format</td>
                  <td>: {e.format}</td>
                  <td rowSpan="3">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(del) => handleDelete(del, i)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Major</td>
                  <td>: {e.major}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>: {e.duration}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>: {e.type}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
    </>
  );
};

export default ListCourse;
