import React from "react";
import useResource from "../custom/useResource";
import { ShortDate, ShortDateFull } from "../custom/dateFormatter";
import useWindow from "../custom/useWindow";

const DashStaffProfile = () => {
  const { width } = useWindow();
  const usid = sessionStorage.getItem("user_id");
  const emp = JSON.parse(sessionStorage.getItem("emp_id"));
  const { data: usr } = useResource(`User/${usid}`, `["*"]`, ``);
  // const { data: em } = useResource(`Employee/${empid.name}`, `["*"]`, ``);
  let user = usr && usr.data;
  // let emp = em && em.data;
  // console.log(empid);
  for (const key in user) {
    //  console.log(key)
  }
  return (
    <>
      <div className="w-100" style={{ padding: "0 50px" }}>
        <div>
          <div style={{ position: "relative" }}>
            USER PROFILE
            {user && (
              <div
                className="text-muted"
                style={{ position: "absolute", right: "0px", bottom: "0px" }}
              >
                <small>
                  <i>last active : {ShortDateFull(user.last_active)}</i>
                </small>
              </div>
            )}
            <hr />
          </div>
          {user && (
            <div className="w-100">
              <div
                className="row col-md-12"
                style={{ padding: "0px", margin: "0px" }}
              >
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      First Name
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={user.first_name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Last Name
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={user.last_name}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div
                className="row col-md-12"
                style={{ padding: "0px", margin: "0px" }}
              >
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Email
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={user.email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Username
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={user.username}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="w-100" style={{ height: "15px" }}></div>
              <div
                className="row col-md-12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0",
                  margin: "0",
                }}
              >
                <div className="input-group">
                  <span className="input-group-text" style={{ width: "125px" }}>
                    User Role
                  </span>
                  <div
                    className="form-control"
                    style={{
                      display: "flex",
                      flexDirection: width > 450 ? "row" : "column",
                    }}
                  >
                    {user.roles.map((d) => (
                      <div style={{ display: "flex", padding: "2px 5px" }}>
                        <div
                          style={{
                            display: "flex",
                            padding: "3px 15px",
                            borderRadius: "3px",
                            background: "#ececec",
                            fontSize: "9px",
                            color: "grey",
                          }}
                        >
                          #{d.role.toUpperCase()}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-100" style={{ height: "15px" }}></div>
              <div className="col-md-12">
                {/* <button className="btn btn-primary">Edit</button> */}
              </div>
            </div>
          )}
        </div>
        <div className="w-100" style={{ height: "25px" }}></div>

        <div>
          <div>
            EMPLOYEMENT PROFILE <hr />
          </div>
          {emp && (
            <div className="w-100">
              <div
                className="row col-md-12"
                style={{ padding: "0px", margin: "0px" }}
              >
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Emp Number
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={emp.name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Company
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={emp.company}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div
                className="row col-md-12"
                style={{ padding: "0px", margin: "0px" }}
              >
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Gender
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={emp.gender}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Emp Type
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={emp.employment_type}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div
                className="row col-md-12"
                style={{ padding: "0px", margin: "0px" }}
              >
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Date of Birth
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={ShortDate(emp.date_of_birth)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-text"
                      style={{ width: "125px" }}
                    >
                      Date Join
                    </span>
                    <input
                      readOnly={true}
                      type="text"
                      value={ShortDate(emp.date_of_joining)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-100" style={{ height: "50px" }}></div>
      </div>
      <div className="w-100" style={{ height: "50px" }}></div>
    </>
  );
};

export default DashStaffProfile;
