import React from "react";

const CroStudentDetails = ({ data }) => {
  return (
    <div>
      <div>
        <p>
          <small>
            <i>{data.name}</i>
          </small>
        </p>
        <b>{data.title}</b>
      </div>
      <div>
        <p>{data.user}</p>
        <p>{data.student_mobile_number}</p>
      </div>
      <div>
        {data.status} - {data.reason_for_leaving}
      </div>
      {/* <div className="w-100">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">
              Fees
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Program
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Link
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
            >
              Disabled
            </a>
          </li>
        </ul>
      </div> */}
      <div className="w-100">
        <hr />
        <p>Program Enrollment</p>
        {data.enrollments.map((pe) => (
          <span className="card col-md-6">
            {pe.name}
            <br />
            {pe.groups.map((gro) => (
              <>{gro.name}</>
            ))}
          </span>
        ))}
      </div>
      <div>
        {data.fees.map((fee) => (
          <>
            {fee.name}
            {fee.payments.map((pay) => (
              <>{pay.name}</>
            ))}
          </>
        ))}
      </div>
    </div>
  );
};

export default CroStudentDetails;
