import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashStudentDet from "./dashStudentDet";
import useWindow from "../custom/useWindow";
import handleLogout from "../custom/logout";
import "../assets/css/dashStud.css";

const DashStudent = () => {
  const { width } = useWindow();
  const [data, setData] = useState([]);
  let sActive = data && data.filter((f) => f.status === "Active");
  let sLeave = data && data.filter((f) => f.status === "Leave");
  let sIdle = data && data.filter((f) => f.status === "Idle");
  let sOut = data && data.filter((f) => f.status === "Out");
  return (
    <>
      <div className="w-100 " style={{ overflow: "hidden" }}>
        <div className="__top_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="col-md-6 col-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: width > 450 ? "20px" : "15px 20px",
              }}
            >
              <div
                className="page-title"
                style={{
                  color: "#212529",
                  lineHeight: "1",
                  padding: "0",
                }}
              >
                STUDENT LIST
              </div>
            </div>
            <div
              className="col-md-6 col-9"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {data && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "0 15px",
                    alignItems: "center",
                    fontSize: "12px",
                    margin: "0",
                    color: "grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "5px 10px",
                      margin: "0",
                      width: "100%",
                      borderRadius: "5px",
                      background: "#cdcdcd",
                    }}
                  >
                    <div className="__studentnumbercontainer">
                      <div className="__studentnumber">{sActive.length}</div>
                      <p style={{ margin: "0", padding: "0 5px" }}>ACTIVE</p>
                    </div>
                    <div className="__studentnumbercontainer">
                      <div className="__studentnumber">{sLeave.length}</div>
                      <p style={{ margin: "0", padding: "0 5px" }}>LEAVE</p>
                    </div>
                    <div className="__studentnumbercontainer">
                      <div className="__studentnumber">{sIdle.length}</div>
                      <p style={{ margin: "0", padding: "0 5px" }}>IDLE</p>
                    </div>

                    <div
                      className="__studentnumbercontainer"
                      style={{
                        fontSize: "20px",
                        position: "relative",
                        padding: "5px 10px",
                      }}
                    >
                      <i
                        className="bi bi-square"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ position: "absolute" }}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0",
                              margin: "0",
                              position: "relative",
                              top: "-3px",
                              left: "0",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            =
                          </span>
                        </div>
                      </i>
                    </div>

                    <div className="__studentnumbercontainer">
                      <div className="__studentnumber">
                        {data && sActive.length + sLeave.length + sIdle.length}
                      </div>
                      <p style={{ margin: "0", padding: "0 5px" }}>TOTAL</p>
                    </div>
                  </div>
                  <div style={{ width: "15px" }}></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      margin: "0",
                      background: "crimson",
                    }}
                  >
                    <div
                      className="col-md-12"
                      style={{
                        textAlign: "center",
                        color: "white",
                        width: "fit-content",
                        // minWidth: width > 450 ? "100px" : "",
                      }}
                    >
                      <div className="__studentnumber">{sOut.length}</div>
                      <p style={{ margin: "0", padding: "0" }}>OUT</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {width > 450 ? (
              ""
            ) : (
              <Link to={"/"} className="nav-link" onClick={handleLogout}>
                <button
                  type="button"
                  className="btn btn-default btn-lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></i>
                </button>
              </Link>
            )}
          </div>
          <hr style={{ marginTop: "0px" }} />
        </div>
        <DashStudentDet handleStudentData={(e) => setData(e)} />

        <div className="w-100" style={{ height: "100px" }}></div>
      </div>
    </>
  );
};

export default DashStudent;
