import React, { useState } from "react";

const Religion = (props) => {
  const [other, setOther] = useState(false);
  const handleChange = (e) => {
    if (e.target.value === "other") {
      setOther(true);
    } else {
      setOther(false);
    }
    props.onChange(e);
  };
  const handleOther = (e) => {
    props.onChange(e);
  };
  return (
    <>
      <div className="row col" style={{ margin: "0px" }}>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="religion"
            value="Islam"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Islam"}
          />
          <label className="form-check-label" htmlFor="Islam">
            Islam
          </label>
        </div>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="religion"
            value="Kristen"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Kristen"}
          />
          <label className="form-check-label" htmlFor="Kristen">
            Kristen
          </label>
        </div>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="religion"
            value="Katolik"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Katolik"}
          />
          <label className="form-check-label" htmlFor="Katolik">
            Katolik
          </label>
        </div>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="religion"
            value="Budha"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Budha"}
          />
          <label className="form-check-label" htmlFor="Budha">
            Budha
          </label>
        </div>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="religion"
            value="Hindu"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Hindu"}
          />
          <label className="form-check-label" htmlFor="Hindu">
            Hindu
          </label>
        </div>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="religion"
            value="Konghucu"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Konghucu"}
          />
          <label className="form-check-label" htmlFor="Konghucu">
            Konghucu
          </label>
        </div>
        <div className="col form-check">
          <div>
            <input
              type="radio"
              className="form-check-input"
              name="religion"
              value="other"
              onChange={handleChange}
              checked={
                props.dataForm &&
                props.dataForm !== "Islam" &&
                props.dataForm !== "Kristen" &&
                props.dataForm !== "Katolik" &&
                props.dataForm !== "Budha" &&
                props.dataForm !== "Hindu" &&
                props.dataForm !== "Konghucu"
              }
            />
            {other ? (
              ""
            ) : (
              <label className="form-check-label" htmlFor="Other">
                Other : _________________
              </label>
            )}
          </div>
          <div>
            {other ? (
              <input
                className="form-control"
                type="text"
                name="religion"
                placeholder="Other"
                onChange={handleOther}
                value={props.dataForm && props.dataForm}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Religion;
