import React from "react";
import { Link } from "react-router-dom";

import useWindow from "../custom/useWindow";

const Hero = () => {
  const { width } = useWindow();
  return (
    <>
      <section style={{ margin: "auto", padding: "0px" }}>
        <div style={{ position: "absolute", top: "25px", left: "25px" }}>
          <Link to="/r">
            <i className="bi bi-award"></i>
          </Link>
        </div>
        <div style={{ position: "absolute", top: "25px", right: "25px" }}>
          <Link to="/login">
            <i className="bi bi-door-open"></i> Login
          </Link>
        </div>
        <div className="row" style={{ margin: "auto", padding: "0px" }}>
          <div
            style={{
              background: "#222222",
              margin: "auto",
              padding: "0px",
              minHeight: "",
              width: "100%",
            }}
          >
            {/* <video
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted
              style={{ margin: "auto", width: "100%" }}
            >
              <source src="" type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            <div
              className="w-100"
              style={{
                background:
                  "url(https://images.pexels.com/photos/6966/abstract-music-rock-bw.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260) center bottom /cover",
                height: "400px",
              }}
            >
              {width > 450 ? (
                <div
                  className=""
                  style={{
                    fontSize: "4rem",
                    borderRadius: "5px",
                    padding: "0px",
                    position: "relative",
                    top: "125px",
                    textAlign: "center",
                    fontWeight: "700",
                    border: "7px solid white",
                    color: "white",
                    width: "50%",
                    margin: "auto",
                    textShadow: "4px 4px 7px rgba(0, 0, 0, 0.79)",
                    boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.79)",
                  }}
                >
                  REGISTER NOW !
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    fontSize: "3rem",
                    borderRadius: "5px",
                    padding: "0px",
                    position: "relative",
                    top: "85px",
                    textAlign: "center",
                    fontWeight: "700",
                    border: "7px solid white",
                    color: "white",
                    width: "80%",
                    margin: "auto",
                    textShadow: "4px 4px 7px rgba(0, 0, 0, 0.79)",
                    boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.79)",
                  }}
                >
                  REGISTER NOW !
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
      </section>
    </>
  );
};

export default Hero;
