import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Similar from "../config/similar";
import Instruments from "./instruments";
import ListCourse from "./listCourse";

const Course = (props) => {
  const location = useLocation();
  const [form, setForm] = useState(location.state);
  // const [form, setForm] = useState({});
  const [btnNext, setBtnNext] = useState(false);
  const [ensemble, setEnsemble] = useState({ major: ["", "", ""], type: "" });
  const [list, setList] = useState({
    format: "",
    major: "",
    duration: "",
    type: "",
  });
  const [lists, setLists] = useState(
    location.state && location.state.course ? location.state.course : []
  );
  const handleInst = (e) => {
    console.log({ [e.target.name]: e.target.value });
    setList({ ...list, [e.target.name]: e.target.value });
    setEnsemble({ ...ensemble, ensemble: false });
  };
  const handleEnsemble = (e) => {
    console.log({ [e.target.name]: e.target.value });
    let ens = ensemble.major;
    if (e.target.name === "ens1") {
      ens[0] = `Sch : ${e.target.value}`;
    }
    if (e.target.name === "ens2") {
      ens[1] = `Ins : ${e.target.value}`;
    }
    if (e.target.name === "ens3") {
      ens[2] = `Grd : ${e.target.value}`;
    }

    let ensJoin = ens.join(" - ");
    if (e.target.name === "type") {
      setList({
        ...list,
        type: `T-Shirt Size : ${e.target.value}`,
      });
    } else {
      setEnsemble({
        ...ensemble,
        major: ens,
        ensemble: true,
      });
      setList({
        ...list,
        format: "Ensemble",
        major: ensJoin,
        duration: "Premium : 60 Menit",
      });
    }
    setLists([list]);
    setForm({
      ...form,
      course: [list],
    });
  };
  const handleInstAdd = (e) => {
    e.preventDefault();
    console.log(e);
    if (list) {
      setLists([...lists, list]);
    } else {
      alert("anda belum melengkapi data");
    }
    if (list) {
      setForm({ ...form, course: [...lists, list] });
    }
    if (lists) {
      setBtnNext(true);
    } else {
      setBtnNext(false);
    }
    setList({
      format: "",
      major: "",
      duration: "",
      type: "",
    });
  };
  const handleClear = (e) => {
    e.preventDefault();
    console.log(e);
    setLists("");
    setList({
      format: "",
      major: "",
      duration: "",
      type: "",
    });
  };
  const handleDelete = (e) => {
    console.log(e);
    let data = lists;
    data.splice(e, 1);
    console.log(data);
    if (data.length === 0) {
      setForm({ ...form, course: "" });
    } else {
      setForm({ ...form, course: data });
    }
  };
  if (form === null) {
    console.log(form);
  } else {
    if (Array.isArray(form.guardian)) {
      let stuEmail = form.email.toLowerCase();
      let stuMobile = form.mobile.toLowerCase();
      let g = form.guardian;
      try {
        g.forEach((e) => {
          let sim = Similar(stuEmail, e.email);
          let mob = Similar(stuMobile, e.mobile);
          console.log(sim, mob);
          if (stuEmail === e.email && stuMobile === e.mobile) {
            alert(
              stuMobile +
                " = " +
                e.mobile +
                "\n" +
                "nomor handphone murid tidak boleh sama dengan nomor handphone orang tua " +
                e.name +
                " & " +
                mob +
                "\n\n" +
                stuEmail +
                " = " +
                e.email +
                "\n" +
                "email murid tidak boleh sama dengan email orang tua " +
                e.name +
                " & " +
                mob +
                "\n\n" +
                "Silahkan cek ulang data anda."
            );
          } else if (stuEmail === e.email) {
            alert(
              stuEmail +
                " = " +
                e.email +
                "\n" +
                "email murid sama dengan email orang tua " +
                e.name +
                " & " +
                sim
            );
          } else if (stuMobile === e.mobile) {
            alert(
              stuMobile +
                " = " +
                e.mobile +
                "\n" +
                "nomor handphone murid sama dengan nomor handphone orang tua " +
                e.name +
                " & " +
                mob
            );
          }
        });
        // console.log(form);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("no guardian");
    }
  }
  return (
    <>
      <div className="w-100 form-title">
        <h1>DETAIL COURSE </h1> <i>Course Details</i>
      </div>
      <div className="w-100" style={{ height: "25px" }}></div>
      {/* <p>{JSON.stringify(form)}</p>
      <p>{JSON.stringify(lists)}</p>
      <p>{JSON.stringify(list)}</p> */}
      {form === null ? (
        <>
          <div>
            <p>"Ups anda belum mengisi data sebelum nya"</p>
          </div>
          <div>
            <Link to="/">
              <button className="btn btn-primary" style={{ width: "150px" }}>
                Start Here
              </button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <form>
            {/* Program */}
            <div
              className="row col-md-12"
              style={{ padding: "5px 0px", margin: "0px" }}
            >
              <div
                className={ensemble.ensemble ? "row col-md-4" : "row col-md-8"}
                style={{ padding: "5px 0px", margin: "0px" }}
              >
                <div
                  className="w-100 form-title"
                  style={{ padding: "0px 0px", margin: "0px" }}
                >
                  <p>Silahkan pilih program yang di inginkan!</p>
                  <small>
                    Notes: <br />
                    - kelas FOM untuk umur 3-5 th <br />- kelas FAS / Harmony
                    untuk umur 5 th keatas
                  </small>
                  <hr />
                </div>
                <div
                  className={ensemble.ensemble ? "col-md-12" : "col-md-3"}
                  style={{ margin: "0px", padding: "0px 15px" }}
                >
                  <span>
                    <strong>CLASS FORMAT</strong>
                  </span>
                  <div className="col form-check">
                    <input
                      type="radio"
                      checked={list.format === "FAS / Private"}
                      className="form-check-input"
                      name="format"
                      id="1 format"
                      value="FAS / Private"
                      onChange={handleInst}
                    />
                    <label className="form-check-label" htmlFor="Konghucu">
                      FAS / Private
                    </label>
                  </div>
                  <div className="col form-check">
                    <input
                      type="radio"
                      checked={list.format === "Harmony"}
                      className="form-check-input"
                      name="format"
                      id="2 format"
                      value="Harmony"
                      onChange={handleInst}
                    />
                    <label className="form-check-label" htmlFor="Konghucu">
                      Harmony / Group
                    </label>
                  </div>
                  <div className="col form-check">
                    <input
                      type="radio"
                      checked={list.format === "FoM"}
                      className="form-check-input"
                      name="format"
                      id="3 format"
                      value="FoM"
                      onChange={handleInst}
                    />
                    <label className="form-check-label" htmlFor="Konghucu">
                      FoM
                    </label>
                  </div>
                  {/* <div className="col form-check">
                    <input
                      type="radio"
                      checked={list.format === "Ensemble"}
                      className="form-check-input"
                      name="format"
                      id="3 format"
                      value="Ensemble"
                      onChange={handleEnsemble}
                      onBlur={handleEnsemble}
                    />
                    <label className="form-check-label" htmlFor="Konghucu">
                      Ensemble
                    </label>
                  </div> */}
                </div>
                {ensemble.ensemble ? (
                  ""
                ) : (
                  <>
                    {/* Instrument */}
                    <div
                      className="row col-md-4 align-items-start justify-content-start"
                      style={{
                        margin: "0px",
                        padding: "0px 15px",
                        borderLeft: "1px solid lightgray",
                      }}
                    >
                      <span>
                        <strong>CLASS MAJOR</strong>
                      </span>
                      <Instruments
                        check={list.major}
                        onChange={handleInst}
                        program={list.format}
                        dis={
                          list.format === null || list.format === undefined
                            ? true
                            : false
                        }
                      />
                    </div>

                    {/* Time */}
                    <div
                      className="col-md-3 "
                      style={{
                        margin: "0px",
                        padding: "0px 15px",
                        borderLeft: "1px solid lightgray",
                      }}
                    >
                      <span>
                        <strong>CLASS DURATION</strong>
                      </span>
                      <div className="col form-check">
                        <input
                          type="radio"
                          disabled={
                            list.major === null || list.major === undefined
                              ? true
                              : false
                          }
                          checked={list.duration === "Lite : 30 Menit"}
                          className="form-check-input"
                          name="duration"
                          id="1 duration"
                          value="Lite : 30 Menit"
                          onChange={handleInst}
                        />
                        <label className="form-check-label" htmlFor="Konghucu">
                          Lite : 30 Menit
                        </label>
                      </div>
                      <div className="col form-check">
                        <input
                          type="radio"
                          disabled={
                            list.major === null || list.major === undefined
                              ? true
                              : false
                          }
                          checked={list.duration === "Regular : 45 Menit"}
                          className="form-check-input"
                          name="duration"
                          id="2 duration"
                          value="Regular : 45 Menit"
                          onChange={handleInst}
                        />
                        <label className="form-check-label" htmlFor="Konghucu">
                          Regular : 45 Menit
                        </label>
                      </div>
                      <div className="col form-check">
                        <input
                          type="radio"
                          disabled={
                            list.major === null || list.major === undefined
                              ? true
                              : false
                          }
                          checked={list.duration === "Premium : 60 Menit"}
                          className="form-check-input"
                          name="duration"
                          id="3 duration"
                          value="Premium : 60 Menit"
                          onChange={handleInst}
                        />
                        <label className="form-check-label" htmlFor="Konghucu">
                          Premium : 60 Menit
                        </label>
                      </div>
                    </div>

                    {/* Type */}
                    <div
                      className="col-md-2 "
                      style={{
                        margin: "0px",
                        padding: "0px 15px",
                        borderLeft: "1px solid lightgray",
                      }}
                    >
                      <span>
                        <strong>CLASS TYPE</strong>
                      </span>
                      <div className="col form-check">
                        <input
                          type="radio"
                          disabled={
                            list.major === null || list.major === undefined
                              ? true
                              : false
                          }
                          checked={list.type === "At SMI"}
                          className="form-check-input"
                          name="type"
                          id="1 type"
                          value="At SMI"
                          onChange={handleInst}
                        />
                        <label className="form-check-label" htmlFor="Konghucu">
                          At SMI
                        </label>
                      </div>
                      <div className="col form-check">
                        <input
                          type="radio"
                          disabled={
                            list.major === null || list.major === undefined
                              ? true
                              : false
                          }
                          checked={list.type === "Online"}
                          className="form-check-input"
                          name="type"
                          id="2 type"
                          value="Online"
                          onChange={handleInst}
                        />
                        <label className="form-check-label" htmlFor="Konghucu">
                          Online
                        </label>
                      </div>
                      <div className="col form-check">
                        <input
                          type="radio"
                          disabled={
                            list.major === null || list.major === undefined
                              ? true
                              : false
                          }
                          checked={list.type === "Go"}
                          className="form-check-input"
                          name="type"
                          id="3 type"
                          value="Go"
                          onChange={handleInst}
                        />
                        <label className="form-check-label" htmlFor="Konghucu">
                          GO
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {ensemble.ensemble ? (
                <div
                  className="row col-md-8"
                  style={{ margin: "0", padding: "0" }}
                >
                  <span>
                    <strong>ADDITIONAL INFORMATION</strong>
                  </span>
                  <hr />
                  <div className="col-md-6">
                    <label>
                      <small>Asal Sekolah Musik</small>
                    </label>
                    <input
                      onChange={handleEnsemble}
                      onBlur={handleEnsemble}
                      type="text"
                      className="form-control"
                      name="ens1"
                      value={ensemble.ens1}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      <small>Instrument yang di kuasai</small>
                    </label>
                    <input
                      onChange={handleEnsemble}
                      onBlur={handleEnsemble}
                      type="text"
                      className="form-control"
                      name="ens2"
                      value={ensemble.ens2}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      <small>Grade</small>
                    </label>
                    <input
                      onChange={handleEnsemble}
                      onBlur={handleEnsemble}
                      type="text"
                      className="form-control"
                      name="ens3"
                      value={ensemble.ens3}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      <small>T-Shirt</small>
                    </label>
                    {/* <input
                      onChange={handleEnsemble}
                      onBlur={handleEnsemble}
                      type="text"
                      className="form-control"
                      name="type"
                    /> */}
                    {/* {console.log("select", list.type.split(" ")[3])} */}
                    <select
                      name="type"
                      className="form-control"
                      onChange={handleEnsemble}
                      onBlur={handleEnsemble}
                      value={list.type.split(" ")[3]}
                    >
                      {list.type !== "0" ? <option value="0"></option> : ""}
                      <option value="S">S - Small</option>
                      <option value="M">M - Medium</option>
                      <option value="L">L - Large</option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="col-md-4">
                  {/* Program List */}
                  <ListCourse handleDelete={handleDelete} data={lists} />
                </div>
              )}
              <div className="w-100" style={{ height: "25px" }}></div>
              {ensemble.ensemble ? (
                ""
              ) : (
                <div className="w-100" style={{ textAlign: "center" }}>
                  <button className="btn btn-primary" onClick={handleInstAdd}>
                    Add
                  </button>
                  <button className="btn btn-primary" onClick={handleClear}>
                    Clear
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="w-100" style={{ height: "25px" }}></div>
          <div className="w-100" style={{ height: "25px" }}></div>
          <div className="w-100" style={{ textAlign: "center" }}>
            <Link to="/parent" state={form}>
              <button
                className="btn btn-primary"
                style={{ width: "150px" }}
                type="submit"
              >
                Back
              </button>
            </Link>
            {btnNext || form.course ? (
              <Link to="/term" state={form}>
                <button
                  // onClick={handleSubmit}
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                  type="submit"
                  // onClick={() => handleNext()}
                >
                  Next
                </button>
              </Link>
            ) : (
              <button className="btn btn-secondary" style={{ width: "150px" }}>
                Next
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Course;
