const FormNavButton = ({ handleNavigation }) => {
  return (
    <div
      style={{
        textAlign: "right",
        padding: "15px",
      }}
    >
      <button className="btn btn-primary" onClick={() => handleNavigation(-1)}>
        BACK
      </button>
      <button className="btn btn-primary" onClick={() => handleNavigation(+1)}>
        NEXT
      </button>
    </div>
  );
};

export default FormNavButton;
