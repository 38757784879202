import React from "react";
import { Routes, Route, NavLink, useLocation, Link } from "react-router-dom";
import "../assets/css/regist.css";
import Course from "./course";
import Form2 from "./form2";
import Form1 from "./form1";
import Form3 from "./form3";
import Term from "./term";
import Page404 from "./Page404";

const Regist = () => {
  const location = useLocation();
  return (
    <>
      <div
        className="container bg-light text-dark"
        style={{
          fontSize: "0.8rem",
          borderRadius: "5px",
          padding: "0px",
          position: "relative",
          top: "-125px",
        }}
      >
        {console.log(location.state)}
        <section style={{}}>
          <div className="row col-12" style={{ margin: "0px", padding: "0px" }}>
            <NavLink
              to="/"
              state={location.state}
              className={({ isActive }) =>
                isActive ? "col-md-3 line activelink" : "col-md-3 col-6 line"
              }
            >
              <div className="form-title">
                <h4 className="m-2">DAFTAR SEKARANG </h4>{" "}
                <i className="m-2">Register Now</i>
              </div>
            </NavLink>

            <NavLink
              to="/student"
              state={location.state}
              className={({ isActive }) =>
                isActive ? "col-md-3 line activelink" : "col-md-3 col-6 line"
              }
            >
              <div className="form-title">
                <h4 className="m-1">DETAIL MURID </h4>{" "}
                <i className="m-1">Student Details</i>
              </div>
            </NavLink>

            <NavLink
              to="/parent"
              state={location.state}
              className={({ isActive }) =>
                isActive ? "col-md-3 line activelink" : "col-md-3 col-6 line"
              }
            >
              <div className="form-title">
                <h4 className="m-1">DETAIL PARENT </h4>{" "}
                <i className="m-1">Parent Details</i>
              </div>
            </NavLink>

            <NavLink
              to="/course"
              state={location.state}
              className={({ isActive }) =>
                isActive ? "col-md-3 line activelink" : "col-md-3 col-6 line"
              }
            >
              <div className="form-title">
                <h4 className="m-1">DETAIL COURSE </h4>{" "}
                <i className="m-1">Course Details</i>
              </div>
            </NavLink>
          </div>
        </section>
        <section>
          <div className="col-md-12" style={{ padding: "15px" }}>
            <div className="w-100" style={{ height: "25px" }}></div>
            {/* Form */}
            <Routes>
              <Route exact path="/*" element={<Form1 />} />
              <Route exact path="/student" element={<Form2 />} />
              <Route exact path="/parent" element={<Form3 />} />
              <Route exact path="/course" element={<Course />} />
              <Route exact path="/term" element={<Term />} />
              {/* <Route path={"/*"} element={<Page404 />} /> */}
            </Routes>
          </div>
          {console.log(location)}
          {location.pathname === "/" || location.state === null ? (
            ""
          ) : (
            <Link
              to="/"
              onMouseEnter={(e) =>
                (e.target.className = "w-100 btn btn-danger")
              }
              onMouseLeave={(e) => (e.target.className = "w-100 btn btn-light")}
            >
              <button className="w-100 btn btn-light">Clear Data</button>
            </Link>
          )}
        </section>
      </div>
    </>
  );
};

export default Regist;
