import useWeb from "../custom/useWeb";

const CompUnit = () => {
  const { data } = useWeb("get_company");
  return (
    <div className="__inputgroup">
      <label className="__inputlabel" htmlFor="job">
        select unit <b style={{ color: "#f99" }}>*</b>
      </label>
      <select
        className="form-select"
        name="unit"
        //   onChange={handleChange}
        defaultValue=""
        //   value={
        //     props.dataForm &&
        //     (props.dataForm === null ? "" : props.dataForm.unit)
        //   }
        required={true}
      >
        <option key="0" value="" disabled>
          -
        </option>
        {data &&
          data.message
            .filter(
              (f) =>
                !f.company_name.toLowerCase().includes("bbg") &&
                !f.company_name.toLowerCase().includes("test")
            )
            .map((d) => (
              <>
                <option key={d.company_name} value={d.company_name}>
                  {d.company_name}
                </option>
              </>
            ))}
      </select>
    </div>
  );
};

export default CompUnit;
