import { useState } from "react";
import { PostResource } from "../custom/apiResource";

const StudentAttendanceResponse = ({ data, fullname, name, handleClose }) => {
  const [respon, setRespon] = useState("");
  const handleSendResponse = (e) => {
    e.preventDefault();
    let body = {
      comment_type: "Comment",
      comment_by: fullname,
      reference_doctype: "Student Attendance",
      reference_name: name,
      content: respon,
    };
    PostResource("Comment", body)
      .then((res) => {
        const newData = [...data, res];
        handleClose(newData);
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <div
        className="input-group mb-3"
        style={{ position: "sticky", top: "0px", zIndex: "1" }}
      >
        <input
          type="text"
          name="response"
          onChange={(e) => setRespon(e.target.value)}
          className="form-control"
          placeholder="Type your response here"
          aria-label="Type your response here"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleSendResponse}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-send"
              viewBox="0 0 16 16"
            >
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
            </svg>{" "}
            send
          </button>
        </div>
      </div>
      <div className="w-100">
        {data.map((d) => (
          <div
            key={d.name}
            id="responitem"
            style={{
              border: "solid 1px white",
              position: "relative",
              padding: "1rem",
              borderRadius: "5px",
              lineHeight: "1",
              width: "80%",
            }}
          >
            {d.content}
            <div
              style={{
                position: "absolute",
                right: "5px",
                bottom: "5px",
                color: "grey",
                fontSize: "10px",
              }}
            >
              {d.creation}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default StudentAttendanceResponse;
