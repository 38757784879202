import React from "react";
import propic from "../assets/img/propic.png";
import "../assets/css/staff.css";
import { NavLink } from "react-router-dom";

const DashStaffMenu = () => {
  const roles = sessionStorage.getItem("roles").split(",");
  return (
    <>
      <div className="w-100" style={{ overflowX: "auto" }}>
        <div
          className="__staff_menu_container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <NavLink
            to=""
            className={({ isActive }) =>
              isActive ? "__staff_menu_active" : "__staff_menu"
            }
            style={{
              border: "solid 1px transparent",
              borderBottom: "solid 1px gray",
              padding: "5px 10px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                padding: " 0",
              }}
            >
              <i
                className="bi bi-house-fill"
                style={{ fontSize: "24px", padding: "0" }}
              ></i>
            </div>
          </NavLink>
          <NavLink
            to="profile"
            className={({ isActive }) =>
              isActive ? "__staff_menu_active" : "__staff_menu"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                padding: "3px 0",
              }}
            >
              <i className="bi bi-person"></i>
              PROFILE
            </div>
          </NavLink>
          <NavLink
            to="cv"
            className={({ isActive }) =>
              isActive ? "__staff_menu_active" : "__staff_menu"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                padding: "3px 0",
              }}
            >
              <i className="bi bi-collection"></i>
              RESUME/CV
            </div>
          </NavLink>
          <NavLink
            to="absen"
            className={({ isActive }) =>
              isActive ? "__staff_menu_active" : "__staff_menu"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                padding: "3px 0",
              }}
            >
              <i className="bi bi-calendar-plus"></i>
              ABSENSI
            </div>
          </NavLink>
          <NavLink
            to="cuti"
            className={({ isActive }) =>
              isActive ? "__staff_menu_active" : "__staff_menu"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                padding: "3px 0",
              }}
            >
              <i className="bi bi-calendar-minus"></i>
              CUTI
            </div>
          </NavLink>
          {/* {console.log(emp)} */}
          {roles.includes("Instructor Manager") && (
            <NavLink
              to="report"
              className={({ isActive }) =>
                isActive ? "__staff_menu_active" : "__staff_menu"
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  padding: "3px 0",
                }}
              >
                <i className="bi bi-clipboard-check"></i>
                REPORT
              </div>
            </NavLink>
          )}
          <div
            className="w-100"
            style={{ borderBottom: "solid grey 1px" }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default DashStaffMenu;
