import { useState, useEffect } from "react";
import { apiSite, waSite } from "../config/urlLink";
import { GetToken } from "./apiAuth";
const abortCtr = new AbortController();

const UploadFile = (file, folder = "") => {
  return new Promise((resolve, reject) => {
    try {
      let token = sessionStorage.getItem("token");
      let xhr = new XMLHttpRequest();
      // console.log(xhr);
      xhr.open("POST", `${apiSite}api/method/upload_file`, true);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", `token ${token}`);
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("folder", `Home/${folder}`);
      formData.append("is_private", 0);
      xhr.onload = function () {
        if (xhr.status === 200) {
          let msg = JSON.parse(xhr.responseText);
          resolve(msg.message); // Return success response
        } else {
          reject(new Error(xhr.statusText)); // Reject with error message
        }
      };
      xhr.onerror = function () {
        reject(new Error("Network error")); // Reject with network error message
      };
      xhr.send(formData);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const GetResourceDetail = async (doctype = "", id = "") => {
  const { headers } = GetToken();
  try {
    let res = await fetch(`${apiSite}api/resource/${doctype}/${id}`, {
      signal: abortCtr.signal,
      method: "GET",
      headers: headers,
    });
    res = await res.json();
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const GetResource = (doctype = "", fields = [], filters = []) => {
  const { headers } = GetToken();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      fetch(
        `${apiSite}api/resource/${doctype}?fields=${fields}&filters=[${filters}]&limit_page_length=None`,

        {
          signal: abortCtr.signal,
          method: "GET",
          headers: headers,
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((data) => {
          setData(data);
          setIsLoading(false);
          setError(null);
          // console.log(data);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("fetch data aborted");
          } else {
            setIsLoading(false);
            setError(err.message);
            // console.log(err.message);
          }
        });
    }, 500);
    return () => abortCtr.abort();
    // eslint-disable-next-line
  }, []);

  return { data, isLoading, error };
};
const PostResource = async (doctype = "", body = {}) => {
  const { headers } = GetToken();
  try {
    let res = await fetch(`${apiSite}api/resource/${doctype}`, {
      signal: abortCtr.signal,
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    res = await res.json();
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const UpdateResource = async (doctype = "", doctypeId = "", body = {}) => {
  const { headers } = GetToken();
  try {
    let res = await fetch(`${apiSite}api/resource/${doctype}/${doctypeId}`, {
      signal: abortCtr.signal,
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    });
    res = await res.json();
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const SendWa = async (number = "", body = {}) => {
  const { headers } = GetToken();

  try {
    let wabody = {
      receiver: number,
      message: JSON.stringify(body),
      session: "628112971117",
    };
    let wasend = await fetch("https://wa.sekolahmusik.co.id/api/send", {
      signal: abortCtr.signal,
      method: "POST",
      body: JSON.stringify(wabody),
      headers: headers,
    });

    return wasend;
  } catch (err) {
    return err;
  }
};
const SendWaTemplate = async (number = "", body = {}, template = "") => {
  const { headers } = GetToken();

  try {
    let wasend = await fetch(waSite, {
      signal: abortCtr.signal,
      method: "POST",
      body: JSON.stringify({
        receiver: number,
        message: body,
        session: "628112971117",
        template: template,
      }),
      headers: headers,
    });

    return wasend;
  } catch (err) {
    return err;
  }
};
export {
  UploadFile,
  GetResourceDetail,
  GetResource,
  PostResource,
  UpdateResource,
  SendWa,
  SendWaTemplate,
};
