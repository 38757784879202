import React from "react";
import useWeb from "../custom/useWeb";

const Unit = (props) => {
  const { data } = useWeb("get_company");
  const handleChange = (e) => {
    props.hdlUnit(e.target.value);
  };

  return (
    <>
      {/* {console.log(props.dataForm.unit)} */}
      <div style={{ padding: "25px 25px" }}>
        <p style={{ textAlign: "center", width: "100%", fontSize: "16px" }}>
          <span> Silahkan Pilih Unit SMI terdekat</span>{" "}
          <span className="text-danger">*</span>
        </p>
        <select
          className="form-select form-select-lg mb-3"
          name="unit"
          onChange={handleChange}
          defaultValue=""
          value={
            props.dataForm &&
            (props.dataForm === null ? "" : props.dataForm.unit)
          }
          required={true}
        >
          <option key="0" value="" disabled>
            -
          </option>
          {data &&
            data.message.map((d) => (
              <>
                <option key={d.company_name} value={d.company_name}>
                  {d.company_name}
                </option>
              </>
            ))}
        </select>
      </div>
    </>
  );
};

export default Unit;
