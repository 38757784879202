import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useWindow from "../custom/useWindow";

const AreYouReady = () => {
  const { width } = useWindow();
  useEffect(() => {
    document.title = `Are You Ready ..?`;
  }, []);
  return (
    <div>
      <div
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: width > 450 ? "100px" : "60px",
          color: "#fff",
          padding: width > 450 ? "3vw 0" : "10vh 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          lineHeight: "1.2",
          textAlign: "left",
        }}
      >
        <span>Are you READY</span>
        <br />
        <span>for the FUTURE?</span>
      </div>

      <Link
        to="/f/page2"
        className="btn btn-light"
        style={{
          fontSize: "32px",
          color: "#e52d27",
          borderRadius: "50px",
          padding: "15px 50px",
        }}
      >
        Get Started
      </Link>
    </div>
  );
};

export default AreYouReady;
