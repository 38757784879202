import { useState, useEffect } from "react";
import { apiSite } from "../config/urlLink";

const useWeb = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCtr = new AbortController();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      Authorization: "token f6b262554df61cb:ca71946b2def9fa",
    };
    // console.log(headers);
    setTimeout(() => {
      fetch(`${apiSite}api/method/smi.api.${url}`, {
        signal: abortCtr.signal,
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((data) => {
          setData(data);
          setIsLoading(false);
          setError(null);
          // console.log(data);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("fetch data aborted");
          } else {
            setIsLoading(false);
            setError(err.message);
            // console.log(err.message);
          }
        });
    }, 0);
    return () => abortCtr.abort();
  }, [url]);

  return { data, isLoading, error };
};

export default useWeb;
