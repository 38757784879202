import React, { useState } from "react";
import formFilling from "../assets/img/form-filling.jpg";
import useWindow from "../custom/useWindow";
import FormStudType from "./formStudType";
import "../assets/css/rhome.css";
import FormNavButton from "./formNavButton";
import FormGuardian from "./formGuardian";
import FormUnit from "./formUnit";

const RHome = () => {
  const { width } = useWindow();
  const [progForm, setProgForm] = useState(1);
  const [progress, setProgress] = useState({
    1: {
      description: "type",
      status: 2,
      sub: "",
    },
    2: {
      description: "parent",
      status: 1,
      sub: [
        { 1: { description: "", status: 1 } },
        { 2: { description: "", status: 0 } },
      ],
    },
    3: {
      description: "student",
      status: 0,
      sub: [
        { 1: { description: "", status: 0 } },
        { 2: { description: "", status: 0 } },
        { 3: { description: "", status: 0 } },
      ],
    },
    4: {
      description: "test",
      status: 0,
      sub: "",
    },
  });
  const [form, setForm] = useState({ type: "1", guardian: "" });
  const [error, setError] = useState({});
  const handleData = (e) => {
    if (e.guardian) {
      console.log(e.guardian);
      setForm({ ...form, guardian: [e.guardian] });
    } else {
      let nam = e.target.name;
      let val = e.target.value;
      setForm({ ...form, [nam]: val });
    }
  };
  const handleNavigation = (e) => {
    console.log(progForm + e);
    if (progForm + e === 0) return;
    setProgForm(progForm + e);
  };

  const mappedSubIcon = (sub) => {
    if (!sub) return null;
    return (
      <>
        <div>
          {sub.map((key) => (
            <div
              key={crypto.randomUUID()}
              className={`__progressicon ${
                { 0: "__greycolor", 1: "__coralcolor", 2: "__greencolor" }[
                  key[Object.keys(key)].status
                ]
              }`}
              // onMouseOver={(e) => console.log(e)}
            >
              {Object.keys(key)[0]}
              <div className="__description">
                {key[Object.keys(key)].description}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  const mappedIcon = Object.keys(progress).map((key) => {
    return (
      <div key={crypto.randomUUID()}>
        <div
          className={`__progressicon ${
            { 0: "__greycolor", 1: "__coralcolor", 2: "__greencolor" }[
              progress[key].status
            ]
          }`}
          style={{ width: "45px", height: "45px" }}
          // onMouseOver={(e) => console.log(e)}
        >
          {String.fromCharCode(64 + parseInt(key))}
          <div className="__description">{progress[key].description}</div>
        </div>
        {mappedSubIcon(progress[key].sub)}
      </div>
    );
  });

  return (
    <>
      <div
        className="w-100"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background:
            width > 450
              ? "#e9e9e9"
              : `url(${formFilling}) center center / cover`,
          position: "relative",
        }}
      >
        <div className="w-100"></div>
        <div
          className="w-100"
          style={{
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "900",
            color: "coral",
            padding: "25px",
            background: width > 450 ? "" : "rgba(255,255,255,0.7)",
          }}
        >
          REGISTER NOW
          {console.log("form", form)}
        </div>
        <div
          className="col-md-8 col-11"
          style={{
            padding: "0",
            margin: "0",
            background: "white",
            height: width > 450 ? "60vh" : "70vh",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            border: width > 450 ? "" : "solid 2px #e9e9e9",
          }}
        >
          <div
            className="col-md-7"
            style={{
              padding: "0",
              margin: "0",
              background:
                width > 450 ? `url(${formFilling}) center center / cover` : "",
              borderRadius: "5px 0 0 5px",
              opacity: "1",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="col-md-2"
              style={{
                padding: "5px",
                margin: "0",
                borderRadius: width > 450 ? "" : "5px 0 0 5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                borderRight: "solid 2px #e9e9e9",
                background: "rgba(255,255,255,0.7)",
                width: "65px",
                overflowY: "auto",
              }}
            ></div>
          </div>
          <div
            className="col-md-5"
            style={{
              padding: "0",
              margin: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {
              {
                1: <FormUnit />,
                2: <FormStudType data={form} handleData={handleData} />,
                3: <FormGuardian data={form} handleData={handleData} />,
              }[progForm]
            }
            <FormNavButton handleNavigation={handleNavigation} />
          </div>
        </div>
        {JSON.stringify(form)}
      </div>
    </>
  );
};

export default RHome;
