const useDate = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day7 = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    DD7 = ("0" + day7.getDate()).slice(-2),
    MM7 = ("0" + (day7.getMonth() + 1)).slice(-2),
    YY7 = day7.getFullYear();
  const today = new Date(),
    DD = ("0" + today.getDate()).slice(-2),
    MM = ("0" + (today.getMonth() + 1)).slice(-2),
    MMM = monthShort[today.getMonth()],
    MMMM = monthNames[today.getMonth()],
    YY = today.getFullYear(),
    HH = today.getHours(),
    mm = today.getMinutes(),
    ss = today.getSeconds(),
    now = YY + "-" + MM + "-" + DD, // + " " + HH + ":" + mm + ":" + ss,
    CalculateAge = (birthdate) => {
      // Parse the input birthdate
      const birthDate = new Date(birthdate);
      const today = new Date();

      let years = today.getFullYear() - birthDate.getFullYear();
      let months = today.getMonth() - birthDate.getMonth();

      // Adjust if the current month is before the birth month
      if (months < 0) {
        years--;
        months += 12;
      }

      // Adjust if the current day is before the birth day in the birth month
      if (today.getDate() < birthDate.getDate()) {
        months--;
        if (months < 0) {
          years--;
          months += 12;
        }
      }

      return `${years}y ${months}m`;
    },
    IsBirthday = (birthdate) => {
      // Parse the input birthdate
      const birthDate = new Date(birthdate);
      const today = new Date();

      // Check if the month and day are the same
      return (
        today.getMonth() === birthDate.getMonth() &&
        today.getDate() === birthDate.getDate()
      );
    };

  return {
    monthShort,
    monthNames,
    now,
    DD7,
    MM7,
    YY7,
    DD,
    MM,
    MMM,
    MMMM,
    YY,
    HH,
    mm,
    ss,
    CalculateAge,
    IsBirthday,
  };
};

export default useDate;
