import React, { useCallback, useMemo, useState } from "react";
import usePost from "../custom/usePost";
import "../assets/css/dashRep.css";
import DashStaffReportDetail from "./dashStaffReportDetail";
import useWindow from "../custom/useWindow";
import { DatePicker, Input, Space, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import DashStaffReportDet from "./dashStaffReportDet";

const DashStaffReport = () => {
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [search, setSearch] = useState({ src: "", status: true });
  const [data, setData] = useState({
    manager_email: sessionStorage.getItem("user_id"),
    company: JSON.parse(sessionStorage.getItem("emp_id")).company,
    start_date: "2024-01-01",
    end_date: "2024-09-30"
  })
  // const { data: teacher } = usePost("get_teacher_list", data);
  const { data: att, isLoading } = usePost(
    "get_teacher_student_attendance_list",
    data
  );
  const handleDate = useCallback((e) => {
    setData({
      ...data, start_date: e[0], end_date: e[1]
    })
  }, [])
  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    console.log([e.target.name], e.target.value);
  };

  const teaFil = useMemo(() => {

    if (!att || !att.data) return [];

    const searchRegex = search.src && new RegExp(`${search.src}`, "gi");

    const dataEntries = Object.entries(att.data).map(([name, value]) => ({
      name, ...value
    }));

    return (
      dataEntries
        .filter(
          (d) =>
            (!searchRegex || searchRegex.test(d.instructor_name)) &&
            d.status === (search.status ? "Active" : "Left")
        )
    );
  }, [att, search.src, search.status]);

  return (
    <>
      <div className="w-100" style={{ height: "85vh", overflow: "hidden" }}>
        {/* {console.log(att)} */}
        <div className="row col-md-12" style={{ padding: "0 15px", margin: "0" }}>
          <Space.Compact style={{ fontSize: '12px' }}>
            <DatePicker.RangePicker
              id={{ start: data.start_date, end: data.end_date }}
              defaultValue={[dayjs(data.start_date), dayjs(data.end_date)]}
              onChange={(date, fdate) => handleDate(fdate)} className="w-100" />
            <Tooltip trigger={['focus']} title="type to search"
              placement="topLeft" overlayClassName="numeric-input">
              <Input name="src" placeholder="Instructor" prefix={(<i className="bi bi-search"></i>)}
                onChange={(_) => handleSearch(_)} className="w-100" />
            </Tooltip>

            <Tooltip trigger={['focus']} title="student search"
              placement="topLeft" overlayClassName="numeric-input">
              <Input name="student" placeholder="Student" prefix={(<i className="bi bi-person"></i>)}
                onChange={(_) => handleSearch(_)} className="w-100" />
            </Tooltip>
            {/* <Tooltip trigger={['focus']} title="student search"
              placement="topLeft" overlayClassName="numeric-input">
              <Input name="attendance" placeholder="Attendance" prefix={(<i className="bi bi-file-person"></i>)}
                onChange={(_) => handleSearch(_)} className="w-100" />
            </Tooltip> */}
          </Space.Compact>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 0 10px 0",
            }}
          >

            <div
              className="w-100"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                className="form-control"
                style={{
                  padding: "3px 10px",
                  fontSize: "12px",
                }}
                type="text"
                placeholder="Search instructor here"
                name="src"
                onChange={handleSearch}
              />
              <input
                className="form-control"
                style={{
                  padding: "3px 10px",
                  fontSize: "12px",
                }}
                type="text"
                placeholder="Search student here"
                name="student"
                value={search.student ? search.student : ""}
                onChange={handleSearch}
              />
              <input
                className="form-control"
                style={{
                  padding: "3px 10px",
                  fontSize: "12px",
                }}
                type="text"
                placeholder="Search Attendance here"
                name="src"
                value={search.src ? search.src : ""}
                onChange={handleSearch}
              />
            </div>
            <div
              className="w-100"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                className="form-control"
                style={{
                  padding: "3px 10px",
                  fontSize: "12px",
                }}
                type="date"
                name="start"
                value={search.start}
                onChange={(e) => setSearch({ ...search, start: e.target.value })}
              />
              <input
                className="form-control"
                style={{
                  padding: "3px 10px",
                  fontSize: "12px",
                }}
                type="date"
                name="end"
                value={search.end}
                onChange={(e) => setSearch({ ...search, end: e.target.value })}
              />
              <button
                className="btn btn-warning"
                style={{
                  padding: "3px 10px",
                  fontSize: "12px",
                }}
                onClick={() => setSearch({ status: true, start: "", end: "" })}
              >
                CLEAR
              </button>
            </div>
            <div
            style={{
              display: "flex",
              margin: "0",
              padding: "0",
              borderRadius: "5px",
              background: "#e9e9e9",
              width: "fit-content",
            }}
          >
            <div
              className="form-check form-switch"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "0 10px",
                }}
              >
                <label
                  className="form-check-label"
                  htmlFor="statusSwitch"
                  style={{
                    display: "flex",
                    margin: "0",
                    padding: "0",
                    color: "grey",
                    fontSize: "10px",
                    fontWeight: "700",
                  }}
                >
                  {search.status ? "ATTENDED" : "ABSENT"}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  paddingRight: "10px",
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="statusSwitch"
                  name="status"
                  defaultChecked={search.status}
                  onClick={() =>
                    setSearch({ ...search, status: !search.status })
                  }
                  style={{
                    margin: "0",
                    padding: "0",
                    backgroundColor: search.status ? "#0d6efd" : "crimson",
                    borderColor: search.status ? "#0d6efd" : "crimson",
                  }}
                />
              </div>
            </div>
          </div>
          </div> */}
        </div>
        <div
          className="row col-md-12"
          style={{ padding: "10px 15px 0 15px", margin: "0" }}
        >
          <div
            className="col-md-3"
            style={{
              padding: "0",
              margin: "0",
              overflow: 'hidden auto'
            }}
          >
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
              <p
                style={{
                  margin: "0",
                  padding: "0",
                }}
              >
                Instructor
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  lineHeight: "1",
                  padding: "0 0 10px 0",
                }}
              >

                <div
                  style={{
                    margin: "0",
                    padding: "0",
                    borderRadius: "5px",
                    background: "#e9e9e9",
                    width: "fit-content",
                  }}
                >
                  <div
                    className="form-check form-switch"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        padding: "0 10px",
                      }}
                    >
                      <label
                        className="form-check-label"
                        htmlFor="statusSwitch"
                        style={{
                          display: "flex",
                          margin: "0",
                          padding: "0",
                          color: "grey",
                          fontSize: "10px",
                          fontWeight: "700",
                        }}
                      >
                        {search.status ? "ACTIVE" : "LEFT"}
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingRight: "10px",
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="statusSwitch"
                        name="status"
                        defaultChecked={search.status}
                        onClick={() =>
                          setSearch({ ...search, status: !search.status })
                        }
                        style={{
                          margin: "0",
                          padding: "0",
                          backgroundColor: search.status ? "" : "crimson",
                          borderColor: search.status ? "" : "crimson",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100" style={{}}>

              {/* Teacher List */}
              <div
                className="w-100"
                style={{
                  height: "72vh",
                  overflow: "auto",
                  padding: "0 10px 0 0",
                }}
              >
                {/* {console.log(attFil)} */}
                {isLoading ? <Spin fullscreen /> :
                  teaFil
                    .sort((a, b) =>
                      a.instructor_name < b.instructor_name
                        ? -1
                        : a.instructor_name > b.instructor_name
                          ? 1
                          : 0
                    )
                    .map((d) => (
                      <div key={d.name} className="w-100">
                        <div
                          className={
                            selectedTeacher === d.name
                              ? "__teacherlistitem __teacherlistitemactive"
                              : "__teacherlistitem "
                          }
                          onClick={() => setSelectedTeacher(d.name)}
                        >
                          <div className="__itemarrow">
                            <i className="bi bi-arrow-right-square"></i>
                          </div>
                          {isLoading ? (
                            <div className="blink-1">
                              <div className="__itembadgecontainer">
                                <div
                                  className="__itembadge"
                                  style={{ background: "#e9e9e9" }}
                                >
                                  <i className="bi bi-person-fill"></i>
                                </div>
                                <div
                                  className="__itembadge"
                                  style={{ background: "#e9e9e9" }}
                                >
                                  <i className="bi bi-file-person-fill"></i>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="__itembadgecontainer">
                              {/* hitung jumlah murid */}
                              <div
                                className="__itembadge"
                                style={{ background: "orange" }}
                              >
                                {/* {att && att[d.name] !== undefined
                                  ? Object.keys(att[d.name]).length
                                  : [].length} */}
                                {d.att.length}
                                <i className="bi bi-person-fill"></i>
                              </div>
                              <div
                                className="__itembadge"
                                style={{ background: "royalblue" }}
                              >
                                {/* Hitung jumlah attendance yang pernah dibuat */}
                                {/* {att && att[d.name] !== undefined
                                  ? Object.values(att[d.name]).reduce(
                                    (acc, arr) => acc + arr.length,
                                    0
                                  )
                                  : [].length} */}
                                {d.total}
                                <i className="bi bi-file-person-fill"></i>
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              fontSize: "8px",
                              fontWeight: "700",
                              color: "white",
                              background: {
                                Active: "limegreen",
                                Left: "crimson",
                              }[d.status],
                              padding: "5px",
                              width: "60px",
                              textAlign: "center",
                              borderRadius: "0 5px 0 7px",
                            }}
                          >
                            {d.status.toUpperCase()}
                          </div>
                          <i style={{ color: "grey", fontSize: "10px" }}>
                            {d.name}
                          </i>
                          <br /> {d.instructor_name}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div
            className="col-md-9"
            style={{
              padding: "0",
              margin: "0",
              overflow: 'hidden auto'
            }}
          >
            <div className="w-100" style={{ height: "72vh" }}>
              {/* tampilkan attendance perteacher */}
              {teaFil && (selectedTeacher === "" ? "" : <DashStaffReportDet data={teaFil.filter(f => f.name === selectedTeacher)} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashStaffReport;
