import { GetResource } from "../custom/apiResource";
import useWindow from "../custom/useWindow";

const DashJadwal = () => {
  const { width } = useWindow();
  const { data, isLoading, error } = GetResource(
    "Course Schedule",
    `["*"]`,
    `["instructor","=","${sessionStorage.getItem("instructor_id")}"]`
  );
  return (
    <>
      {console.log(data)}
      <div className="w-100 " style={{ overflow: "hidden" }}>
        <div className="__top_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="col-md-6 col-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: width > 450 ? "20px" : "15px 20px",
              }}
            >
              <div
                className="page-title"
                style={{
                  color: "#212529",
                  lineHeight: "1",
                  padding: "0",
                }}
              >
                DASHBOARD
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <div className="__jadwalday">
            <div className="jadwaldayitem"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashJadwal;
