import { Avatar, Badge, Card, Col, Flex, Grid, Space } from 'antd'
import React from 'react'

const DashStaffReportDet = ({ data }) => {

    return (
        <div
            style={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)', gap: '5px', padding: '0 0 0 10px' }}>
            {data[0]?.att.map(d => (
                <div style={{ padding: '2px' }}>
                    <div className="card">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '0 5px',
                                height: '25px',
                                overflow: 'hidden',
                                fontSize: '12px',
                                borderBottom: 'solid 1px #ccc'
                            }}>
                            <small>{d.student}</small>
                            <Space >


                                <span style={{ fontSize: '12px' }}>{d.status}</span>

                            </Space>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start', padding: '5px', fontSize: '12px' }}>
                            <Badge size='small' count={d.total} showZero color={d.total > 0 ? 'forestgreen' : '#ccc'} >
                                <Avatar shape="square" icon={<i className='bi bi-person' />} src={"https://sister.sekolahmusik.co.id" + d.image} />
                            </Badge>
                            <p style={{ marginLeft: '10px' }}>{d.student_name}</p>
                        </div>
                    </div>
                </div>
            ))}</div>
    )
}

export default DashStaffReportDet