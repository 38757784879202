import React, { useCallback, useMemo, useState } from "react";
import usePost from "../custom/usePost";
import { apiSite } from "../config/urlLink";
import AddAttendance from "./addAttendance";
import useDate from "../custom/useDate";
import Modal from "../website/modal";
import { ShortDate } from "../custom/dateFormatter";
import useWindow from "../custom/useWindow";
import { GetToken } from "../custom/apiAuth";
import NoData from "./nodata";
import { DatePicker, Flex, Input, Space, Spin, Switch, Tooltip } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker
const { Search } = Input

const GetCourse = (props) => {
  const { width } = useWindow();
  const { monthShort } = useDate();
  const { headers } = GetToken();
  const [src, setSrc] = useState({
    att_check: false,
    src: "",
    sort: true,
    today: true,
    loading: false
  });
  const [data, setData] = useState({ vis: false });
  const [vis, setVis] = useState({ modal: false });
  const [attendanceDetail, setAttendanceDetail] = useState("");
  const [studentGroup, setStudentGroup] = useState("");
  const usidStore = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");
  let today = new Date(),
    lastMonth = new Date(new Date().setDate(new Date().getDate() - 60)),
    date =
      lastMonth.getFullYear() +
      "-" +
      (lastMonth.getMonth() + 1) +
      "-" +
      lastMonth.getDate(),
    nextMonth = new Date(new Date().setDate(new Date().getDate() + 30)),
    date7 =
      nextMonth.getFullYear() +
      "-" +
      (nextMonth.getMonth() + 1) +
      "-" +
      nextMonth.getDate();
  const [usid, setUsid] = useState({
    instructor: usidStore,
    from_date: today,
    to_date: today,
  })
  // const usid = {
  //   instructor: usidStore,
  //   from_date: date,
  //   to_date: date7,
  // };
  // console.log(usid);
  const { data: course, isLoading, error } = usePost("get_course_schedule", usid);
  // const { data: instruct } = useResource(
  //   "Instructor",
  //   '["name","`tabStudent Group Student`.student","`tabStudent Group Student`.student_name","`tabStudent Group Instructor`.instructor","`tabStudent Group Instructor`.instructor_name"]',
  //   `["Student Group Instructor","instructor","=","EDU-INS-2022-S-PA-002"]`
  // );
  // const { data: studentGroupList } = useResource(
  //   "Student Group",
  //   '["name","`tabStudent Group Student`.student","`tabStudent Group Student`.student_name","`tabStudent Group Instructor`.instructor","`tabStudent Group Instructor`.instructor_name"]',
  //   `["Student Group Instructor","instructor","=","EDU-INS-2022-S-PA-002"]`
  // );

  const handleAttendance = async (cs, i) => {
    // console.log(cs, i);
    setStudentGroup("");
    const abortCtr = new AbortController();

    try {
      let res = await fetch(
        `${apiSite}api/method/smi.api.get_student_group_student`,
        {
          signal: abortCtr.signal,
          method: "POST",
          headers: headers,
          body: `{"stud_group":"${cs.student_group
            .replace(/[\\"']/g, "\\$&")
            .replace(/\u0000/g, "\\0")}"}`,
        }
      );
      res = await res.json();

      setData({
        ...data,
        i: i,
        cs,
        detail: res.message,
      });
      setStudentGroup({
        sg: res.message,
        key: `${cs.student_group}${cs.name}`,
      });
      setVis({
        ...vis,
        i: i,
        modal: true,
        title: "Add Attendance",
        val: 1,
      });
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStudent = async (id) => {
    console.log(id);
    setAttendanceDetail("");
    const abortCtr = new AbortController();
    try {
      let res = await fetch(
        `${apiSite}api/resource/Student Attendance?fields=["*"]&filters=[["course_schedule","=","${id}"]]`,
        {
          signal: abortCtr.signal,
          method: "GET",
          credentials: "include",
          headers: headers,
        }
      );
      res = await res.json();
      setAttendanceDetail(res.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStudentGroup = async (name, sg) => {
    // console.log("click", sg, name);
    setStudentGroup("");
    const abortCtr = new AbortController();

    try {
      let res = await fetch(
        `${apiSite}api/method/smi.api.get_student_group_student`,
        {
          signal: abortCtr.signal,
          method: "POST",
          headers: headers,
          body: `{"stud_group":"${sg
            .replace(/[\\"']/g, "\\$&")
            .replace(/\u0000/g, "\\0")}"}`,
        }
      );
      res = await res.json();
      setStudentGroup({ sg: res.message, key: `${sg}${name}` });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDate = useCallback((e) => {
    setUsid({
      ...usid, from_date: e[0], to_date: e[1]
    })
    setSrc({
      att_check: false,
      src: "",
      sort: true,
      today: true,
      loading: false
    })
  }, [])
  const handleSearch = (e) => {
    console.log(e)
    setSrc({ ...src, [e.target.name]: e.target.value });
    // console.log([e.target.name], e.target.value);
  };
  const handleClose = (e) => {
    setVis({ ...vis, val: 0, modal: false });
    setStudentGroup("");
  };
  const dataCourse = useMemo(() => {
    if (!course || !course.message) return [];

    // Pre-filter the data based on status
    const filteredData = course.message.filter(f =>
      ["Replacement", "Scheduled", "Extension"].some(status =>
        f.status.includes(status)
      )
    );
    // console.log(filteredData)
    // Sort the data
    const sortedData = filteredData.sort((a, b) => {
      if (src.sort === true) {
        return new Date(b.schedule_date) - new Date(a.schedule_date);
      } else {
        return new Date(a.schedule_date) - new Date(b.schedule_date);
      }
    });

    // console.log(sortedData)
    if (src.src === "") {
      return sortedData;
    }
    // Apply additional filters
    return sortedData.filter(f =>
      (f.student_group + f.name + f.room)
        .toLowerCase()
        .includes(src.src) &&
      (src.att_check || f.attendance_status === null || f.attendance_status === "Not Filled")
    );
  }, [course, src.att_check, src.src, src.sort]);
  return (
    <>
      <Modal
        modal={vis.modal}
        title={vis.title}
        element={
          <div
            className="w-100 justify-content-around"
            style={{
              textAlign: "justify",
              height: "auto",
            }}
          >
            {
              {
                0: "",
                1: (
                  <AddAttendance
                    data={data}
                    sg={studentGroup}
                    handleAttendance={handleAttendance}
                    handleClose={handleClose}
                  />
                ),
              }[vis.val]
            }
          </div>
        }
        handleClose={() => setVis({ modal: false })}
      />
      {/* {console.log(src)} */}
      <div
        className="row col-md-12"
        style={{ padding: "0", margin: "0", height: "80vh" }}
      >
        <div
          className="col-md-6"
          style={{
            padding: "0 15px 0 15px",
            margin: "0px",
            height: "100%",
          }}
        >
          <div className="w-100">

            {/* <br />
                <i>
                  <small>
                    <b>
                      {ShortDate(usid.from_date)} - {ShortDate(usid.to_date)}
                    </b>
                  </small>
                </i> */}
            {/* <strong>{`${MMM.toUpperCase()}, ${YY}`}</strong> */}
            <Flex
              gap="small" align="start" vertical>
              <Flex className="w-100" justify="space-between" align={"center"}>
                <p>Schedule Period</p>
                <Flex>
                  <Tooltip trigger={['focus']} title="is Attended?" placement="topLeft" overlayClassName="numeric-input">
                    <Switch checkedChildren="att" unCheckedChildren="att" defaultChecked={src.att_check}
                      onClick={() =>
                        setSrc({ ...src, att_check: !src.att_check })
                      } /></Tooltip>

                  <Tooltip trigger={['focus']} title="Sort Data" placement="topLeft" overlayClassName="numeric-input">
                    <Switch checkedChildren="asc" unCheckedChildren="dsc" defaultChecked={src.sort}
                      onClick={() => setSrc({ ...src, sort: !src.sort })} /></Tooltip>
                </Flex>

              </Flex>
              <Space.Compact >
                <RangePicker style={{ width: '50%' }}
                  id={{ start: usid.from_date, end: usid.to_date }}
                  defaultValue={[dayjs(usid.from_date), dayjs(usid.to_date)]}
                  // defaultValue={[usid.from_date, usid.to_date]}
                  onChange={(date, fdate) => handleDate(fdate)} disabled={src.loading} loading={src.loading} />


                <Tooltip trigger={['focus']} title="type to search"
                  placement="topLeft" overlayClassName="numeric-input">
                  <Search style={{ width: '50%' }} name="src"
                    onChange={(_) => handleSearch(_)} disabled={src.loading} loading={src.loading} />
                </Tooltip>
              </Space.Compact>


            </Flex>

            {/* <div
    className="form-check form-switch"
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 15px",
    }}
  >
    <input
      className="form-check-input"
      type="checkbox"
      role="switch"
      id="attSwitch"
      name="att_check"
      defaultChecked={src.att_check}
      onClick={() =>
        setSrc({ ...src, att_check: !src.att_check })
      }
    />
    <label
      className="form-check-label"
      htmlFor="attSwitch"
      style={{ paddingLeft: "10px" }}
    >
      {width > 450 ? "Attended" : "Att"}
    </label>
  </div>

  <div
    className="col-md-auto"
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <input
      className="form-control"
      style={{
        padding: "5px 15px",
      }}
      type="text"
      placeholder="type to search"
      name="src"
      onChange={handleSearch}
    />
    <button
      className="btn btn-primary"
      onClick={() => setSrc({ ...src, sort: !src.sort })}
      style={{ display: "block" }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <i
          className={`bi bi-sort-${src.sort ? "up" : "down"}`}
        ></i>{" "}
        <span
          style={{
            marginLeft: "5px",
          }}
        >
          {width > 450 ? "Date" : ""}
        </span>
      </div>
    </button>
  </div> */}
          </div>

          <div
            className="w-100"
            style={{
              padding: width > 450 ? "0 15px" : "0",
              margin: "0px",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <div className="w-100" style={{ height: "25px" }}></div>
            {/* 
            {today
              ? dataCourseToday && dataCourseToday.length > 0
                ? ""
                : ""
              : ""}
            {{ 0: "", 1: "", 2: "" }[today]} */}
            {isLoading ? <Spin fullscreen /> : (dataCourse && dataCourse.length > 0 ? (
              dataCourse.map((cs, i) => (
                <div
                  className="col-md-12 __schedule_container"
                  key={cs.name}
                  style={
                    studentGroup.key === `${cs.student_group}${cs.name}`
                      ? { opacity: "1" }
                      : {}
                  }
                  loading="lazy"
                >
                  {/* {console.log(cs)} */}
                  <div
                    className="__schedule_container"
                    style={
                      studentGroup.key === `${cs.student_group}${cs.name}`
                        ? { opacity: "1" }
                        : {}
                    }
                  >
                    <div className="__course_date">
                      <div className="__date_month">
                        {
                          monthShort[
                          parseInt(cs.schedule_date.substring(5, 7), 10) - 1
                          ]
                        }
                      </div>
                      <div className="__date_day">
                        {parseInt(cs.schedule_date.substring(8, 10), 10)}
                      </div>
                    </div>
                    <div
                      className="card __course_content"
                      // style={{ backgroundColor: "green", opacity: "0.7" }}
                      style={{ backgroundColor: cs.color }}
                    >
                      <span className="__course_status">
                        {cs.attendance_status
                          ? cs.attendance_status.toUpperCase()
                          : cs.status.toUpperCase()}
                      </span>
                      <p>
                        <span className="__cs_hour">
                          {cs.from_time.split(":")[0]}
                        </span>
                        .{cs.from_time.split(":")[1]}-
                        <span className="__cs_hour">
                          {cs.to_time.split(":")[0]}
                        </span>
                        .{cs.to_time.split(":")[1]}
                        <br />
                        {cs.student_group} | {cs.course.split(" - ")[1]}
                        <br />
                        {cs.name}
                        <br />
                        {cs.room.split(" - ")[0] +
                          "-" +
                          cs.room.split(" - ")[1]}{" "}
                        | {cs.company.split(" - ")[1]}
                      </p>
                    </div>
                    <div className="__student_details_button">
                      <div className="btn-group">
                        {studentGroup.key ===
                          `${cs.student_group}${cs.name}` ? (
                          <>
                            <button
                              id={`btn-${cs.student_group}${cs.name}`}
                              className="btn btn-light"
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                              }}
                              onClick={() => setStudentGroup("")}
                            >
                              <i className="bi bi-person-bounding-box"></i>

                              {/* <i className="bi bi-person-square"></i> */}
                            </button>
                            {cs.attendance_status === null ||
                              cs.attendance_status === "Not Filled" ? (
                              <button
                                className="btn btn-secondary"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "center",
                                }}
                                onClick={() => handleAttendance(cs, i)}
                              >
                                <i className="bi bi-plus-square"></i>
                              </button>
                            ) : (
                              <button
                                className="btn btn-secondary"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "center",
                                  color: "grey",
                                  background: "lightgrey",
                                }}
                                onClick={() => alert("Cannot attend twice.")}
                              >
                                <i className="bi bi-plus-square-fill"></i>
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            <button
                              id={`btn-${cs.student_group}${cs.name}`}
                              className="btn btn-light"
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                              }}
                              onClick={() =>
                                handleStudentGroup(cs.name, cs.student_group)
                              }
                            >
                              <i className="bi bi-person-square"></i>
                            </button>
                            {cs.attendance_status === null ||
                              cs.attendance_status === "Not Filled" ? (
                              <button
                                className="btn btn-secondary"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "center",
                                }}
                                onClick={() => handleAttendance(cs, i)}
                              >
                                <i className="bi bi-plus-square"></i>
                              </button>
                            ) : (
                              <button
                                className="btn btn-secondary"
                                style={{
                                  fontSize: "18px",
                                  textAlign: "center",
                                  color: "grey",
                                  background: "lightgrey",
                                }}
                                onClick={() => alert("Cannot attend twice.")}
                              >
                                <i className="bi bi-plus-square-fill"></i>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {studentGroup.key && studentGroup.key ? (
                    <div
                      id={`${cs.student_group}${cs.name}`}
                      className="__course_content_sg"
                      hidden={
                        studentGroup.key !== `${cs.student_group}${cs.name}`
                      }
                    >
                      {/* {JSON.stringify(studentGroup)} */}
                      <div className="__course_content_sg_inset">
                        {studentGroup.sg &&
                          studentGroup.sg.map((sg, i) => (
                            <div
                              key={i}
                              onClick={() => handleStudent(cs.name)}
                              style={{ display: "block" }}
                            >
                              <i
                                className="bi bi-dash"
                                style={{ color: "gray" }}
                              ></i>
                              <span>
                                {sg.student} - {sg.student_name} | {sg.status} -{" "}
                                {sg.payment === "Late" ? (
                                  <>
                                    <b style={{ color: "red" }}>{sg.payment}</b>{" "}
                                    <i
                                      className="bi bi-cash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </>
                                ) : (
                                  <>
                                    <b style={{ color: "green" }}>
                                      {sg.payment}
                                    </b>{" "}
                                    <i
                                      className="bi bi-cash"
                                      style={{ color: "green" }}
                                    ></i>
                                  </>
                                )}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="w-100" style={{ height: "25px" }}></div>
                </div>
              ))
            ) : (
              // If there is no schedule
              <>
                {console.log(dataCourse)}
                <NoData /></>
            ))}
            <div className="w-100" style={{ height: "50px" }}></div>
          </div>
        </div>
        {/* <div
          className="row col-md-6"
          style={{ height: "100%", overflowY: "auto" }}
        >
          {isLoading ? "Loading" : (dataCourse && dataCourse.length > 0 ? (
            dataCourse.map((cs, i) => (
              <>

              </>
            ))
          ) : (
            // If there is no schedule
            <>
              <NoData />
            </>
          ))}
        </div> */}
      </div >
    </>
  );
};

export default GetCourse;
