import React, { Component } from "react";
import "../assets/css/login.css";
import "../assets/css/modal.css";
import { apiSite } from "../config/urlLink";
import Modal from "./modal";
import loading from "../assets/img/loading.gif";
import { redirectToPage } from "../custom/pageFn";
import { ApiAuth } from "../custom/apiAuth";

class Login extends Component {
  // constructor(props) {
  //   super(props);
  // }

  state = {
    data: {
      usr: "",
      pwd: "",
      isRemember: false,
      message: "",
      error: "",
      vis: false,
      token: { token: "", date: "" },
    },
    vis: {
      modal: false,
    },
    forgot: false,
  };

  componentDidMount() {
    this.getDate();
    this.clearStore();
    this.update();
  }
  update = () => {
    document.title = `Please Login`;
  };
  clearStore = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  getDate = (e) => {
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    this.setState({ data: { ...this.state.data, date: date } });
  };

  handleChange = (e) => {
    // console.log(`${[e.target.name]}`, e.target.value);
    this.setState(
      { data: { ...this.state.data, [e.target.name]: e.target.value } }
      // () => console.log(this.state.data)
    );
  };
  handlePassword = async (e, usr) => {
    const abortCtr = new AbortController();
    e.preventDefault();

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    this.setState({
      vis: {
        ...this.state.vis,
        modal: true,
        title: "Processing",
        msg: (
          <div style={{ position: "relative", overflow: "hidden" }}>
            Please wait while we gathering the data and processing yor request..{" "}
            <img
              style={{
                position: "absolute",
                top: "-225px",
                right: "-200px",
              }}
              src={loading}
              alt="loading..."
              height="500px"
            />
          </div>
        ),
      },
    });
    try {
      let res = await fetch(
        `${apiSite}api/method/frappe.core.doctype.user.user.reset_password?user=${this.state.data.usr}`,
        {
          signal: abortCtr.signal,
          method: "GET",
          headers: headers,
        }
      );
      res = await res.json();
      // console.log(res);
      if (res.exc_type) {
        // console.log(res);
        throw res;
      } else {
        res = JSON.parse(res._server_messages);
        res = JSON.parse(res[0]);
        // console.log(res);
        this.setState({
          forgot: false,
          vis: {
            ...this.state.vis,
            modal: true,
            title: "Reset Succesfull",
            msg:
              res.message + " Please check your email for futher instructions.",
          },
        });
      }
    } catch (error) {
      // console.log(error);
      this.setState({
        vis: {
          ...this.state.vis,
          modal: true,
          title: error.exc_type,
          msg: "Email " + error.message,
        },
      });
    }
  };
  handleSubmit = (e, usr, pwd) => {
    const abortCtr = new AbortController();
    e.preventDefault();
    this.setState({
      vis: {
        ...this.state.vis,
        modal: true,
        title: "Loading",
        msg: (
          <div style={{ position: "relative", overflow: "hidden" }}>
            Please wait while we gathering the data and processing yor request
            for login.{" "}
            <img
              style={{
                position: "absolute",
                top: "-225px",
                right: "-200px",
              }}
              src={loading}
              alt="loading..."
              height="500px"
            />
          </div>
        ),
      },
    });
    let n = 0;
    const loginSister = async () => {
      try {
        // let res = await fetch(`${apiSite}api/method/login`, {
        //   method: "POST",
        //   credentials: "include",
        //   body: JSON.stringify(this.state.data),
        //   headers: headers,
        //   mode: "cors",
        // });
        // res = await res.json();
        let res = await ApiAuth(this.state.data.usr, this.state.data.pwd);
        if (res.exception) {
          console.log(res.exception);
          n += 1;
          throw res;
        }
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${res.api_key}:${res.api_secret}`,
        };
        // let res1 = await fetch(
        //   `${apiSite}api/method/frappe.auth.get_logged_user`,
        //   {
        //     method: "POST",
        //     credentials: "include",
        //     headers: headers,
        //     mode: "cors",
        //   }
        // );
        // res1 = await res1.json();
        // if (res1.exception) {
        //   console.log(res1.exception);
        //   n += 1;
        //   throw res1;
        // }
        let res2 = await fetch(
          `${apiSite}api/resource/Instructor?filters=[["instructor_email","=","${res.email}"]]`,
          {
            method: "GET",
            headers: headers,
          }
        );
        res2 = await res2.json();
        if (res2.exception) {
          console.log(res2.exception);
          n += 1;
          throw res2;
        }
        res2 = await res2.data;
        res2 = await res2.map((d) => d.name);

        let res3 = await fetch(`${apiSite}api/resource/User/${res.email}`, {
          method: "GET",
          headers: headers,
        });
        res3 = await res3.json();
        if (res3.exception) {
          console.log(res3.exception);
          n += 1;
          throw res3;
        }
        res3 = await res3.data;
        let roles = await res3.roles.map((d) => d.role);
        let res4 = await fetch(`${apiSite}api/resource/Employee?fields=["*"]`, {
          method: "GET",
          credentials: "include",
          headers: headers,
        });
        res4 = await res4.json();
        if (res4.exception) {
          console.log(res4.exception);
          n += 1;
          throw res4;
        }
        // console.log(res4);
        if (res4.data) {
          res4 = await res4.data[0];
        }

        if (this.state.data.isRemember === true) {
          localStorage.setItem("user_id", res.email);
          sessionStorage.setItem("user_id", res.email);
          res4 && localStorage.setItem("emp_id", JSON.stringify(res4));
          res4 && sessionStorage.setItem("emp_id", JSON.stringify(res4));
          localStorage.setItem("roles", roles);
          sessionStorage.setItem("roles", roles);
          localStorage.setItem("instructor_id", res2);
          sessionStorage.setItem("instructor_id", res2);
          localStorage.setItem("full_name", res3.full_name);
          sessionStorage.setItem("full_name", res3.full_name);
          localStorage.setItem("usr", JSON.stringify(res3));
          sessionStorage.setItem("usr", JSON.stringify(res3));
        } else {
          sessionStorage.setItem("user_id", res.email);
          res4 && sessionStorage.setItem("emp_id", JSON.stringify(res4));
          sessionStorage.setItem("roles", roles);
          sessionStorage.setItem("instructor_id", res2);
          sessionStorage.setItem("full_name", res3.full_name);
          sessionStorage.setItem("usr", JSON.stringify(res3));
        }

        this.setState({
          vis: {
            ...this.state.vis,
            modal: true,
            title: res.message,
            msg: (
              <div style={{ position: "relative", overflow: "hidden" }}>
                Welcome {res3.full_name}
                <br />
                Please wait we're logging you in.{" "}
                <img
                  style={{
                    position: "absolute",
                    top: "-225px",
                    right: "-200px",
                  }}
                  src={loading}
                  alt="loading..."
                  height="500px"
                />
              </div>
            ),
          },
        });

        redirectToPage(5, "/d");
      } catch (error) {
        console.log(error);
        this.setState({
          vis: {
            ...this.state.vis,
            modal: true,
            title: error.message + " - " + n,
            msg: error.exception,
          },
        });
      }
    };

    loginSister();
  };

  render() {
    const data = this.state.data;
    const vis = this.state.vis;
    return (
      <>
        <Modal
          modal={vis.modal}
          title={vis.title}
          element={vis.msg}
          handleClose={() => this.setState({ vis: { ...vis, modal: false } })}
        />
        <div className="form-center">
          <br />
          <form
            className="form-signin"
            method="get"
            onSubmit={this.handleSubmit}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  className="mb-4"
                  src="./logo512.png"
                  alt=""
                  width="72"
                  height="72"
                  style={{ borderRadius: "5px" }}
                />
              </div>
              <h1 className="h3 mb-3 font-weight-normal">
                {this.state.forgot ? "Forgot Password" : "Sign in"}
              </h1>
            </div>
            <label className="sr-only" htmlFor="usr">
              {this.state.forgot ? "Email" : "Username"}
            </label>
            <input
              className="form-control"
              type="text"
              name="usr"
              id="usr"
              placeholder={this.state.forgot ? "Email" : "Username"}
              required
              autoFocus
              onChange={this.handleChange}
            />
            {this.state.forgot ? (
              ""
            ) : (
              <>
                <label className="sr-only" htmlFor="pwd">
                  Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="pwd"
                  id="inputpwd"
                  placeholder="password"
                  required
                  onChange={this.handleChange}
                />
                {/* <div className="form-check checkbox mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isRemember"
                    value={this.state.data.isRemember}
                    // checked={this.state.data.isRemember}
                    onClick={() =>
                      this.setState(
                        {
                          data: {
                            ...this.state.data,
                            isRemember: !this.state.data.isRemember,
                          },
                        },
                        () => console.log(this.state.data)
                      )
                    }
                    // onChange={this.handleChange}
                  />
                  <label className="form-check-label"> Remember me</label>
                </div> */}
              </>
            )}
            <span>{data.message}</span>

            <div className="w-100" style={{ height: "25px" }}></div>
            {this.state.forgot ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={this.handlePassword}
                >
                  Reset Password
                </button>
                <span>or</span>
                <button
                  className="btn btn-light"
                  style={{ display: "block", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ forgot: !this.state.forgot });
                  }}
                >
                  Login Here
                </button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <button className="btn btn-primary" type="submit">
                  Sign in
                </button>
                <span>or</span>
                <button
                  className="btn btn-light"
                  style={{ display: "block", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ forgot: !this.state.forgot });
                  }}
                >
                  Forgot Password !!
                </button>
              </div>
            )}
            <div className="w-100" style={{ height: "25px" }}></div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Don't have an account?</span>
            </div>
            <hr />
            <p className="text-muted" style={{ textAlign: "center" }}>
              &copy; 2021-2023
            </p>
          </form>
        </div>
      </>
    );
  }
}

export default Login;
