import React from "react";

const Question = (props) => {
  const handleChange = (e) => {
    props.handleChange(e);
  };
  const handleUp = (e) => {
    props.handleUp(e);
  };
  const handleDown = (e) => {
    props.handleDown(e);
  };
  return (
    <div
      key={props.input.id + props.input.name + props.input.type}
      style={{
        display: props.input.id === props.data.id ? "block" : "none",
      }}
    >
      {/* {console.log(props)} */}
      {
        {
          checkbox: (
            <div>
              <label className="form-check-label">{props.input.label}</label>
              <input
                className="form-check-input"
                type={props.input.type}
                name={props.input.name}
                defaultChecked={props.data[props.input.name]}
                onChange={handleChange}
                // required={true}
              />
            </div>
          ),
          radio: (
            <div>
              <label className="form-check-label">{props.input.label}</label>
              {props.input.options.map((d) => (
                <div className="form-check">
                  <label className="form-check-label">{d}</label>
                  <input
                    className="form-check-input"
                    type={props.input.type}
                    name={props.input.name}
                    value={d}
                    onChange={handleChange}
                    checked={props.data[props.input.name] === d}
                    // required={true}
                  />
                </div>
              ))}
            </div>
          ),
          select: (
            <div>
              <label className="form-check-label">{props.input.label}</label>
              <select
                className="form-select"
                name={props.input.name}
                onChange={handleChange}
                defaultValue={props.data[props.input.name]}
                // required={true}
              >
                <option value="-">Choose your {props.input.name}</option>
                {props.input.options.map((d) => (
                  <>
                    <option value={d}>{d}</option>
                  </>
                ))}
              </select>
            </div>
          ),
          text: (
            <div>
              <label>{props.input.label}</label>
              <input
                className="form-control"
                type={props.input.type}
                name={props.input.name}
                value={props.data[props.input.name]}
                onChange={handleChange}
                // required={true}
              />
            </div>
          ),
          number: (
            <div>
              <label>{props.input.label}</label>
              <input
                className="form-control"
                type={props.input.type}
                name={props.input.name}
                value={props.data[props.input.name]}
                onChange={handleChange}
                // required={true}
              />
            </div>
          ),
          email: (
            <div>
              <label>{props.input.label}</label>
              <input
                className="form-control"
                type={props.input.type}
                name={props.input.name}
                value={props.data[props.input.name]}
                onChange={handleChange}
                // required={true}
              />
            </div>
          ),
        }[props.input.type]
      }

      {props.input.length === props.data.id ? (
        ""
      ) : (
        <button className="btn btn-sm btn-light" onClick={handleDown}>
          <i className="bi bi-chevron-down"></i>
        </button>
      )}
      {props.data.id === 1 ? (
        ""
      ) : (
        <button className="btn btn-sm btn-light" onClick={handleUp}>
          <i className="bi bi-chevron-up"></i>
        </button>
      )}
    </div>
  );
};

export default Question;
