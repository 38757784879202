import React from "react";

const Instrument = (props) => {
  return (
    <>
      <div className="col-3 form-check">
        <input
          className="form-check-input"
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          checked={props.value === props.checked}
          disabled={props.disabled}
        />
        {console.log(props.disabled)}
        <label className="form-check-label" htmlFor={props.value}>
          {props.value}
        </label>
      </div>
    </>
  );
};

export default Instrument;
