import { apiSite } from "../config/urlLink";
import Login from "../website/login";

const handleLogout = (e) => {
  const abortCtr = new AbortController();
  // e.preventDefault();
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  setTimeout(() => {
    fetch(`${apiSite}api/method/logout`, {
      signal: abortCtr.signal,
      method: "POST",
      credentials: "include",
      headers: headers,
    })
      .then((res) => res.json())

      // display a success message
      .then((res) => {
        alert("Logout Success.");
        // console.log(res);
        sessionStorage.clear();
        localStorage.clear();
        return <Login />;
      })

      // display an alert message for an error
      .catch((err) => {
        return <Login />;
      });
  }, 0);
  // window.location.assign("/login");
};
export default handleLogout;
