import { createContext, useState } from "react";

const UserContext = createContext(null);

const UserContextProvider = (props) => {
  const [modalVis, setModalVis] = useState({
    modal: false,
    title: "",
    body: "",
  });

  const usidStore = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");

  const modalChanges = (changes) => {
    setModalVis({ ...modalVis, ...changes });
    console.log(changes);
  };
  const modalClose = () => {
    setModalVis({ ...modalVis, modal: false });
    window.location.reload();
  };
  const contextValue = {
    modalVis,
    modalChanges,
    modalClose,
  };
  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };
