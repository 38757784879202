import React from "react";

const Gender = (props) => {
  const handleChange = (e) => {
    props.onChange(e);
  };
  return (
    <>
      <div className="row col" style={{ margin: "0px" }}>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="gender"
            value="Male"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Male"}
          />
          <label className="form-check-label" htmlFor="laki-laki">
            Laki-laki
          </label>
        </div>
        <div className="col form-check">
          <input
            required={true}
            type="radio"
            className="form-check-input"
            name="gender"
            value="Female"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "Female"}
          />
          <label className="form-check-label" htmlFor="perempuan">
            Perempuan
          </label>
        </div>
      </div>
    </>
  );
};

export default Gender;
