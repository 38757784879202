import React, { useCallback, useContext, useMemo, useState } from "react";
import StudentCard from "./studentCard";
import "./css/student.css";
import CroStudentTitle from "./croStudentTitle";
import useDate from "../../custom/useDate";
import { StudentsContext } from "../../context/studentsContext";
import CroStudentDetails from "./croStudentDetails";

const CroStudent = () => {
  const [search, setSearch] = useState({ name: "", status: "" });
  const [studentDetails, setStudentDetails] = useState("");
  const { originalData: students, dataFil: groupedByStudent } =
    useContext(StudentsContext);

  const { IsBirthday } = useDate();
  const handleStatus = useCallback((e) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      status: prevSearch.status === e ? "" : e,
    }));
  }, []);

  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const handleStudentCard = (e) => {
    if (studentDetails === e) {
      setStudentDetails("");
    } else {
      setStudentDetails(e);
    }
    console.log(studentDetails);
  };

  const data = useMemo(() => (students ? students[0] : []), [students]);

  const filteredData = useMemo(() => {
    if (!data || !data.length) return [];

    const sortedData = [...data].sort((a, b) => {
      const isBirthdayA = IsBirthday(a.date_of_birth);
      const isBirthdayB = IsBirthday(b.date_of_birth);

      if (isBirthdayA && !isBirthdayB) return -1;
      if (!isBirthdayA && isBirthdayB) return 1;

      return a.title.localeCompare(b.title);
    });

    return sortedData.filter((student) => {
      const queryMatches =
        !search.query ||
        [
          `${student.first_name} ${student.last_name}`, // space between first and last names
          student.nis,
          student.user,
          student.student_mobile_number,
        ].some(
          (field) =>
            field && field.toLowerCase().includes(search.query.toLowerCase())
        );

      const statusMatches = !search.status || student.status === search.status;

      return queryMatches && statusMatches;
    });
  }, [data, search.query, search.status, IsBirthday]);

  return (
    <>
      <div className="w-100 __crostudentcontainer">
        {data && <CroStudentTitle data={data} handleStatus={handleStatus} />}
        <div
          className="row col-md-12 justify-content-between align-items-center"
          style={{ padding: "0 10px" }}
        >
          <form className="row col-md-8 align-items-center">
            <div className="col-auto">
              <label htmlFor="searchName" className="col-form-label">
                Query by Nis, name, email and phone
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                id="searchName"
                className="form-control"
                name="query"
                onChange={handleSearch}
              />
            </div>
          </form>

          <div
            className="col-auto btn btn-primary"
            onClick={() => "addStudetn"}
          >
            <i className="bi bi-plus-square"></i> Add Student
          </div>
        </div>
        <div className="row col-md-12 ">
          <div className="row col-md-6 __crostudentcontentcontainer">
            {filteredData.map((stu) => (
              <StudentCard
                key={stu.title}
                classdiv={"col-6 col-md-3"}
                data={stu}
                handleClick={handleStudentCard}
              />
            ))}
          </div>
          <div className="row col-md-6 __crostudentcontentcontainer">
            {studentDetails
              ? groupedByStudent
                  .filter((f) => f.name === studentDetails)
                  .map((d) => <CroStudentDetails data={d} />)
              : "<-- please choose the student"}
          </div>
        </div>
      </div>
    </>
  );
};

export default CroStudent;
