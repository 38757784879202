import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "../assets/css/dashNav.css";
import DashNav from "./dashNav";
import DashFoot from "./dashFoot";
import routes from "../config/routes";
import { CompDate } from "../custom/dateFormatter";
import axios from "axios";

const Dashboard = (props) => {
  useEffect(() => {
    let empid = JSON.parse(sessionStorage.getItem("emp_id"));
    document.title = `Hi ${empid.last_name}`;

    const getIP = async () => {
      try {
        let res = await axios.get("https://api.ipify.org/?format=json");
        res = await res.data;
        let x = {
          ip: res.ip,
          date: CompDate(new Date()),
          company: empid.company,
          name: empid.employee_name,
          user: sessionStorage.getItem("user_id"),
        };
        console.log(x);
        let res1 = await axios.post("https://abs.mytree.link/php/save.php", x, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            withCredentials: true,
          },
        });
        console.log(res1);
        // res1 = await res1.json();
      } catch (error) {
        console.log(error);
      }
    };
    if (window.location.hostname === "localhost") {
      console.log(window.location.hostname);
    } else {
      getIP();
    }
  }, []);

  return (
    <>
      <div id="main" className="w-100">
        <DashNav />

        <Routes>
          {routes.dash.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={route.element}
            />
          ))}
        </Routes>
        <DashFoot />
      </div>
    </>
  );
};

export default Dashboard;
