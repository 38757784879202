import { createContext, useMemo, useState, useEffect } from "react";
import useResource from "../custom/useResource";

const StudentsContext = createContext(null);

const StudentsContextProvider = (props) => {
  const [studentsData, setStudentsData] = useState([]);
  const [originalData, setOriginalData] = useState([[], [], [], [], []]);

  const { data: student } = useResource("Student", `["*"]`, ``);
  const { data: fees } = useResource("Fees", `["*"]`, ``);
  const { data: payment } = useResource("Payment Entry", `["*"]`, ``);
  const { data: programEnrollment } = useResource(
    "Program Enrollment",
    `["*"]`,
    ``
  );
  const { data: studentGroup } = useResource("Student Group", `["*"]`, ``);

  useEffect(() => {
    Promise.all([
      student || [],
      fees || [],
      payment || [],
      programEnrollment || [],
      studentGroup || [],
    ]).then((res) => {
      const [students, fees, payments, enrollments, groups] = res.map(
        (item) => item.data || []
      );

      setOriginalData([students, fees, payments, enrollments, groups]);

      // Group fees by student
      const feesByStudent = fees.reduce((acc, fee) => {
        if (!acc[fee.student]) {
          acc[fee.student] = [];
        }
        acc[fee.student].push(fee);
        return acc;
      }, {});

      // Group payments by fee
      const paymentsByFee = payments.reduce((acc, payment) => {
        if (!acc[payment.fee]) {
          acc[payment.fee] = [];
        }
        acc[payment.fee].push(payment);
        return acc;
      }, {});

      // Group enrollments by student
      const enrollmentsByStudent = enrollments.reduce((acc, enrollment) => {
        if (!acc[enrollment.student]) {
          acc[enrollment.student] = [];
        }
        acc[enrollment.student].push(enrollment);
        return acc;
      }, {});

      // Group student groups by program enrollment
      const groupsByEnrollment = groups.reduce((acc, group) => {
        if (!acc[group.program_enrollment]) {
          acc[group.program_enrollment] = [];
        }
        acc[group.program_enrollment].push(group);
        return acc;
      }, {});

      // Combine everything into a hierarchical structure
      const combinedData = students.map((student) => ({
        ...student,
        fees: (feesByStudent[student.name] || []).map((fee) => ({
          ...fee,
          payments: paymentsByFee[fee.name] || [],
        })),
        enrollments: (enrollmentsByStudent[student.name] || []).map(
          (enrollment) => ({
            ...enrollment,
            groups: groupsByEnrollment[enrollment.name] || [],
          })
        ),
      }));

      setStudentsData(combinedData);
    });
  }, [student, fees, payment, programEnrollment, studentGroup]);

  const dataFil = useMemo(() => studentsData, [studentsData]);

  const contextValue = {
    originalData, // This holds the original data arrays
    data: studentsData, // This holds the combined grouped data
    dataFil, // Memoized version of the combined data for performance
  };

  return (
    <StudentsContext.Provider value={contextValue}>
      {props.children}
    </StudentsContext.Provider>
  );
};

export { StudentsContextProvider, StudentsContext };
