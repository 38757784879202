import { useState } from "react";
import Modal from "../website/modal";
import {
  GetResourceDetail,
  PostResource,
  SendWa,
  SendWaTemplate,
  UpdateResource,
} from "../custom/apiResource";
import { LongDate, ShortDateFull } from "../custom/dateFormatter";
import { redirectToPage } from "../custom/pageFn";

const EditAttendance = ({ data, cs, handleClose }) => {
  const [form, setForm] = useState({ ...data, cs });
  const [vis, setVis] = useState({ modal: false });

  let fullname = sessionStorage.getItem("full_name");
  const hdlChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    setForm({ ...form, [nam]: val });
  };
  const hdlRate = (i, reset = false) => {
    if (reset) {
      setForm({ ...form, growth_point: 0, rateHover: 0 });
    } else {
      setForm({ ...form, growth_point: i });
    }
  };
  const hdlRateHover = (i) => {
    setForm({ ...form, rateHover: i });
  };
  const hdlUpdate = () => {
    setVis({
      modal: true,
      title: "Update Daily Report",
      body: (
        <>
          <div className="w-100">
            <div
              class="alert"
              style={{ padding: "20px", background: "#f44336", color: "white" }}
            >
              <span
                class="closebtn"
                onClick={(e) => (e.target.parentElement.style.display = "none")}
                style={closebtn}
              >
                &times;
              </span>
              <strong>Caution!</strong> Cannot revert the data after update.
            </div>
            <div className="w-100">
              <p>
                This data will be resent to the student and parent mobile number
                simultaneusly. Please check again.
              </p>
              <button
                className="btn btn-warning"
                onClick={() => {
                  setVis({ modal: false });
                  hdlUpdateNow();
                }}
              >
                Update Now
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setVis({ modal: false })}
              >
                Cancel
              </button>
            </div>
            <div className="__custom-loader"></div>
          </div>
        </>
      ),
    });
  };
  const hdlUpdateNow = async () => {
    setVis({
      modal: true,
      title: "Data Updating",
      body: (
        <>
          <div className="w-100">Please wait while we updating the data.</div>
        </>
      ),
    });
    console.log(form);
    try {
      let update = await UpdateResource("Student Attendance", form.name, form);
      console.log(update);
      let content = `♻ Edited Student Attendance Report on ${new Date()} ♻`;
      let body = {
        comment_type: "Comment",
        reference_doctype: "Student Attendance",
        reference_name: form.name,
        content: content,
      };
      let addComment = await PostResource("Comment", body);
      console.log(addComment);
      let res = await GetResourceDetail("Student", form.student);
      console.log(res);
      let mobile = [];
      mobile.push(res.data.student_mobile_number);
      await res.data.guardians.forEach((e) => {
        if (e) {
          mobile.push(e.mobile_number);
        }
      });
      for (const e of mobile) {
        let msg = {
          ...form,
          cs_id: form.cs.name || "-",
          cs_date: LongDate(form.cs.schedule_date) || "-",
          cs_time: form.cs.from_time || "-",
          additional_info: `♻ Edited | ${ShortDateFull(new Date())} ♻`,
          instructor_name: fullname || "-",
        };
        let wasend = await SendWaTemplate(e, msg, "student_attendance");
        if (!wasend.ok) {
          const errResponse = await wasend.json();
          throw errResponse;
        }
      }
      setVis({
        modal: true,
        title: "Success",
        body: (
          <>
            <div className="w-100">
              The daily report has been updated and send.
            </div>
          </>
        ),
      });

      redirectToPage(3);
    } catch (error) {
      setVis({
        modal: true,
        title: "Ups there is an error",
        body: (
          <>
            <div className="w-100">
              {error.message}
              <br />
              <small>
                Please try again later.If the problem persist please contact the
                administrator.
              </small>
              <br />
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    let msg = {
                      receiver: "6287832340390",
                      message: `${form} || ${error}`,
                      session: "628112971117",
                    };
                    let wasend = await SendWa("6287832340390", msg);

                    if (!wasend.ok) {
                      const errResponse = await wasend.json();
                      throw errResponse;
                    }
                    alert("Message send succesfully!");
                  } catch (err) {
                    console.log(err);
                    alert(
                      "Ups, sorry. Cannot send the message now. There is an unknown error."
                    );
                  }
                }}
              >
                Send Error
              </button>
            </div>
          </>
        ),
      });
    }
  };
  const closebtn = {
    marginLeft: "15px",
    color: "white",
    fontWeight: "bold",
    float: "right",
    fontSize: "22px",
    lineHeight: "20px",
    cursor: "pointer",
    transition: "0.3s",
  };
  return (
    <>
      <Modal
        modal={vis.modal}
        title={vis.title}
        element={vis.body}
        handleClose={() => setVis({ modal: false })}
      />
      <div className="w-100">
        <div
          class="alert"
          style={{ padding: "20px", background: "#f44336", color: "white" }}
        >
          <span
            className="closebtn"
            onClick={(e) => (e.target.parentElement.style.display = "none")}
            style={closebtn}
          >
            &times;
          </span>
          <strong>Caution!</strong> Cannot revert the data after update.
        </div>
        <div
          className="row col-md-12"
          style={{ margin: "0px", padding: "0px" }}
        >
          {/* Student Group */}
          <div className="col-md-4" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Student Group</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              type="text"
              className="form-control"
              value={form.student_group}
              onChange={() => hdlChange()}
              disabled
            />
          </div>
          {/* ID */}
          <div className="col-md-4" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>ID</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              type="text"
              className="form-control"
              value={form.student}
              onChange={() => hdlChange()}
              disabled
            />
          </div>
          {/* Name */}
          <div className="col-md-4" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Name</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              type="text"
              className="form-control"
              value={form.student_name}
              onChange={() => hdlChange()}
              disabled
            />
          </div>
        </div>

        {/* Status & Point */}
        <div
          className="w-100"
          style={{
            padding: "25px 0 10px 0px",
            margin: "0px",
            borderBottom: "solid 1px #e9e9e9",
          }}
        >
          Content
        </div>
        <div
          className="row col-md-12"
          style={{ padding: "0px", margin: "0px" }}
        >
          {/* Absent Status */}
          <div className="col-md-4" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Status</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <select
              className="form-control"
              name="status"
              onClick={() => hdlChange}
              onChange={() => hdlChange}
            >
              <option value="Present">Present</option>
              <option value="Absent">Absent</option>
            </select>
          </div>
          {/* Growth Point */}
          <div
            className="col-md-4"
            style={{
              margin: "0px",
              padding: "0px",
            }}
          >
            <label>
              <small>
                <i>Point</i>
              </small>
            </label>
            <br />
            {/* Rate */}
            <div style={{ display: "flex", paddingLeft: "15px" }}>
              {[...Array(5)].map((star, i) => {
                i += 1;
                return (
                  <div
                    type="star-rating-button"
                    key={i}
                    className={
                      i <= (form.rateHover || form.growth_point) ? "on" : "off"
                    }
                    onClick={() => hdlRate(i)}
                    onMouseEnter={() => hdlRateHover(i)}
                    onMouseLeave={() => hdlRateHover(form.growth_point)}
                    onDoubleClick={() => hdlRate(0, true)}
                  >
                    <i
                      className={
                        i <= (form.rateHover || form.growth_point)
                          ? "bi bi-star-fill"
                          : "bi bi-star"
                      }
                    ></i>
                  </div>
                );
              })}
              {form.growth_point > 0 ? (
                <div
                  onClick={() => hdlRate(0, true)}
                  onBlur={() => hdlChange}
                  style={{
                    cursor: "pointer",
                    padding: "0 15px",
                    display: "flex",
                    alignItems: " center",
                  }}
                >
                  <small>reset point</small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className="row col-md-12"
          style={{ padding: "0px", margin: "0px" }}
        >
          <div className="col-md-12" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Lesson</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <textarea
              type="text"
              className="form-control"
              rows="5"
              value={form.lesson}
              name="lesson"
              onChange={hdlChange}
            />
          </div>
          <div className="col-md-12" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Comment</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <textarea
              type="text"
              className="form-control"
              rows="5"
              value={form.comment}
              name="comment"
              onChange={hdlChange}
            />
          </div>
          <div className="col-md-6" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Video Url</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              type="text"
              className="form-control"
              value={form.video_url}
              name="video_url"
              onChange={hdlChange}
            />
          </div>
        </div>
        <div className="w-100" style={{ padding: "1rem 0", color: "white" }}>
          <button className="btn btn-warning" onClick={() => hdlUpdate()}>
            Update
          </button>
          <button className="btn btn-primary" onClick={(e) => handleClose(e)}>
            Cancel
          </button>
        </div>
        {/* <div className="w-100">{JSON.stringify(form)}</div> */}
        {/* <div className="w-100">{JSON.stringify(cs)}</div> */}
      </div>
    </>
  );
};

export default EditAttendance;
