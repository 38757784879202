import React, { useState, useEffect } from "react";
import useDate from "../custom/useDate";
import "../assets/css/rate.css";
import { apiSite } from "../config/urlLink";
import Modal from "../website/modal";
import { redirectToPage } from "../custom/pageFn";
import { GetToken } from "../custom/apiAuth";
import { SendWa } from "../custom/apiResource";
import html2canvas from "html2canvas";

const AddAttendance = (props) => {
  const { YY, MM, DD } = useDate();
  const { headers } = GetToken();
  const [vis, setVis] = useState({ modal: false });
  const [data, setData] = useState({
    course_schedule: props.data.cs.name,
    date: `${YY}-${MM}-${DD}`,
    student_group: props.data.cs.student_group,
    instructor: props.data.cs.instructor,
    comment: "",
    lesson: "",
    video: "",
    status: "Present",
    growth: "0",
    individually: false,
  });
  const [studentData, setStudentData] = useState("");

  useEffect(() => {
    const getStudentData = async () => {
      const abortCtr = new AbortController();
      try {
        let res = await fetch(
          `${apiSite}api/method/smi.api.get_student_group_student`,
          {
            signal: abortCtr.signal,
            method: "POST",
            headers: headers,
            body: `{"stud_group":"${props.data.cs.student_group
              .replace(/[\\"']/g, "\\$&")
              .replace(/\u0000/g, "\\0")}"}`,
          }
        );
        res = await res.json();

        let da = [];
        let studentArr = await res.message;
        for (let index = 0; index < studentArr.length; index++) {
          // data Looping
          let loop = [];
          loop = {
            ...res.message[index],
            ...data,
            student_status: props.sg.sg[index].status,
            course: props.data.cs.name,
          };
          da.push(loop);
        }

        setStudentData(da);
        return da;
      } catch (error) {
        console.error(error);
      }
    };
    getStudentData();
    // eslint-disable-next-line
  }, []);

  const handleRate = (i, reset) => {
    let da = [];
    let studentArr = studentData;
    if (reset === undefined) {
      for (let index = 0; index < studentArr.length; index++) {
        // data Looping
        let loop = [];
        loop = {
          ...studentData[index],
          growth: i,
        };
        da.push(loop);
      }
      // console.log(i, reset);
      setData({ ...data, growth: i });
      setStudentData(da);
    } else {
      for (let index = 0; index < studentArr.length; index++) {
        // data Looping
        let loop = [];
        loop = {
          ...studentData[index],
          growth: 0,
          rateHover: 0,
        };
        da.push(loop);
      }
      setData({ ...data, growth: 0, rateHover: 0 });
      setStudentData(da);
    }
  };
  const handleChange = (e) => {
    let n = e.target.name;
    let v = e.target.value;
    let da = [];
    let studentArr = studentData;
    if (n === "status" && v === "Absent") {
      for (let index = 0; index < studentArr.length; index++) {
        // data Looping
        let loop = [];
        loop = {
          ...studentData[index],
          [e.target.name]: e.target.value,
          growth: "0",
          rateHover: "0",
        };
        da.push(loop);
      }
      // console.log(da);
      setData({
        ...data,
        [e.target.name]: e.target.value,
        growth: "0",
        rateHover: "0",
      });
      setStudentData(da);
    } else {
      for (let index = 0; index < studentArr.length; index++) {
        // data Looping
        let loop = [];
        loop = {
          ...studentData[index],
          [e.target.name]: e.target.value,
        };
        da.push(loop);
      }
      // console.log(da);
      setData({ ...data, [e.target.name]: e.target.value });
      setStudentData(da);
    }
  };
  const handleClose = (e) => {
    console.log(props);
    props.handleClose(e);
  };
  const handleIndividu = (e) => {
    handleChange(e);
    setData({ ...data, individually: !data.individually });
  };
  const handleChangeIndividu = (eventHandler, index, name, value) => {
    let data = [...studentData];
    if (name === "status") {
      let n = eventHandler.target.name;
      let v = eventHandler.target.value;
      if (v === "Absent") {
        data[index] = {
          ...data[index],
          [n]: v.toString(),
          comment: "",
          add: "",
          growth: "0",
          rateHover: "0",
        };
      } else {
        data[index] = { ...data[index], [n]: v.toString() };
      }
    } else if (name === "add") {
      let v = eventHandler.target.value;
      data[index] = { ...data[index], comment: data.comment, add: v };
    } else if (name === "reset") {
      data[index] = { ...data[index], growth: "0", rateHover: "0" };
    } else {
      data[index] = { ...data[index], [name]: value.toString() };
    }
    setStudentData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setVis({
      ...vis,
      modal: true,
      title: "Processing data",
      msg: "please wait while we are processing your data",
    });
    try {
      const abortCtr = new AbortController();
      for (let index = 0; index < studentData.length; index++) {
        // data Looping
        let loop = [];
        loop = { data: studentData[index] };
        let res = await fetch(
          `${apiSite}api/method/smi.api.post_student_attendance`,
          {
            signal: abortCtr.signal,
            method: "POST",
            headers: headers,
            body: JSON.stringify(loop),
          }
        );
        res = await res.json();
        console.log(res);
        if (res.exc) {
          throw res;
        } else {
          res = await JSON.parse(res._server_messages);

          const result = res.reduce(
            (acc, item) => {
              const obj = JSON.parse(item);
              acc.message.push(obj.message);
              if (obj.title) {
                acc.title.push(obj.title);
              }
              return acc;
            },
            { message: [], title: [] }
          );

          console.log(result);
          let msg = result.message.join("\n");
          // console.log(res);

          setVis({
            ...vis,
            modal: true,
            title: result.title[0] + " Info",
            msg: msg,
          });
        }
      }
      redirectToPage(3);
    } catch (error) {
      console.error(error);
      setVis({
        ...vis,
        modal: true,
        title: error.exc_type,
        msg: (
          <div>
            {error.exception} <br />
            {error.exc_type ? (
              ""
            ) : (
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    let msg = {
                      receiver: "6287832340390",
                      message: `${JSON.stringify(data)} || ${JSON.stringify(error)}`,
                      session: "628112971117",
                    };
                    let wasend = await SendWa("6287832340390", msg);

                    if (!wasend.ok) {
                      const errResponse = await wasend.json();
                      throw errResponse;
                    }
                    alert("Message send succesfully!");
                  } catch (err) {
                    console.log(err);
                    alert(
                      "Ups, sorry. Cannot send the message now. There is an unknown error."
                    );
                  }
                }}
              >
                Send Error Report
              </button>
            )}
          </div>
        ),
      });
    }
    // console.log(e);
  };

  // Function to capture screenshot and send error report
  const sendErrorReport = (error) => {
    html2canvas(document.body).then((canvas) => {
      // Convert canvas to base64 image
      const imgData = canvas.toDataURL("image/png");

      // Create a new FormData object
      const formData = new FormData();
      formData.append("screenshot", imgData);
      formData.append("error", JSON.stringify(error));

      // Send the FormData to the server
      fetch("url_to_send_error_report", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // Handle response
          console.log("Error report sent successfully");
        })
        .catch((error) => {
          console.error("Error sending error report:", error);
        });
    });
  };

  return (
    <>
      {/* {console.log("prop", props)}
      {console.log("student data", studentData)} */}
      <Modal
        modal={vis.modal}
        title={vis.title}
        element={<>{vis.msg}</>}
        handleClose={() => setVis({ modal: false })}
      />
      <div className="row col-md-12" style={{ padding: "0", margin: "0px" }}>
        {/* <p>{JSON.stringify(data)}</p>
        <p>{JSON.stringify(studentData)}</p> */}
        {/* Course & Date */}
        <div
          className="row col-md-12"
          style={{ padding: "5px 0px", margin: "0px" }}
        >
          <div className="col-md-6" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Course Schedule</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              required={true}
              readOnly
              onChange={handleChange}
              onClick={handleChange}
              onBlur={handleChange}
              type="text"
              className="form-control"
              name="course_schedule"
              id="course_schedule"
              value={data.course_schedule}
              aria-describedby="mobileHelp"
            />
          </div>
          <div className="col-md-6" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Student Group</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              required={true}
              readOnly
              onChange={handleChange}
              onClick={handleChange}
              onBlur={handleChange}
              type="text"
              className="form-control"
              name="student_group"
              id="student_group"
              value={data.student_group}
              aria-describedby="mobileHelp"
            />
          </div>
        </div>
        {/* Date, Status & Point */}
        <div
          className="row col-md-12"
          style={{ padding: "5px 0px", margin: "0px" }}
        >
          {/* Date */}
          <div className="col-md-4" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Date</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            <input
              required={true}
              onChange={handleChange}
              onClick={handleChange}
              onBlur={handleChange}
              type="date"
              className="form-control"
              name="date"
              id="date"
              value={data.date}
              readOnly={data.individually}
              aria-describedby="mobileHelp"
            />
          </div>
          {/* Absent Status */}
          <div className="col-md-4" style={{ margin: "0px", padding: "0px" }}>
            <label>
              <small>
                <i>Status</i>
                <span className="text-danger">*</span>
              </small>
            </label>
            {data.individually ? (
              <>
                <input
                  className="form-control"
                  type="text"
                  name="status"
                  readOnly={data.individually}
                  value={data.status}
                />
              </>
            ) : (
              <select
                className="form-control"
                name="status"
                onClick={handleChange}
                onChange={handleChange}
              >
                <option value="Present">Present</option>
                <option value="Absent">Absent</option>
              </select>
            )}
          </div>
          {/* Growth Point */}
          <div
            className="col-md-4"
            style={{
              margin: "0px",
              padding: "0px",
            }}
          >
            <label>
              <small>
                <i>Point</i>
              </small>
            </label>
            <br />
            {data.individually || data.status === "Absent" ? (
              <>
                <div style={{ display: "flex", paddingLeft: "15px" }}>
                  {[...Array(5)].map((star, i) => {
                    i += 1;
                    return (
                      <div
                        type="star-rating-button"
                        key={i}
                        className={
                          i <= (data.rateHover || data.growth) ? "on" : "off"
                        }
                        style={{ color: "#b0b0b0" }}
                      >
                        <i
                          className={
                            i <= (data.rateHover || data.growth)
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                        ></i>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                {/* Rate */}
                <div style={{ display: "flex", paddingLeft: "15px" }}>
                  {[...Array(5)].map((star, i) => {
                    i += 1;
                    return (
                      <div
                        type="star-rating-button"
                        key={i}
                        className={
                          i <= (data.rateHover || data.growth) ? "on" : "off"
                        }
                        onClick={() => handleRate(i)}
                        onMouseEnter={() => setData({ ...data, rateHover: i })}
                        onMouseLeave={() =>
                          setData({ ...data, rateHover: data.growth })
                        }
                        onDoubleClick={() => handleRate(0, 0)}
                      >
                        <i
                          className={
                            i <= (data.rateHover || data.growth)
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                        ></i>
                      </div>
                    );
                  })}
                  {data.growth > 0 ? (
                    <div
                      onClick={() => handleRate(0, 0)}
                      onBlur={handleChange}
                      style={{
                        cursor: "pointer",
                        padding: "0 15px",
                        display: "flex",
                        alignItems: " center",
                      }}
                    >
                      <small>reset point</small>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {studentData.length > 1 ? (
          <div
            className="col-md-12"
            style={{ padding: "5px 0px", margin: "0px" }}
          >
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="individually"
                onClick={handleIndividu}
                disabled={data.status === "Absent" ? true : false}
              />
              <label className="form-check-label">
                <small>
                  <i>Edit Attendance Individually</i>
                </small>
              </label>
            </div>
          </div>
        ) : (
          <div className="w-100" style={{ height: "15px" }}></div>
        )}
        <hr style={{ padding: " 0px", margin: "0px" }} />
        <div
          className="row col-md-12"
          style={{ padding: "5px 0px", margin: "0px" }}
        >
          <label style={{ padding: "5px 0px", margin: "0px" }}>
            <small>
              <i>Student Details</i>
            </small>
          </label>
          {/* Student Detail */}
          {studentData &&
            studentData.map((e, i) => (
              <div
                key={i}
                className="col-md-6"
                style={{
                  padding: "5px",
                  margin: "0px",
                }}
              >
                <div
                  style={{
                    padding: "15px",
                    background: "#efefef",
                    borderRadius: "5px",
                  }}
                >
                  <label>
                    <small>
                      {e.student} - {e.student_status} |{" "}
                      {e.payment === "Paid" ? (
                        <span style={{ color: "green" }}>{e.payment}</span>
                      ) : (
                        <span style={{ color: "red" }}>{e.payment}</span>
                      )}
                    </small>
                    <br />
                    {e.student_name}
                  </label>
                  {data.individually === true ? (
                    <>
                      {/* Rate */}

                      <select
                        className="form-control"
                        name="status"
                        onClick={(ev) => handleChangeIndividu(ev, i, "status")}
                        onChange={(ev) => handleChangeIndividu(ev, i, "status")}
                      >
                        <option value="Present">Present</option>
                        <option value="Absent">Absent</option>
                      </select>
                      {studentData[i].status === "Absent" ? (
                        <>
                          <div style={{ display: "flex", paddingLeft: "15px" }}>
                            {[...Array(5)].map((star, i) => {
                              i += 1;
                              return (
                                <div
                                  type="star-rating-button"
                                  key={i}
                                  className={
                                    i <= (e.rateHover || e.growth)
                                      ? "on"
                                      : "off"
                                  }
                                  style={{ color: "#b0b0b0" }}
                                >
                                  <i
                                    className={
                                      i <= (e.rateHover || e.growth)
                                        ? "bi bi-star-fill"
                                        : "bi bi-star"
                                    }
                                  ></i>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ display: "flex", paddingLeft: "15px" }}>
                            {[...Array(5)].map((star, index) => {
                              index += 1;
                              return (
                                <div
                                  type="star-rating-button"
                                  key={index}
                                  className={
                                    index <= (e.rateHover || e.growth)
                                      ? "on"
                                      : "off"
                                  }
                                  onClick={(ev) =>
                                    handleChangeIndividu(ev, i, "growth", index)
                                  }
                                  onMouseEnter={(ev) =>
                                    handleChangeIndividu(
                                      ev,
                                      i,
                                      "rateHover",
                                      index
                                    )
                                  }
                                  onMouseLeave={(ev) =>
                                    handleChangeIndividu(
                                      ev,
                                      i,
                                      "rateHover",
                                      studentData[i].growth
                                    )
                                  }
                                  onDoubleClick={(ev) =>
                                    handleChangeIndividu(ev, i, "reset")
                                  }
                                >
                                  <i
                                    className={
                                      index <= (e.rateHover || e.growth)
                                        ? "bi bi-star-fill"
                                        : "bi bi-star"
                                    }
                                  ></i>
                                </div>
                              );
                            })}
                            {studentData[i].growth > 0 ? (
                              <div
                                onClick={(ev) =>
                                  handleChangeIndividu(ev, i, "reset")
                                }
                                style={{
                                  cursor: "pointer",
                                  padding: "0 15px",
                                  display: "flex",
                                  alignItems: " center",
                                }}
                              >
                                <small>reset point</small>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}
                      <input
                        className="form-control"
                        type="text"
                        name="add"
                        placeholder="Specific Comment"
                        value={studentData[i].add}
                        onChange={(ev) => handleChangeIndividu(ev, i, "add")}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </div>
        <hr style={{ padding: " 0px", margin: "0px" }} />
        {/* Lesson */}
        <div className="col-md-12" style={{ margin: "0px", padding: "0px" }}>
          <label>
            <small>
              <i>Lesson</i>
            </small>
          </label>
          {/* <input
            onChange={handleChange}
            onClick={handleChange}
            onBlur={handleChange}
            type="text"
            className="form-control"
            name="lesson"
            id="lesson"
            value={data.lesson}
            readOnly={data.status === "Absent" ? true : false}
            aria-describedby="mobileHelp"
          /> */}
          <textarea
            name="lesson"
            className="form-control"
            onChange={handleChange}
            onClick={handleChange}
            onBlur={handleChange}
            value={data.lesson}
            readOnly={data.status === "Absent" ? true : false}
            cols="30"
            rows="5"
            style={{ resize: "vertical", width: "100%" }}
          ></textarea>
        </div>
        {/* Comment */}
        <div className="col-md-12" style={{ margin: "0px", padding: "0px" }}>
          <label>
            <small>
              <i>Comment</i>
            </small>
          </label>
          <textarea
            name="comment"
            className="form-control"
            onChange={handleChange}
            onClick={handleChange}
            onBlur={handleChange}
            value={data.comment}
            cols="30"
            rows="3"
            style={{ resize: "vertical", width: "100%" }}
          ></textarea>
        </div>

        {/* Video Url */}
        <div className="col-md-12" style={{ margin: "0px", padding: "0px" }}>
          <label>
            <small>
              <i>Video Url</i>
            </small>
          </label>
          <input
            onChange={handleChange}
            onClick={handleChange}
            onBlur={handleChange}
            type="text"
            className="form-control"
            name="video"
            id="video"
            value={data.video}
            readOnly={data.status === "Absent" ? true : false}
            aria-describedby="mobileHelp"
          />
        </div>
        <div className="w-100" style={{ height: "25px" }}></div>
        <button
          type="button"
          className="btn btn-primary"
          style={{
            textAlign: "center",
            width: "fit-content",
            height: "auto",
          }}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          type="button"
          className="btn btn-warning"
          style={{
            textAlign: "center",
            width: "fit-content",
            height: "auto",
          }}
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default AddAttendance;
