//njajal.sekolahmusik.co.id/api/resource/Employee?filters=[[%22user_id%22,%22=%22,%22tjipto@sekolahmusik.co.id%22]]&fields=[%22company%22]

import { useState, useEffect } from "react";
import { apiSite } from "../config/urlLink";
import { GetToken } from "./apiAuth";

const useResource = (doctype, fields = [], filters = []) => {
  const { headers } = GetToken();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCtr = new AbortController();
    setIsLoading(true);

    const fetchData = async () => {
      try {


        const response = await fetch(
          `${apiSite}api/resource/${doctype}?fields=${fields}&filters=[${filters}]&limit_page_length=None`,

          {
            signal: abortCtr.signal,
            method: "GET",
            headers: headers,
          }
        )
        if (!response.ok) {
          throw new Error("Could not fetch the data for that resource")
        }
        const result = await response.json()


        setData(result);
        setIsLoading(false);
        setError(null);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("fetch data aborted");
        } else {
          setIsLoading(false);
          setError(err.message);
          console.log(err.message);
        }
      };
    }
    setTimeout(() => {
      fetchData()
    }, 3000);

    return () => abortCtr.abort();

    // eslint-disable-next-line
  }, [doctype, fields, filters]);

  return { data, isLoading, error };
};

export default useResource;
