import quiet from "../assets/img/quiet.jpg";

const NoData = () => {
  return (
    <div
      className="w-100"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "10px",
          width: "80%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "400px",
            background: `url(${quiet}) center 70% /cover`,
          }}
        ></div>
        <p style={{ fontSize: "10px", fontWeight: "100" }}>
          <a href="https://www.filmaffinity.com/us/movieimage.php?imageId=834060903">
            Image taken from this link.
          </a>
        </p>
        <hr />
        {/* <img width={"80%"} src={quiet} alt="Where is everybody?" /> */}
        <p>
          <b>IT'S A BIT QUIET AROUND HERE RIGHT NOW.</b> <br />
          <i>
            <small>please ask CRO to put somebody here.</small>
          </i>
        </p>
      </div>
    </div>
  );
};

export default NoData;
