import useWeb from "../custom/useWeb";

const CompReason = () => {
  const { data } = useWeb("get_reason_for_joining");
  return (
    <div className="__inputgroup">
      <label className="__inputlabel" htmlFor="job">
        joining reason <b style={{ color: "#f99" }}>*</b>
      </label>
      <select
        className="form-select"
        name="unit"
        //   onChange={handleChange}
        defaultValue=""
        //   value={
        //     props.dataForm &&
        //     (props.dataForm === null ? "" : props.dataForm.unit)
        //   }
        required={true}
      >
        <option key="0" value="" disabled>
          -
        </option>
        {data &&
          data.message.map((d) => (
            <>
              <option key={d[0]} value={d[1]}>
                {d[1]}
              </option>
            </>
          ))}
      </select>
    </div>
  );
};

export default CompReason;
