import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { ShortDateFull } from "../custom/dateFormatter";
import interactionPlugin from "@fullcalendar/interaction";
import Modal from "../website/modal";
import useResource from "../custom/useResource";
import { apiSite } from "../config/urlLink";
import AddAttendance from "./addAttendance";
import "../assets/css/fullcalendar.css";
import useWindow from "../custom/useWindow";
import { GetToken } from "../custom/apiAuth";

const DashCalFull = (props) => {
  const { width } = useWindow();
  // eslint-disable-next-line
  const [event, setEvent] = useState({
    weekendsVisible: true,
    currentEvents: [],
  });
  const [studentGroup, setStudentGroup] = useState("");
  const [data, setData] = useState("");
  const { data: colorCode } = useResource("Color Code", `["*"]`, ``);
  const [vis, setVis] = useState({ modal: false });
  const handleAttendance = async (cs) => {
    // console.log(cs, i);
    const { headers } = GetToken();

    setStudentGroup("");
    const abortCtr = new AbortController();
    console.log(cs);

    try {
      let res = await fetch(
        `${apiSite}api/method/smi.api.get_student_group_student`,
        {
          signal: abortCtr.signal,
          method: "POST",
          headers: headers,
          body: `{"stud_group":"${cs.student_group
            .replace(/[\\"']/g, "\\$&")
            .replace(/\u0000/g, "\\0")}"}`,
        }
      );
      res = await res.json();
      console.log(res);
      setData({
        ...data,
        // i: i,
        cs,
        detail: res.message,
      });
      setStudentGroup({
        sg: res.message,
        key: `${cs.student_group}${cs.name}`,
      });
      setVis({
        ...vis,
        // i: i,
        modal: true,
        title: "Add Attendance",
        val: 1,
      });
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const renderSidebar = () => {
    return (
      <div className="w-100">
        <div className="w-100">
          <span>All Events ({props.events.length})</span>
        </div>
        <div className="w-100" style={{ height: "80vh", overflowY: "auto" }}>
          <div className="w-100" style={{ height: "15px" }}></div>
          {props.events.map((d) => (
            <>
              <div key={d.id}>
                <b>{ShortDateFull(d.start)}</b>
                <br />
                <i>{d.title}</i>
              </div>
              <div className="w-100" style={{ height: "15px" }}></div>
            </>
          ))}
        </div>
      </div>
    );
  };

  const handleEventClick = (clickInfo) => {
    let o = clickInfo.event._def;
    let p = o.extendedProps;
    let u = o.ui;
    let cs = {
      instructor: p.instructor,
      instructor_name: p.instructor_name,
      name: p.name,
      student_group: o.title,
    };
    setVis({
      modal: true,
      val: 0,
      title: (
        <>
          {p.name}{" "}
          <span
            className="badge badge-sm badge-primary"
            style={{
              fontSize: "14px",
              fontWeight: "300",
              backgroundColor: `${u.backgroundColor}`,
            }}
          >
            {o.groupId.toUpperCase()}
          </span>{" "}
          {p.attendance_status && (
            <span
              className="badge badge-sm badge-warning"
              style={{
                fontSize: "14px",
                fontWeight: "300",
                background: "orange",
              }}
            >
              {p.attendance_status.toUpperCase()}
            </span>
          )}
        </>
      ),
      msg: (
        <>
          <p>
            Student Group : {o.title} <br />
            Course : {p.course} <br />
            Program : {p.program} <br />
            Room : {p.room} <br />
            Company : {p.company} <br />
          </p>
          <p>
            Instructor : {p.instructor} <br />
            Instructor Name : {p.instructor_name} <br />
          </p>
          <hr />
          {p.attendance_status === null ||
            p.attendance_status === "Not Filled" ? (
            <button
              className="btn btn-primary"
              style={{
                fontSize: "18px",
                textAlign: "center",
                //   color: "grey",
                //   background: "lightgrey",
              }}
              onClick={() => handleAttendance(cs)}
            >
              <i className="bi bi-plus-square"></i> Add Attendance
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              style={{
                fontSize: "18px",
                textAlign: "center",
                //   color: "grey",
                //   background: "lightgrey",
              }}
              onClick={() => alert("cannot attend twice.")}
            >
              <i className="bi bi-plus-square-fill"></i> Add Attendance
            </button>
          )}
          {/* {JSON.stringify(o)} */}
        </>
      ),
    });
    // console.log("click", clickInfo);
  };

  const handleEvents = (events) => {
    // console.log("handle event", events);
    setEvent({
      currentEvents: events,
    });
  };
  let nowDateContent = "";
  const renderIndicatorContent = (event) => {
    // console.log(event);
    try {
      if (event.isAxis) {
        nowDateContent = new Date(event.date);
        return <div style={{ borderTop: "1px red solid" }}></div>;
      } else {
        return (
          <div
            className=""
            style={{
              borderTop: "2px red solid",
              color: "red",
              textAlign: event.view.type === "timeGridWeek" ? "right" : "center",
              position: "absolute",
            }}
          >
            <div style={{ position: "relative", top: "-25px" }}>
              {event.view.type === "timeGridWeek"
                ? ("0" + nowDateContent.getHours()).slice(-2) +
                "." +
                ("0" + nowDateContent.getMinutes()).slice(-2) +
                "  "
                : ShortDateFull(nowDateContent)}
            </div>
          </div>
        );
      }
    } catch (error) {
      console.log(error)
    }

  };
  const renderEventContent = (eventInfo) => {
    let o = eventInfo.event._def;
    let p = o.extendedProps;
    // let u = o.ui;
    // console.log(eventInfo.event.title, eventInfo);
    return (
      <>
        {p.attendance_status === null ||
          p.attendance_status === "Not Filled" ? (
          <div className="w-100">
            <div className="w-100">
              <b>{eventInfo.timeText.split(" - ")[0]}</b>
            </div>
            <div className="w-100">
              <i>{eventInfo.event.title}</i>
            </div>
          </div>
        ) : (
          <div className="w-100" style={{ opacity: "0.5" }}>
            <div className="w-100">
              <b>{eventInfo.timeText.split(" - ")[0]}</b>
              {p.attendance_status}
            </div>
            <div className="w-100">
              <i>{eventInfo.event.title}</i>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {/* {console.log(event)} */}
      <Modal
        modal={vis.modal}
        title={vis.title}
        element={
          {
            0: <>{vis.msg}</>,
            1: (
              <AddAttendance
                data={data}
                sg={studentGroup}
                handleClose={() => setVis({ modal: false })}
              />
            ),
          }[vis.val]
        }
        handleClose={() => setVis({ modal: false })}
      />
      <div id="calendarEL" className="col-md-9">
        {/* {console.log(props.events)} */}
        {props.events.length > 0 && (
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridDay,timeGridWeek,dayGridMonth listMonth",
            }}
            allDaySlot={false}
            initialView="timeGridWeek"
            editable={false}
            dayHeaderFormat={{
              weekday: "short",
              month: "2-digit",
              day: "2-digit",
              omitCommas: true,
            }}
            nowIndicator={true}
            nowIndicatorClassNames={""}
            nowIndicatorContent={renderIndicatorContent}
            // contentHeight={"650"}
            height={"95%"}
            // selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            // weekends={event.weekendsVisible}
            // events={props.events}
            initialEvents={props.events} // alternatively, use the `events` setting to fetch from a feed
            // select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={() => handleEvents(props.events)} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
  eventAdd={function(){}}
  eventChange={function(){}}
  eventRemove={function(){}}
  */
          />
        )}
        <div className="row w-100" style={{ display: "flex", padding: "15px" }}>
          {width > 450 ? (
            <>
              <div className="col-auto">Legend :</div>
              {colorCode &&
                colorCode.data
                  .sort((a, b) => a.title < b.title)
                  .map((d) => (
                    <div
                      id={d.color + d.name}
                      className="col-auto"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "10px",
                          background: d.color,
                        }}
                      // onMouseEnter={(e) => console.log(e)}
                      ></div>
                      {d.title}
                    </div>
                  ))}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="w-100" style={{ height: "25px" }}></div>
      </div>
      <div className="col-md-3">{renderSidebar()}</div>
    </>
  );
};

export default DashCalFull;
