import { apiSite } from "../config/urlLink";

const ApiAuth = async (usr, pwd) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    let res = await fetch(`${apiSite}api/method/smi.api.login`, {
      method: "POST",
      body: JSON.stringify({ usr: usr, pwd: pwd }),
      headers: headers,
      mode: "cors",
    });
    res = await res.json();
    if (res.exception) {
      throw res;
    }
    res = await res.message;
    SetToken(res.api_key, res.api_secret);
    return res;
  } catch (error) {
    return error;
  }
};

const SetToken = (key, secret, local = false) => {
  let token = `${key}:${secret}`;
  sessionStorage.setItem("token", token);
  if (local) {
    localStorage.setItem("token", token);
  }
};
const GetToken = () => {
  let token = sessionStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `token ${token}`,
  };
  //   console.log(headers);
  return { headers };
};
export { ApiAuth, SetToken, GetToken };
