import React from "react";
import useResource from "../custom/useResource";
import useWindow from "../custom/useWindow";

const DashFoot = () => {
  const { width } = useWindow();
  const usidStore = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");
  const { data } = useResource(
    "Employee",
    '["company"]',
    `["user_id", "=", "${usidStore}"]`
  );

  const dat =
    data === null || data === undefined
      ? "None"
      : data.data[0] === undefined
      ? "None"
      : data.data[0].company;
  return (
    <>
      {/* {console.log(data)} */}
      {width > 450 ? (
        <div className="copyright">
          <div className="sub-copyright">
            <div style={{ clear: "both" }}></div>
            <div className="w-100" style={{ height: "25px" }}></div>

            <div style={{ fontSize: "9px", color: "rgba(65, 105, 178, 1)" }}>
              SISTER | {dat ? dat : "None"}
            </div>

            <div className="w-100" style={{ height: "25px" }}></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default DashFoot;
