import React from "react";
import { Link } from "react-router-dom";
import useWindow from "../custom/useWindow";
import GetCourse from "./getCourse";
import "../assets/css/dash.css";
import handleLogout from "../custom/logout";

const Dash = (props) => {
  const { width } = useWindow();

  return (
    <>
      <div className="w-100 ">
        <div className="__top_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="page-title" style={{ color: "#212529" }}>
              DASHBOARD
            </div>
            {/* <button onClick={handleCourse}>get</button> */}

            {width > 450 ? (
              ""
            ) : (
              <Link to={"/"} className="nav-link" onClick={handleLogout}>
                <button
                  type="button"
                  className="btn btn-default btn-lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></i>
                </button>
              </Link>
            )}
          </div>
          <hr style={{ marginTop: "0px" }} />
        </div>
        {/* {JSON.stringify(`${height} x ${width}`)} */}
        <GetCourse />
      </div>
    </>
  );
};

export default Dash;
