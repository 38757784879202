import { createContext, useState } from "react";

const ModalContext = createContext(null);

const ModalContextProvider = (props) => {
  const [modalVis, setModalVis] = useState({
    modal: false,
    title: "",
    body: "",
  });

  const modalChanges = (changes) => {
    setModalVis({ ...modalVis, ...changes });
    console.log(changes);
  };
  const modalClose = () => {
    setModalVis({ ...modalVis, modal: false });
    window.location.reload();
  };
  const contextValue = {
    modalVis,
    modalChanges,
    modalClose,
  };
  return (
    <ModalContext.Provider value={contextValue}>
      {props.children}
    </ModalContext.Provider>
  );
};
export { ModalContextProvider, ModalContext };
