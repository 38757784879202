import React, { useState } from "react";

const Nationality = (props) => {
  const [other, setOther] = useState(false);
  const handleChange = (e) => {
    if (e.target.value === "other") {
      setOther(true);
    } else {
      setOther(false);
    }
    props.onChange(e);
  };
  const handleOther = (e) => {
    props.onChange(e);
  };
  return (
    <>
      {console.log(props)}
      <div className="row col" style={{ margin: "0px" }}>
        <div className="col form-check">
          <input
            type="radio"
            className="form-check-input"
            name="national"
            value="WNI"
            onChange={handleChange}
            checked={props.dataForm && props.dataForm === "WNI"}
          />
          <label className="form-check-label" htmlFor="Konghucu">
            WNI
          </label>
        </div>
        <div className="col form-check">
          <div>
            <input
              type="radio"
              className="form-check-input"
              name="national"
              value="other"
              onChange={handleChange}
              checked={props.dataForm && props.dataForm !== "WNI"}
            />
            {other ? (
              ""
            ) : (
              <label className="form-check-label" htmlFor="Other">
                WNA : _________________
              </label>
            )}
          </div>
          <div>
            {other ? (
              <input
                className="form-control"
                type="text"
                name="national"
                placeholder="Type your Nationality here"
                onChange={handleOther}
                value={props.dataForm && (props.dataForm ? props.dataForm : "")}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nationality;
