import React from "react";
import { apiSite } from "../../config/urlLink";
import useDate from "../../custom/useDate";

const StudentCard = ({ data, classdiv, handleClick }) => {
  const { CalculateAge, IsBirthday } = useDate();
  return (
    <div
      className={`${classdiv} __crostudentcardcontainer`}
      onClick={() => handleClick(data.name)}
    >
      <div
        className="w-100 __crostudentcard"
        style={{
          "--studentphoto": data.image
            ? `url(${apiSite}${data.image})`
            : "url(/assets/img/placeholder.webp)",
        }}
      >
        {IsBirthday(data.date_of_birth) && (
          <img
            src="/assets/img/birthday.webp"
            alt={`happy birthday ${data.title}`}
            style={{
              aspectRatio: "1/1",
              width: "100%",
              objectFit: "contain",
              position: "absolute",
              top: "0",
            }}
          />
        )}
        <div className="w-100 __crostudentcardtitle">
          <p
            style={{
              textAlign: "justify",
              fontSize: "12px",
              lineHeight: "1",
            }}
          >
            <small>
              <i>{data.nis}</i>
            </small>
          </p>
          <p>
            <b>{data.title}</b>
          </p>
        </div>

        <div className="w-100 __crostudentcarddetail">
          <div className="__crostudentcardbadge">
            <div className={`__crostudentcardbadgepoint badge text-secondary`}>
              {data.point} <i className={`bi bi-postage`}></i>
            </div>
            <span
              className={`badge text-bg-${
                {
                  Trial: "light",
                  Waiting: "info",
                  Active: "success",
                  Leave: "secondary",
                  Idle: "warning",
                  Out: "danger",
                }[data.status]
              }`}
            >
              {data.status}
            </span>
          </div>
          <span
            style={{
              textAlign: "justify",
              fontSize: "12px",
              lineHeight: "1",
            }}
          >
            <img
              src={
                {
                  Male: "/assets/img/male.png",
                  Female: "/assets/img/female.png",
                  "": "/assets/img/question-mark.png",
                }[data.gender || ""]
              }
              alt={
                {
                  Male: "Male",
                  Female: "Female",
                  "": "please input",
                }[data.gender || ""]
              }
              style={{
                aspectRatio: "1/1",
                height: "25px",
                objectFit: " contain",
              }}
            />
            <small>
              <i>{CalculateAge(data.date_of_birth)}</i>
            </small>
          </span>
          <div
            className="w-100"
            style={{
              position: "absolute",
              bottom: "0",
              padding: "10px",
              textAlign: "justify",
            }}
          >
            <p
              style={{
                textAlign: "justify",
                fontSize: "14px",
                lineHeight: "1",
              }}
            >
              {data.user || "-"}
            </p>
            <p
              style={{
                textAlign: "justify",
                fontSize: "14px",
                lineHeight: "1",
              }}
            >
              {data.student_mobile_number || "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
