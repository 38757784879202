import React, { useEffect } from "react";
import Regist from "./regist";
import Hero from "./hero";

const Home = () => {
  useEffect(() => {
    document.title = `Register Now!`;
  }, []);
  return (
    <>
      <div>
        <Hero />
        <div className="w-100" style={{ height: "50px" }}></div>
        <div className="container">
          <Regist />
        </div>
      </div>
    </>
  );
};

export default Home;
