import React, { useMemo, useState } from "react";
import usePost from "../custom/usePost";
import useWindow from "../custom/useWindow";
import useDate from "../custom/useDate";
import useResource from "../custom/useResource";
import { Link } from "react-router-dom";
import DashCal from "./dashCal";
import handleLogout from "../custom/logout";
import { Spin } from "antd";

const DashCourse = (props) => {
  const { width } = useWindow();

  const usidStore = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");

  let today = new Date(),
    lastMonth = new Date(new Date().setDate(new Date().getDate() - 60)),
    date =
      lastMonth.getFullYear() +
      "-" +
      (lastMonth.getMonth() + 1) +
      "-" +
      lastMonth.getDate(),
    nextMonth = new Date(new Date().setDate(new Date().getDate() + 30)),
    date7 =
      nextMonth.getFullYear() +
      "-" +
      (nextMonth.getMonth() + 1) +
      "-" +
      nextMonth.getDate();
  const [usid, setUsid] = useState({
    instructor: usidStore,
    from_date: lastMonth,
    to_date: nextMonth,
  });
  const { data: course, isLoading } = usePost("get_course_schedule", usid);

  // const { data: ins } = useResource(
  //   "Instructor",
  //   '["name"]',
  //   `["instructor_email", "=", "${usidStore}"]`
  // );

  // const { data: course } = useResource(
  //   "Course Schedule",
  //   '["name","status","color","schedule_date","room","from_time","to_time","instructor"]',
  //   ``
  // );

  let courseFill = useMemo(() => {

    if (!course || !course.message) return [];
    return (
      course)
  }, [course]);

  return (
    <>
      <div className="w-100">
        <div className="__top_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="page-title" style={{ color: "#212529" }}>
              SCHEDULE
            </div>
            {/* <button onClick={handleCourse}>get</button> */}

            {width > 450 ? (
              ""
            ) : (
              <Link to={"/"} className="nav-link" onClick={handleLogout}>
                <button
                  type="button"
                  className="btn btn-default btn-lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></i>
                </button>
              </Link>
            )}
          </div>
          <hr style={{ marginTop: "0px" }} />
        </div>
        <div
          className="col-md-12"
          style={{ overflowY: "auto", height: "100%" }}
        >
          {isLoading ? <Spin fullscreen /> : courseFill && <DashCal data={courseFill.message} />}

          <div className="w-100" style={{ height: "125px" }}></div>
        </div>
      </div>
    </>
  );
};

export default DashCourse;
