import useWeb from "../custom/useWeb";
import useWindow from "../custom/useWindow";
import CompKnowFrom from "./compKnowFrom";
import CompReason from "./compReason";
import CompUnit from "./compUnit";

const FormUnit = () => {
  const { width } = useWindow();
  return (
    <>
      <div
        className="w-100"
        style={{
          textAlign: "center",
          fontSize: width > 450 ? "24px" : "18px",
          fontWeight: "500",
          color: "coral",
          padding: "25px 0 0 0",
        }}
      >
        UNIT DATA
      </div>
      <div className="w-100" style={{ padding: "25px" }}>
        <div className="w-100" style={{ lineHeight: "1" }}>
          <CompUnit />
          <CompKnowFrom />
          <CompReason />
        </div>
      </div>
    </>
  );
};

export default FormUnit;
