import React from "react";

const Maintenance = () => {
  return (
    <>
      <div
        className="w-100"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background:
            "url(https://images.pexels.com/photos/585419/pexels-photo-585419.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1) no-repeat center center /cover",
        }}
      >
        <div
          className="col-md-6 col-12"
          style={{ overflow: "hidden", padding: "10px" }}
        >
          <div
            className="row col-md-12 col-12"
            style={{
              padding: "40px",
              background: "rgba(255,255,255,0.7)",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-md-6 col-12">
              <h1>We are sorry!</h1>
              <hr />
              <p>This page is down for maintenance.</p>
              <p>
                We are working to get it back up and running as soon as
                possible. Please check on us later.
              </p>
              <p>
                For further information please contact <b> CITY Dev Works</b>.
              </p>
              <p style={{ fontSize: "35px" }}>
                <a
                  href="mailto:city@sekolahmusikindonesia.id"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-envelope"></i>
                </a>{" "}
                <a
                  href="https://discord.gg/dpNYze6K"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-discord"></i>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <img
                src="./assets/img/construction.png"
                alt="under maintenance"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
