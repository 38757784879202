import React from "react";
import Login from "../website/login";
import Home from "../website/home";
import DashCourse from "../dashboard/dashCourse";
import DashHis from "../dashboard/dashHis";
import Dashboard from "../dashboard/dashboard";
import Dash from "../dashboard/dash";
// import QRCam from "../qrcode/cam1";
import DashStudent from "../dashboard/dashStudent";
import DashStaff from "../dashboard/dashStaff";
import Page1 from "../franchise/page1";
import AreYouReady from "../franchise/areYouReady";
import Page2 from "../franchise/page2";
import DashStaffProfile from "../dashboard/dashStaffProfile";
import DashStaffReport from "../dashboard/dashStaffReport";
import RHome from "../register/rHome";
import DashJadwal from "../dashboard/dashjadwal";
import BbgRegister from "../bbg/register";
import CroDash from "../dashboard/cro/croDash";

const routes = {
  staff: [
    {
      path: "/profile",
      element: <DashStaffProfile />,
    },
    {
      path: "/report",
      element: <DashStaffReport />,
    },
  ],
  dash: [
    {
      path: "/profile",
      element: <Dash />,
    },
    {
      path: "/jadwal",
      element: <DashJadwal />,
    },
    {
      path: "/riwayat",
      element: <DashHis />,
    },
    {
      path: "/e/*",
      element: <DashStaff />,
    },
    // {
    //   path: "/qrcam/:id",
    //   element: <QRCam />,
    // },
    // {
    //   path: "/qrcam",
    //   element: <QRCam />,
    // },
    {
      path: "/course",
      element: <DashCourse />,
    },
    {
      path: "/student",
      element: <DashStudent />,
    },
    {
      path: "/",
      element: <Dash />,
    },
    {
      path: "/c/student",
      element: <CroDash />,
    },
  ],
  web: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/b/*",
      element: <BbgRegister />,
    },
    {
      path: "/d/*",
      element: <Dashboard />,
    },
    {
      path: "/f/*",
      element: <Page1 />,
    },
    {
      path: "/r/*",
      element: <RHome />,
    },
    {
      path: "/*",
      element: <Home />,
    },
  ],
  franchise: [
    {
      path: "/",
      element: <AreYouReady />,
    },
    {
      path: "/page2",
      element: <Page2 />,
    },
  ],
};

export default routes;
