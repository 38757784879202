import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useWindow from "../custom/useWindow";
import "../assets/css/dashHis.css";
import useResource from "../custom/useResource";
import StudentAttendance from "./studentAttendance";
import { ShortDate } from "../custom/dateFormatter";
import handleLogout from "../custom/logout";
import { DatePicker, Flex, Space, Tooltip, Input, Spin } from "antd";
const { RangePicker } = DatePicker
const { Search } = Input

const DashHis = () => {

  const { width } = useWindow();

  const [date, setDate] = useState({
    start: new Date(new Date().getTime() - 60 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
    end: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
  });

  const [src, setSrc] = useState({ src: "", loading: false });

  const { data: attendance, isLoading } = useResource(
    "Student Attendance",
    '["name","course_schedule","creation","modified","docstatus","student","student_name","student_group","date","status","growth_point","comment","lesson","video_url","instructor"]',
    `["creation",">","${date.start}"],["creation","<","${date.end}"],["instructorlink_name","=","${sessionStorage.getItem("full_name")}"]`
  );

  const { data: courseSchedule } = useResource(
    "Course Schedule",
    '["attendance_status","name","title","course","docstatus","instructor","instructor_name","room","from_time","to_time","schedule_date"]',
    `["schedule_date",">","${date.start}"],["schedule_date","<","${date.end}"],["instructor_name","=","${sessionStorage.getItem("full_name")}"]`
  );

  const { data: comment } = useResource(
    "Comment",
    '["name","reference_name","comment_by","content","creation"]',
    `["reference_doctype","=","Student Attendance"],["comment_type","=","Comment"],["comment_email","=","${sessionStorage.getItem(
      "user_id"
    )}"]`
  );

  const handleDate = useCallback((e) => {
    setDate({
      ...date,
      start: e[0],
      end: e[1]
    })
    setSrc({ src: "", loading: false })
  }, [])

  let data = [];

  // if (attendance) {
  //   if (courseSchedule) {
  //     for (let i = 0; i < attendance.data.length; i++) {
  //       for (let j = 0; j < courseSchedule.data.length; j++) {
  //         if (
  //           attendance.data[i].course_schedule === courseSchedule.data[j].name
  //         ) {
  //           let newData = {
  //             attendance_name: attendance.data[i].name,
  //             att: attendance.data[i],
  //             cs: courseSchedule.data[j],
  //           };
  //           data.push(newData);
  //         }
  //       }
  //     }
  //   }
  // }

  if (attendance && courseSchedule) {
    const courseScheduleLookup = courseSchedule.data.reduce((lookup, cs) => {
      lookup[cs.name] = cs;
      return lookup;
    }, {});

    for (let i = 0; i < attendance.data.length; i++) {
      const attendanceData = attendance.data[i];
      const cs = courseScheduleLookup[attendanceData.course_schedule];
      if (cs) {
        let newData = {
          attendance_name: attendanceData.name,
          att: attendanceData,
          cs: cs,
        };
        data.push(newData);
      }
    }
  }

  let dataFill = useMemo(() => {
    if (!attendance || !courseSchedule) return [];
    return (
      data &&
      data.filter((f) =>
        // f.att.instructor === sessionStorage.getItem("instructor_id") &&
        (
          f.att.student_name +
          f.att.student_group +
          f.att.lesson +
          f.att.comment +
          f.att.video_url
        )
          .toLowerCase()
          .includes(src.src)
      )
    );
  }, [attendance, courseSchedule, data, src.src]);

  return (
    <>
      {" "}
      <div className="w-100" style={{ background: "#eeeeee" }}>
        <div className="__top_content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="page-title" style={{ color: "#212529" }}>
              HISTORY
            </div>

            {width > 450 ? (
              ""
            ) : (
              <Link to={"/"} className="nav-link" onClick={handleLogout}>
                <button
                  type="button"
                  className="btn btn-default btn-lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></i>
                </button>
              </Link>
            )}
          </div>
          <hr style={{ marginTop: "0px" }} />
        </div>
        <Flex
          className="w-100"
          vertical="horizontal"
          gap="small"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0",
            padding: "0 15px",
          }}
        >
          <Flex gap="small">
            <Flex className="w-100" >
              <p>
                Attendance History<br />
                <small><i>{ShortDate(date.start)} - {ShortDate(date.end)}</i></small>
              </p>
            </Flex>
            <Flex className="w-100" justify="space-between" align="center">
              <Space.Compact >
                <RangePicker
                  id={{ start: date.start, end: date.end }}
                  onChange={(date, fdate) => handleDate(fdate)} disabled={src.loading} loading={src.loading} />


                <Tooltip trigger={['focus']} title="search by: name, or content"
                  placement="topLeft" overlayClassName="numeric-input">
                  <Search name="src"
                    onChange={(_) => setSrc({ ...src, src: _.target.value.toLowerCase() })} disabled={src.loading} loading={src.loading} />
                </Tooltip>
              </Space.Compact>
              {/* <label htmlFor="src">
              search by: name, lesson,comment & video
              </label>
              <input
                type="text"
                name="src"
                className="form-control"
                onChange={(e) => setSrc(e.target.value.toLowerCase())}
              // style={{ width: "250px" }}
              /> */}
            </Flex>
          </Flex>
          <div
            className="w-100"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "0",
              padding: "0 10px",
              overflowY: "auto",
              height: "80vh",
            }}
          >
            {isLoading ? <Spin fullscreen /> : (dataFill &&
              dataFill
                .sort((a, b) => a.date > b.date)
                .map((d) => (
                  <div
                    key={d.attendance_name}
                    className="col-md-4 col-12"
                    style={{ padding: "1rem", margin: "0px" }}
                  >
                    <StudentAttendance
                      data={d}
                      comment={(comment && comment.data) || []}
                    />
                  </div>
                )))}
            <div className="w-100" style={{ height: "25px" }}></div>
          </div>
        </Flex>
      </div>
    </>
  );
};

export default DashHis;
