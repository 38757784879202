import React from "react";
import Instrument from "./instrument";
// import useFetch from "./useFetch";

const Instruments = (props) => {
  // const { data, isLoading, error } = useFetch("instruments.php");
  const { check, dis, program } = props;
  const data = [
    { id: "1", name: "Piano", status: "1", idx: "1", parent: "FAS / Private" },
    { id: "2", name: "Drum", status: "1", idx: "2", parent: "FAS / Private" },
    { id: "3", name: "Vokal", status: "1", idx: "3", parent: "FAS / Private" },
    { id: "4", name: "Gitar", status: "1", idx: "4", parent: "FAS / Private" },
    { id: "5", name: "Violin", status: "1", idx: "5", parent: "FAS / Private" },
    { id: "6", name: "Saxo", status: "1", idx: "6", parent: "FAS / Private" },
    { id: "7", name: "Flute", status: "1", idx: "7", parent: "FAS / Private" },
    { id: "8", name: "MTL", status: "1", idx: "8", parent: "FAS / Private" },
    { id: "9", name: "Group", status: "1", idx: "10", parent: "Harmony" },
    { id: "12", name: "Piano", status: "1", idx: "1", parent: "Harmony" },
    { id: "13", name: "Drum", status: "1", idx: "2", parent: "Harmony" },
    { id: "14", name: "Vokal", status: "1", idx: "3", parent: "Harmony" },
    { id: "15", name: "Gitar", status: "1", idx: "4", parent: "Harmony" },
    { id: "16", name: "Violin", status: "1", idx: "5", parent: "Harmony" },
    { id: "17", name: "Saxo", status: "1", idx: "6", parent: "Harmony" },
    { id: "18", name: "Flute", status: "1", idx: "7", parent: "Harmony" },
    { id: "19", name: "MTL", status: "1", idx: "8", parent: "Harmony" },
    {
      id: "10",
      name: "FOM Fun",
      status: "1",
      idx: "10",
      parent: "FoM",
    },
    {
      id: "11",
      name: "FOM Learn",
      status: "1",
      idx: "11",
      parent: "FoM",
    },
    { id: "12", name: "Bass", status: "1", idx: "9", parent: "FAS / Private" },
    { id: "13", name: "Bass", status: "1", idx: "9", parent: "Harmony" },
  ];
  return (
    <>
      <div
        className="row align-items-base"
        style={{ margin: "0px", padding: "0px" }}
      >
        {data &&
          data
            .filter((e) => e.parent === null || e.parent === program)
            .map((d) => (
              <Instrument
                key={`${d.id} major`}
                id={`${d.id} major`}
                name="major"
                value={d.name}
                onChange={(e) => {
                  props.onChange(e);
                  // console.log(e.target.value);
                }}
                checked={check}
                disabled={dis}
              />
            ))}
      </div>
    </>
  );
};

export default Instruments;
