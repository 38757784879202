import React from "react";
import useWindow from "../custom/useWindow";
import redbg from "../assets/img/red_bg.png";
import logoh from "../assets/img/logoh.png";
import brandi from "../assets/img/brandi.png";
import routes from "../config/routes";
import { Link, Route, Routes } from "react-router-dom";

const Page1 = () => {
  const { width } = useWindow();
  return (
    <>
      <div
        className="w-100"
        style={{
          background: `linear-gradient(120deg, rgba(255,255,255,0) 0%, rgba(0,35,255,0.5) 100%), url(${redbg}) center center /cover`,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100vh",
          width: "100vw",
          justifyContent: width > 450 ? "center" : "flex-start",
          padding: width > 450 ? "0 0 0 7vw" : "25px",
        }}
      >
        <Link to="/">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <img
              alt="smi logo horisontal"
              width={width > 450 ? "" : "70%"}
              src={logoh}
            />
          </div>
        </Link>
        <Routes>
          {routes.franchise.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={route.element}
            />
          ))}
        </Routes>
        <div
          style={{
            position: "absolute",
            right: width > 450 ? "100px" : "15px",
            bottom: "0px",
          }}
        >
          <img
            alt="brandi ideas"
            style={{
              // width: "100%",
              height: width > 450 ? "90vh" : "30vh",
            }}
            src={brandi}
          />
        </div>
      </div>
    </>
  );
};

export default Page1;
