import React, { useState, useMemo, useEffect } from "react";
import usePost from "../custom/usePost";
import propic from "../assets/img/propic.png";
import useWindow from "../custom/useWindow";
import Modal from "../website/modal";
import {
  CalculateAge,
  ShortDate,
  ShortDateFull,
} from "../custom/dateFormatter";
import useResource from "../custom/useResource";
import { GetResourceDetail } from "../custom/apiResource";
import DashStudentPic from "./dashStudentPic";
import { apiSite } from "../config/urlLink";
import { Empty, Spin } from "antd";

const DashStudentDet = (props) => {
  const { width } = useWindow();
  const [vis, setVis] = useState({ modal: false });
  const [attDetail, setAttDetail] = useState({ id: "", data: "" });
  const [selectedStud, setSelectedStud] = useState("");
  const [search, setSearch] = useState({
    src: "",
    status: true,
    sort: "name",
    student: "",
  });
  const [usid, setUsid] = useState({
    user: sessionStorage.getItem("user_id"),
  });
  const { data: studentList, isLoading } = usePost("get_student_list", usid);
  // const studentList = []
  const { data: attendance } = useResource(
    "Student Attendance",
    '["name","course_schedule","creation","modified","docstatus","student","student_name","student_group","date","status","growth_point","comment","lesson","video_url","instructor"]',
    `["instructorlink_name","=","${sessionStorage.getItem("full_name")}"]`
  );

  // let att = attendance && attendance.data;
  // let data = studentList && studentList.message;

  // useEffect(() => {

  // }, [props, data]);
  const handleData = (data) => {
    props.handleStudentData(data);
  }

  let dataFil = useMemo(() => {

    if (!studentList || !studentList.message) return [];
    handleData(studentList.message)
    // const searchRegex = search.student && new RegExp(`${search.student}`, "gi");
    return (
      studentList &&
      studentList.message
        .sort((a, b) => (a.student_name > b.student_name ? 1 : -1))
        .filter((f) =>
          (f.student_name + f.student).toLowerCase().includes(search.student)
        )
    );
  }, [studentList, search.student]);

  const attFil = useMemo(() => {
    if (!attendance || !attendance.data) return [];

    // const searchRegex = search.src && new RegExp(`${search.src}`, "gi");
    // console.log(att);
    return (
      attendance &&
      attendance.data.filter(
        (f) =>
          f.instructor === sessionStorage.getItem("instructor_id") &&
          // (search.status ? f.status === "Present" : f.status === "Absent") && // filter by status if it's defined
          (search.start ? search.start < f.schedule_date : f.schedule_date) && // filter by start date if it's defined
          (search.end ? search.end > f.schedule_date : f.schedule_date) && // filter by end date if it's defined
          (f.comment + f.lesson + f.video_url + f.owner)
            .toLowerCase()
            .includes(search.src)
      )
    );
  }, [attendance, search.src, search.start, search.end]);
  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const handleAttDet = (e, c, i) => {
    e.preventDefault();
    console.log(c);
    setAttDetail({ [c.student]: c });
  };
  const handleStudentDetail = async (e, data, i) => {
    setVis({
      modal: true,
      title: "Looking for " + data.student_name,
      element: "Please wait while we are looking for the data.",
    });
    try {
      let res = await GetResourceDetail("Student", data.student);
      res = await res.data;

      setVis({
        modal: true,
        title: data.student_name + " Data",
        element: (
          <>
            <div className="w-100">
              <div
                className="row col-md-12"
                style={{ padding: "0", margin: "0" }}
              >
                <div className="col-md-5" style={{ padding: "0", margin: "0" }}>
                  <div
                    style={{
                      background: `url('${data.image === null
                        ? propic
                        : data.image.includes("/files/")
                          ? apiSite + data.image
                          : data.image
                        }') center center / cover`,
                      height: "100%",
                      minHeight: width > 450 ? "" : "40vh",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  ></div>
                </div>
                <div
                  className="col-md-7"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <div
                    className="w-100"
                    style={{ paddingLeft: "10px", margin: "0px" }}
                  >
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Student ID
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={res.name}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Full Name
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={res.title}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Date Of Birth
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={
                            res.place_of_birth +
                            ", " +
                            ShortDate(res.date_of_birth)
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Age
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={CalculateAge(res.date_of_birth)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Joining Date
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={ShortDate(res.joining_date)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Joining Reason
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={res.reason_joining}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Know From
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={res.know_from}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          Address
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={res.address_line_1}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "125px" }}
                        >
                          City
                        </span>
                        <input
                          readOnly={true}
                          type="text"
                          value={res.city}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      });
      // console.log(res);
    } catch (error) {
      console.log(error);
      setVis({
        modal: true,
        title: "Sorry " + data.student_name + " is lost.",
        element: (
          <>
            <div className="w-100">
              {JSON.stringify(error)}
              <div className="row col-md-12">
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </>
        ),
      });
    }
  };
  return isLoading ? (
    <>
      {/* <div className="w-100">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "10px 10px",
            margin: "0",
            width: "100%",
            borderRadius: "5px",
            background: "#e9e9e9",
            height: width > 450 ? "80vh" : "68vh",
          }}
        >
          "Please wait while we prepare the data"
        </div>
      </div> */}
      <Spin fullscreen />
    </>
  ) : studentList ? (
    <>
      <Modal
        modal={vis.modal}
        title={vis.title}
        element={vis.element}
        handleClose={() => setVis({ modal: false })}
      />
      <div
        className="w-100"
        style={{
          display: "flex",
          flexDirection: width > 450 ? "row" : "column",
          justifyContent: "space-between",
          alignItems: width > 450 ? "center" : "flex-start",
          padding: "0 15px",
        }}
      >
        {/* {console.log(data)} */}
        <div className="w-100" style={{ display: "flex" }}>
          <p
            style={{
              margin: "0",
              padding: "0",
            }}
          >
            Filter
          </p>
        </div>

        <div
          className="w-100"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <input
            className="form-control"
            style={{
              padding: "3px 10px",
              fontSize: "12px",
            }}
            type="text"
            placeholder="Search student here"
            name="student"
            value={search.student ? search.student : ""}
            onChange={handleSearch}
          />
          <input
            className="form-control"
            style={{
              padding: "3px 10px",
              fontSize: "12px",
            }}
            type="text"
            placeholder="Search Attendance here"
            name="src"
            value={search.src ? search.src : ""}
            onChange={handleSearch}
          />
        </div>
        <div
          className="w-100"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <input
            className="form-control"
            style={{
              padding: "3px 10px",
              fontSize: "12px",
            }}
            type="date"
            name="start"
            value={search.start}
            onChange={(e) => setSearch({ ...search, start: e.target.value })}
          />
          <input
            className="form-control"
            style={{
              padding: "3px 10px",
              fontSize: "12px",
            }}
            type="date"
            name="end"
            value={search.end}
            onChange={(e) => setSearch({ ...search, end: e.target.value })}
          />
          <button
            className="btn btn-warning"
            style={{
              padding: "3px 10px",
              fontSize: "12px",
            }}
            onClick={() => setSearch({ status: true, start: "", end: "" })}
          >
            CLEAR
          </button>
        </div>
      </div>
      <hr />
      <div
        className="col-md-12"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: "0 10px",
          margin: "0",
          width: "100%",
        }}
      >
        <div
          className="w-100"
          style={{
            padding: "0",
            margin: "0",
            fontSize: "14px",
            height: width > 450 ? "75vh" : "68vh",
            overflow: "auto",
          }}
        >
          {dataFil.map((d, i) => (
            <div
              key={i + d.student}
              style={{
                margin: "0px",
                padding: "5px",
                display: "flex",
                flexDirection: width > 450 ? "row" : "column",
                background: selectedStud === d.student ? "lightgrey" : "",
                borderRadius: "5px",
              }}
              onClick={() => setSelectedStud(d.student)}
            >
              <div className="col-md-3 col-12" style={{}}>
                <div
                  className={
                    selectedStud === d.student
                      ? "__studentcontaineractive"
                      : "__studentcontainer"
                  }
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    className="__studentdetail"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {selectedStud === d.student ? (
                      <DashStudentPic userImage={d.image} id={selectedStud} />
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          height: "60px",
                          width: "60px",
                          borderRadius: "5px",
                          border: "1px solid rgb(0 0 0 /.3)",
                        }}
                      >
                        <div
                          style={{
                            background: `url('${d.image === null || d.image === undefined
                              ? propic
                              : d.image.includes("/files/")
                                ? apiSite + d.image
                                : d.image
                              }') center center / cover`,
                            height: "100%",
                            width: "100%",
                            borderRadius: "3px",
                          }}
                        />
                      </div>
                    )}
                    <div className="" style={{ padding: "0 7px" }}>
                      <div className="w-100" style={{ lineHeight: "1" }}>
                        <p style={{ margin: "0", padding: "0" }}>
                          <small>
                            <i>{d.student}</i>
                          </small>
                          <br />
                          <b>{d.student_name.toUpperCase()}</b>
                        </p>
                      </div>
                      <div
                        className="w-100"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "5px 0 0 0",
                        }}
                      >
                        {/* status */}
                        <div
                          className="__tagbadge"
                          style={{
                            background: {
                              Active: "mediumseagreen",
                              Leave: "orange",
                              Idle: "dodgerblue",
                              Out: "crimson",
                            }[d.status],
                          }}
                        >
                          <div>{d.status.toUpperCase()}</div>
                        </div>
                        {/* payment status */}
                        <div
                          className="__tagbadge"
                          style={{
                            background: {
                              Paid: "limegreen",
                              // Unpaid: "yellow",
                              Unpaid: "sandybrown",
                              Late: "crimson",
                            }[d.payment],
                          }}
                        >
                          <div>
                            <small>{d.payment.toUpperCase()}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="__btndetail"
                    style={{
                      position: "absolute",
                      right: "5px",
                      bottom: "5px",
                    }}
                    onClick={(e) => handleStudentDetail(e, d, i)}
                  >
                    <div
                      className="jello-horizontal"
                      style={{ paddingRight: "5px" }}
                    >
                      <i className="bi bi-house"></i>
                    </div>
                    DETAIL
                  </div>
                </div>
              </div>
              <div
                className="col-md-9 col-12"
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  margin: "0",
                  padding: "0",
                  fontSize: "14px",
                  overflow: "auto",
                }}
              // onMouseLeave={() => setAttDetail({})}
              >
                {Object.keys(attDetail)[0] === d.student ? (
                  <div
                    className="col-md-4 col-12"
                    style={{
                      padding: "0 5px",
                      margin: "0",
                      display: "flex",
                      flexFlow: "wrap row",
                    }}
                  >
                    <div className="w-100">
                      {Object.keys(attDetail)[0] === selectedStud ? (
                        <>
                          <div
                            className="w-100"
                            style={{
                              padding: "0",
                            }}
                          >
                            {/* {console.log(attDetail[d])} */}
                            <div
                              className="card"
                              style={{
                                padding: "15px",
                                position: "relative",
                                fontSize: "12px",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                  background: "#e9e9e9",
                                  color: "grey",
                                  padding: "5px 10px",
                                }}
                                onClick={() => setAttDetail({})}
                              >
                                X
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    background: "#e9e9e9",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {attDetail[selectedStud].schedule_date &&
                                    ShortDate(
                                      attDetail[selectedStud].schedule_date
                                    )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "0 10px",
                                  }}
                                >
                                  <span className="__cs_hour">
                                    {attDetail[selectedStud].from_time &&
                                      attDetail[selectedStud].from_time.split(
                                        ":"
                                      )[0]}
                                  </span>
                                  .
                                  {attDetail[selectedStud].from_time &&
                                    attDetail[selectedStud].from_time.split(
                                      ":"
                                    )[1]}{" "}
                                  -
                                  <span className="__cs_hour">
                                    {attDetail[selectedStud].to_time &&
                                      attDetail[selectedStud].to_time.split(
                                        ":"
                                      )[0]}
                                  </span>
                                  .
                                  {attDetail[selectedStud].to_time &&
                                    attDetail[selectedStud].to_time.split(
                                      ":"
                                    )[1]}
                                </div>
                              </div>
                              <div style={{ padding: "10px 0" }}>
                                {attDetail[selectedStud].course_schedule}
                                <br />
                                {attDetail[selectedStud].student_group}
                              </div>
                              {/* {attDetail[selectedStud].course.split(" - ")[1]} */}
                              <div style={{ position: "relative" }}>
                                <span
                                  style={{
                                    color: "lightgrey",
                                    fontSize: "12px",
                                    position: "absolute",
                                    top: "-0px",
                                    left: "0px",
                                  }}
                                >
                                  {ShortDateFull(
                                    attDetail[selectedStud].creation
                                  )}
                                </span>
                                <hr />
                                <span
                                  style={{
                                    color: "lightgrey",
                                    fontSize: "12px",
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                  }}
                                >
                                  {attDetail[selectedStud].name}
                                </span>
                              </div>
                              <div style={{ position: "relative" }}>
                                <div>
                                  {attDetail[selectedStud].student} <br />
                                  {attDetail[selectedStud].student_name} <br />
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-17px",
                                    right: "0",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {[...Array(5)].map((star, i) => {
                                      i += 1;
                                      return (
                                        <div
                                          type="star-rating-button"
                                          key={i}
                                          className={
                                            i <=
                                              attDetail[selectedStud].growth_point
                                              ? "on"
                                              : "off"
                                          }
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          <i
                                            className={
                                              i <=
                                                attDetail[selectedStud]
                                                  .growth_point
                                                ? "bi bi-star-fill"
                                                : "bi bi-star"
                                            }
                                          ></i>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div
                                  className="w-100"
                                  style={{ position: "relative" }}
                                >
                                  <hr />
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "-20px",
                                      color: "grey",
                                      fontSize: "20px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {attDetail[
                                      selectedStud
                                    ].status.toUpperCase()}
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "1px",
                                      color: "lightgrey",
                                      background: "white",
                                    }}
                                  >
                                    {
                                      {
                                        0: "DRAFT",
                                        1: "SUBMITTED",
                                        2: "CANCELED",
                                      }[attDetail[selectedStud].docstatus]
                                    }
                                  </div>
                                </div>
                                <div
                                  style={{ height: "150px", overflowY: "auto" }}
                                >
                                  <small>
                                    <b>Comment : </b>
                                  </small>
                                  <p
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {attDetail[selectedStud].comment}
                                  </p>
                                  <small>
                                    <b>Lesson : </b>
                                  </small>
                                  <p
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {attDetail[selectedStud].lesson}
                                  </p>
                                  <small>
                                    <b>Video Url : </b>
                                  </small>
                                  <p
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {attDetail[selectedStud].video_url}
                                  </p>
                                </div>
                                <div
                                  className="w-100"
                                  style={{ height: "15px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className={
                    Object.keys(attDetail)[0] === d.student
                      ? "col-md-8"
                      : "w-100"
                  }
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    flexFlow: "wrap row",
                  }}
                >
                  {attFil &&
                    attFil
                      .filter((f) => f.student === d.student)
                      .sort((a, b) =>
                        a.schedule_date < b.schedule_date
                          ? -1
                          : a.schedule_date > b.schedule_date
                            ? 1
                            : 0
                      )
                      .map((c) => (
                        <>
                          <div
                            key={i + c.name}
                            className={
                              Object.keys(attDetail)[0] === d.student
                                ? "col-md-4 col-6"
                                : "col-md-3 col-6"
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => handleAttDet(e, c, i)}
                          >
                            <div
                              style={{
                                background: {
                                  Present: "limegreen",
                                  Absent: "crimson",
                                }[c.status],
                                borderRadius: "3px",
                                padding: "5px",
                                color: "white",
                              }}
                            >
                              {ShortDate(c.schedule_date)}
                              <br />
                              {c.status + " - " + c.date}
                            </div>
                          </div>
                        </>
                      ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <Empty />
  );
};

export default DashStudentDet;
